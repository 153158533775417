/* Services and interfaces */
import { FileSystemTree } from "../services/navigate";
import { DocumentS3Keys, getStorageDocumentS3ROKeys, getStorageDocumentS3RWKeys, multiFileDownload } from "../services/documents";
/* Utils */
import AWS from "aws-sdk";

export const multipleDownload = (files: Array<FileSystemTree>): Promise<void> => {
    return new Promise((resolve, reject) => {

        const fileNamesList = files.map(file => file.s3filename);

        multiFileDownload(fileNamesList).then(async ({ ZippedName }) => {
            const { s3, keys } = await getS3RWConfig();

            const objectData = {
                Bucket: keys.bucketname,
                Key: ZippedName,
            }

            s3.getObject(objectData, (err, result) => {
                if (err) {
                    reject();
                    return;
                };
                //@ts-ignore
                const blob = new Blob([result.Body], { type: result.ContentType });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = ZippedName;
                link.click();
                s3.deleteObject(objectData, (errore, risultato) => {
                    if (errore) {
                        console.log("Il file non è stato eliminato");
                    }
                })
                resolve();
            })
        }).catch(error => {
            console.log("Errore:", error);
            reject();
        })
    })
}

export const singleDownload = (s3filename: string, filename: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            getS3ROConfig().then(({ keys, s3 }) => {
                const objectData = {
                    Bucket: keys.bucketname,
                    Key: s3filename,
                }

                s3.getObject(objectData, (err, result) => {
                    if (err) {
                        reject();
                        return;
                    };
                    //@ts-ignore
                    const blob = new Blob([result.Body], { type: result.ContentType });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                    resolve();
                })
            })

        } catch (error) {
            reject(error);
        }
    })
}

const getS3ROConfig = (): Promise<{ keys: DocumentS3Keys, s3: AWS.S3 }> => {
    return new Promise((resolve, reject) => {
        try {
            getStorageDocumentS3ROKeys().then(keys => {
                const s3 = new AWS.S3({
                    credentials: {
                        accessKeyId: keys.id,
                        secretAccessKey: keys.secret
                    }
                })
                resolve({ keys, s3 });
            });
        } catch {
            reject();
        }
    })
}

const getS3RWConfig = (): Promise<{ keys: DocumentS3Keys, s3: AWS.S3 }> => {
    return new Promise((resolve, reject) => {
        try {
            getStorageDocumentS3RWKeys().then(keys => {
                const s3 = new AWS.S3({
                    credentials: {
                        accessKeyId: keys.id,
                        secretAccessKey: keys.secret
                    }
                })
                resolve({ keys, s3 });
            });
        } catch {
            reject();
        }
    })
}