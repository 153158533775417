import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { getTokenWithAuthCode } from "../../services/auth";
import {
  clearLocalStorage,
  existsInLocalStorage,
  getValueFromLocalStorage,
  getValueFromSession,
  saveInLocalStorage,
} from "../../services/storage-wrapper";
/* CONFIG */
import config from "../../config.json";
import { useSafeEffect } from "../../helpers/hooks";
import { getUsers, UserInterface, UserParamsInterface } from "../../services/user";

const appConfig: any = config;

interface AuthSessionType {
  email: string;
  name: string;
  token: string;
  accessToken: string;
  identities: string;
  refreshToken: string;
  [key: string]: any;
}
interface AuthContextType {
  user: UserInterface;
  userSession: AuthSessionType;
  authenticated: boolean;
  loading: boolean;
  login(): void;
  logout(): void;
}

const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = (props: {
  [key: string]: any;
  children: JSX.Element;
}) => {
  const { children } = props;
  const navigate = useNavigate();
  const [user, setUser]: any = useState<UserInterface>();

  const [userSession, setUserSession] = useState<AuthSessionType>(
    existsInLocalStorage("session")
      ? JSON.parse(getValueFromLocalStorage("session"))
      : null
  );

  const [authCode, setAuthCode] = useState<string>();
  const [isLogout, setIsLogout] = useState<boolean>(false);

  const [isLoading, setIsLoading]: any = useState(false);
  const [hasSession, setHasSession]: any = useState(
    existsInLocalStorage("session")
  );

  useSafeEffect(
    [userSession],
    () => userSession?.email && !user ? Promise.resolve() : Promise.reject(),
    () => {
      console.info("authenticated");
      getUser({ username: userSession.email.toLowerCase() });
    },
    () => {
      console.info("Still not authenticated");
    }
  );


  useSafeEffect(
    [],
    () => Promise.resolve(new URL(window.location.href)),
    (url: URL) => {
      const code = url.searchParams.get("code");
      const logout = url.searchParams.get("logout") ? true : false;

      setAuthCode(code || "");
      setIsLogout(logout);
    }
  );

  useSafeEffect(
    [authCode, isLogout],
    async () => {
      setIsLoading(true);
      if (isLogout) {
        throw new Error("requesting logout");
      }
      if (!authCode && !user) return;
      const tokenResponse = await getTokenWithAuthCode(authCode || "");
      return tokenResponse;
    },
    (data: AuthSessionType) => {
      if (!hasSession && !authCode) {
        redirectForAuthentication();
      }

      if (data && data?.token) {
        navigate({
          pathname: `/home`,
          search: "",
        });

        const sessionString = JSON.stringify(data);
        saveInLocalStorage("session", sessionString);
        setUserSession(data);
      }
    },
    (err: any) => {
      console.error(err);
      clearLocalStorage();
      setHasSession(false);
      setUser(null);
      navigate({
        pathname: "/home",
        search: "",
      });
    }
  );

  const getUser = async (filters: UserParamsInterface) => {
    try {
      setIsLoading(true);
      const userResp = await getUsers(filters);
      setUser(userResp.users[0]);
      setHasSession(true);
    } catch (err) {
      console.error(err);
      clearLocalStorage();
      setHasSession(false);
      setUser(null);
      navigate({
        pathname: "/home",
        search: "",
      });
    } finally {
      setIsLoading(false);
    }
  }

  const redirectForAuthentication = () => {
    const envVariables =
      appConfig.Environment[process.env.REACT_APP_ENVIRONMENT || "none"];
    window.location.assign(
      `${envVariables.cognitoHost}/oauth2/authorize?&redirect_uri=${envVariables.redirectUri}&response_type=CODE&client_id=${envVariables.clientId
      }&scope=${encodeURIComponent(envVariables.scope.join(" "))}`
    );
  };

  const logout = () => {
    const envVariables =
      appConfig.Environment[process.env.REACT_APP_ENVIRONMENT || "none"];
    window.location.assign(
      `${envVariables.cognitoHost}/logout?client_id=${envVariables.clientId}&logout_uri=${envVariables.logoutUri}`
    );
  };

  const memoedValues = useMemo<AuthContextType>(
    () => ({
      authenticated: hasSession,
      loading: isLoading,
      user,
      userSession,
      login: redirectForAuthentication,
      logout,
    }),
    [isLoading, hasSession, user, userSession]
  );

  return (
    <AuthContext.Provider value={memoedValues}>{hasSession && children}</AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
