import React from "react";
import { Link } from "react-router-dom";
/* AntD */
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
/* Config */
import config from "../../config.json";
/* Services*/
import {
  getValueFromLocalStorage,
} from "../../services/storage-wrapper";
/* Context */
import { useAuth } from "..";
/* Translations */
import { headerTranslations, menuTranslations } from "../../helpers/translations";
/* i18n */
import i18n from "../../i18next";
/* Images */
import ita from "../../assets/images/flag_ita.png";
import eng from "../../assets/images/flag_uk.png";

const Header = () => {

  const {
    loading,
    authenticated,
    user,
    userSession,
    login,
    logout,
  } = useAuth();

  const menu: any = menuTranslations;
  const header: any = headerTranslations;

  return (
    <>
      {loading && (
        <div className="spinnerContainer">
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
            tip="LOADING..."
            className="spinner"
          />
        </div>
      )}
      <nav
        className="navbar navbar-expand-lg navbar-dark header-bg"
        style={{ backgroundColor: "#1f4788" }}
      >
        <Link
          className="navbar-brand"
          to={config.Menu.home.url}
        >
          {menu.home.title}
        </Link>
        <button
          className="navbar-toggler"
          style={{ color: "#fff" }}
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {authenticated && user &&
              Object.keys(config.Menu)
                .filter(key => (!['home', 'logout'].includes(key) && !config.Menu[key]?.notVisibleFor?.includes(user?.role?.accessLevel)))
                .map((menuItemKey, index) => {
                  const item = config.Menu[menuItemKey];
                  if(item.disabled) return;

                  return (
                    <li className={item.subPages && item.subPages.length ? 'nav-item dropdown' : 'nav-item'} key={index}>
                      {item.subPages && item.subPages.length ? (
                        <>
                          <a
                            className="nav-link dropdown-toggle"
                            style={{ color: '#fff' }}
                            href={item.url}
                            id={menu[menuItemKey].title + 'Dropdown'}
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {menu[menuItemKey].title}
                          </a>
                          <div className="dropdown-menu" aria-labelledby={menu[menuItemKey].title + 'Dropdown'}>
                            {item.subPages.filter(subMenuItem => !subMenuItem?.notVisibleFor?.includes(user?.role?.accessLevel)).map(
                              (page: any, ind: number) => {
                                if(page.disabled) return;
                                return <Link key={ind} className="dropdown-item" to={page.url}>
                                {menu[menuItemKey].subPages[page.title].title}
                              </Link>
                              }

                            )}
                          </div>
                        </>
                      ) : (
                        <Link className="nav-link" style={{ color: '#fff' }} to={item.url} state={{resetData: true}}>
                          {menu[menuItemKey].title}
                        </Link>
                      )}
                    </li>
                  );
                })}
          </ul>
          <div className="form-inline my-2 my-lg-0">
            <ul className="w-100 navbar-nav mr-auto">
              <li className="nav-item dropdown ml-4 mr-1">
                <span
                  // style={{ color: '#fff', cursor: 'pointer' }}
                  className="fa fa-user dropdown-toggle text-white"
                  title="Navbar menu"
                  role="button"
                  id="dropdownProfilo"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownProfilo"
                >
                  {authenticated ? (
                    <>
                      <span className="dropdownTextVoice dropdown-item">
                        {header.user}:{" "}
                        {user
                          ? user?.firstName + " " + user?.lastName
                          : userSession.email || "Account"}
                      </span>

                      <span className="dropdown-submenu dropdown-item">
                        <button className="dropbtn p-0">
                          {header.language}:{" "}
                          {getValueFromLocalStorage("i18nextLng") === "it"
                            ? header.italiano
                            : header.inglese}
                        </button>
                        <div className="dropdown-content">
                          <a onClick={() => i18n.changeLanguage("en")}>
                            {header.inglese}{" "}
                            <img
                              className="flagIcons"
                              src={eng}
                              alt="Inglese"
                            />
                          </a>
                          <a onClick={() => i18n.changeLanguage("it")}>
                            {header.italiano}{" "}
                            <img
                              className="flagIcons"
                              src={ita}
                              alt="Italiano"
                            />
                          </a>
                        </div>
                      </span>

                      <span className="dropdownTextVoice dropdown-item">
                        {header.buildVersion}{" "}
                        {config.BuildVersion === "#{buildnumber}#"
                          ? "localBuild"
                          : config.BuildVersion}
                      </span>

                      <Link
                        className="dropdown-item"
                        onClick={logout}
                        to="/home"
                      >
                        {header.exit}
                        <span
                          style={{ color: "red" }}
                          className="fa fa-power-off ml-2"
                        />
                      </Link>
                    </>
                  ) : (
                    <>
                      <span className="dropdown-submenu dropdown-item">
                        <button className="dropbtn p-0">
                          {header.language}
                        </button>
                        <div className="dropdown-content">
                          <a onClick={() => i18n.changeLanguage("en")}>
                            {header.inglese}
                          </a>
                          <a onClick={() => i18n.changeLanguage("it")}>
                            {header.italiano}
                          </a>
                        </div>
                      </span>

                      <button className="dropdown-item" onClick={login}>
                        {header.login} <span className="fa fa-sign-in ml-2" />
                      </button>
                    </>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
