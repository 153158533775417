import React from "react";
import { DatePicker, Form, FormItemProps } from "antd";
import { Moment } from "moment";

export interface AntdFormRangePickerProps extends FormItemProps {
  disabledDate?: (date: Moment) => boolean;
  disabled?: boolean;
  name: any;
  label: string;
  placeholder?: [string, string];
  customClass?: string;
  format?: string;
  onChange?: (a: any) => void;
  onOk?: (value: any) => void;
  /** @deprecated use initialValue */
  defaultValue?: Moment;
  noWrapperStyle?: boolean;
  showTime?: boolean;
  timeFormat?: string;
}

const colProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const AntdFormRangePickerField: React.FC<AntdFormRangePickerProps> = ({
  disabledDate,
  disabled,
  name,
  label,
  placeholder,
  customClass,
  defaultValue,
  initialValue,
  format,
  onChange,
  onOk,
  noWrapperStyle,
  showTime = false,
  timeFormat,
  ...otherProps
}) => {

  const { RangePicker } = DatePicker;

  return (
    <div
      className={
        !noWrapperStyle
          ? `col-12 col-sm-12 col-md-6 col-lg-4 my-1 ${customClass}`
          : ""
      }
    >
      <Form.Item
        initialValue={initialValue || defaultValue}
        name={name}
        label={label}
        {...otherProps}
        {...colProps}
      >
        <RangePicker
          showTime={showTime && { format: timeFormat || "HH:mm" }}
          {...otherProps}
          className="w-100"
          format={format || "DD/MM/yyyy"}
          disabled={disabled}
          disabledDate={disabledDate}
          onChange={onChange ? (e) => onChange(e) : (e) => null}
          onOk={onOk}
          style={{ width: '100%' }}
          placeholder={placeholder}
        />
      </Form.Item>
    </div>
  );
};

export default AntdFormRangePickerField;
