import { NotificationInterface } from "./notification.interface";
import { notification } from "antd";
import { commonTranslations } from "../../helpers/translations";

const translateObj: any = commonTranslations?.notifications;

export const operationError = (httpStatus?: number): NotificationInterface => {
  const errorKey =
    httpStatus && httpStatus === 409 ? "deleteError" : "operationError";
  return {
    type: "error",
    params: {
      message: translateObj["error"],
      description: translateObj[errorKey],
    },
  };
};

export const customOperationError = (value: string): NotificationInterface => {
  return {
    type: "error",
    params: {
      message: translateObj["error"],
      description: translateObj[value],
    },
  };
};

export const operationSuccess = (config?: {messageKey?: string, descriptionKey?: string}): NotificationInterface => {
  return {
    type: "success",
    params: {
      message: translateObj[config?.messageKey || "success"],
      description: translateObj[config?.descriptionKey || "operationSuccess"],
    },
  };
};

export const operationInfo = (config: {messageKey: string, descriptionKey: string}): NotificationInterface => {
  return {
    type: "info",
    params: {
      message: translateObj[config.messageKey],
      description: translateObj[config.descriptionKey],
    },
  };
};

export const searchError = (): NotificationInterface => {
  return {
    type: "error",
    params: {
      message: translateObj["error"],
      description: translateObj["searchError"],
    },
  };
};

export const searchSuccess = (): NotificationInterface => {
  return {
    type: "success",
    params: {
      message: translateObj["success"],
      description: translateObj["searchSuccess"],
    },
  };
};

export const notify = (config: NotificationInterface) => {
  notification[config.type](config.params);
};
