import React, { useEffect, useState } from 'react';
/* AntD */
import { FormInstance, Tabs } from 'antd';
/* Components */
import { AntdFormInputField, AntdFormSelectField, AntdFormRadioField, OptionProps } from '../../components';
/* Services */
import { getApplicationCode, getDropdownByKey } from '../../services/masterData';
/* Utils */
import { uploadTranslations as upload, getRequiredValidation, UPLOAD_FIELDS, STATIC_OPTIONS } from '../../helpers';

interface DynamicFieldsProps {
    changedFields: any;
    formRef: FormInstance;
    formStructure: {
        [key: string]: string[]
    },
    clearFields: () => void
}

export const DynamicFields: React.FC<DynamicFieldsProps> = ({ changedFields, formRef, formStructure, clearFields }) => {

    const [shownKey, setShownKey] = useState<string>(null);
    const [options, setOptions] = useState({});

    useEffect(() => {
        setShownKey(Object.keys(formStructure)[0]);
    }, [formStructure])

    useEffect(() => {
        shownKey && populateDropdownsOptions()
    }, [shownKey])

    useEffect(() => {
        changedFields && shownKey && populateDropdownsOptions();
    }, [changedFields])

    const populateDropdownsOptions = () => {

        if(changedFields.documentsList) return;

        const fields: any = formStructure[shownKey];
        if (!fields?.length) return

        for (const dependsOnKey of fields) {
            const field = UPLOAD_FIELDS[dependsOnKey]
            if(field.key === "month") {
                setOptions((state) => ({...state, "month": STATIC_OPTIONS.month}))
                continue;
            };
            let params = { columnKey: dependsOnKey }

            if (field.dependsOn) {
                /* Filtro SOLO i valori effettivamente impostati */
                const changedKey = Object.keys(changedFields)[0];
                if(field.dependsOn?.includes(changedKey)) {
                    formRef.setFieldsValue({[field.name]: undefined})
                    setOptions((state) => ({ ...state, [dependsOnKey]: [] }))
                }
                const parentFieldValues = field.dependsOn.map((key: string) => {

                    const objValue = Object.values(UPLOAD_FIELDS).find(uploadFieldValue => uploadFieldValue.name === key);
                    const keyToUse = objValue?.key;

                    return { key: keyToUse, value: formRef.getFieldValue(key) }
                }).filter(({ value }: any) => {
                    return value
                })
                const checkFieldsValues = parentFieldValues.length === field.dependsOn.length;

                if (!checkFieldsValues) {
                    setOptions((state) => ({ ...state, [dependsOnKey]: [] }))
                    continue;
                }

                parentFieldValues.forEach((parentValue: any) => {
                    params = { ...params, [parentValue.key]: parentValue.value }
                });
            }
            getDropdownData(dependsOnKey, params, dependsOnKey === "applicationCode");
        }

    }

    const getDropdownData = async (fieldToFetch: string, params: any, isApplicationCode: boolean) => {
        try {
            
            const masterDataResponse = isApplicationCode ? await getApplicationCode(params) : await getDropdownByKey(fieldToFetch === "businessUnit" ? "innerBusinessUnit" : fieldToFetch, params.cig);
            setOptions((state) => ({ ...state, [fieldToFetch]: masterDataResponse.values.map<OptionProps>(value => ({ name: value, value })) }))
        } catch (err) {
            console.log("Error", err);
            setOptions((state) => ({ ...state, [fieldToFetch]: [] }))
        }
    }

    const onTabChange = (activeKey: string) => {
        clearFields();
        setShownKey(activeKey);
    }

    return (
        formStructure && <>
            <div className='col-12 w-100'>
                <Tabs defaultActiveKey={shownKey} activeKey={shownKey} destroyInactiveTabPane={true} onTabClick={onTabChange}>
                    {Object.keys(formStructure).map((formKey, tabsIndex) => (
                        <Tabs.TabPane key={formKey} tab={<span>{upload.labels[formKey]}</span>} />
                    ))}
                </Tabs>
            </div>

            <div className='w-100 mb-4'>
                {formStructure[shownKey]?.map((fieldKey, index) => {
                    const field = UPLOAD_FIELDS[fieldKey]
                    return <>

                        {field.type === "text" &&
                            <AntdFormInputField
                                key={index}
                                inputName={field.name}
                                placeholder={upload.placeholders[field.key]}
                                label={upload.labels[field.key]}
                                rules={getRequiredValidation("upload", field.key)}
                                cols={field.cols}
                                required={field.required}
                            />
                        }
                        {field.type === "select" &&
                            <AntdFormSelectField
                                name={field.name}
                                key={index}
                                placeholder={upload.placeholders[field.key]}
                                label={upload.labels[field.key]}
                                rules={getRequiredValidation("upload", field.key)}
                                cols={field.cols}
                                options={() => options[field.key] || []}
                                disabled={!options[fieldKey]?.length}
                                required={field.required}
                            />
                        }
                        {field.type === "optgroup" &&
                            <div className="col-12 my-1">
                                <AntdFormRadioField
                                    key={index}
                                    name={field.name}
                                    label={upload.labels[field.key]}
                                    rules={getRequiredValidation("upload", field.key)}
                                    required={field.required}
                                    radios={field.options}
                                />
                            </div>
                        }

                    </>
                })}
            </div>
        </>
    )
}
