import { AutoComplete, AutoCompleteProps, Form, FormItemProps } from "antd";
import React, { useCallback, useState } from "react";

export interface SuggestedValue {
  data?: any;
  value: string;
}

export interface AntdFormAutoCompleteProps extends AutoCompleteProps {
  onSuggestedOptions?: <T extends unknown>(
    arg: string
  ) => Promise<SuggestedValue[]>;
  customClass?: string;
  name?: string;
  formItemsProps?: FormItemProps;
  label?: string;
  noDebounce?: boolean
}

const AntdFormAutoCompleteField: React.FC<AntdFormAutoCompleteProps> = (
  props
) => {
  const [options, setOptions] = useState<SuggestedValue[]>([]);
  const { customClass, name, label, formItemsProps, noDebounce = false, ...otherProps } = props;
  const onSearch = async (text: string) => {
    if (!props.onSuggestedOptions) return;
    const suggestedOptions = await props.onSuggestedOptions(text);
    setOptions(suggestedOptions);
  };

  const debounce = (func: (...arg: any) => void) => {
    let timer: any;
    return function (...args: any) {
      /* eslint-disable */
      const self = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(self, args);
      },  1000);
    };
  };

  const optimizedFn = useCallback(debounce(onSearch), []);
  return (
    <div
      className={
        customClass ? customClass : "col-12 col-sm-12 col-md-6 col-lg-4 my-1"
      }
    >
      <Form.Item name={name} label={label} {...formItemsProps}>
        <AutoComplete options={options} onSearch={noDebounce ? onSearch : optimizedFn} {...otherProps} />
      </Form.Item>
    </div>
  );
};

export default AntdFormAutoCompleteField;
