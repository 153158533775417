import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
/* AntD */
import { Button, Collapse, Form, List, Spin, Tabs } from 'antd';
/* Components */
import { AntdFormSelectField, CustomModal } from '../../../components';
import IconByMimeType from '../../../components/Utility/IconByMimeType';
import { SearchCA, SearchCIG, SearchGDPR, SearchODL, SearchKPI, SearchReadiness, SearchKnowledgeManagement, SearchProcedureAlt, SearchReportingServizi } from '../../../components/search/consultSearch';
/* Services and interfaces */
import { FileSystemIndex, searchInFileSystem } from '../../../services/search';
import { getDropdownByKey, MasterDataResponse } from '../../../services/masterData';
/* Translations */
import { consultTranslations, commonTranslations as common, commonTranslations } from '../../../helpers/translations';
/* Providers */
import { useConsult } from '../consultProvider';
/* Utility */
import { useSafeEffect } from '../../../helpers/hooks';
import { getRequiredValidation, mapToSelectData } from '../../../helpers';
import { notify, operationError } from '../../../services/notification-wrapper';
import { removeItemsFromSessionStorage } from '../../../services/storage-wrapper';

const ConsultSearch = () => {

    const navigate = useNavigate();
    const { search } = consultTranslations;

    const [openSearch, setOpenSearch] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [fileSystemIndex, setFileSystemIndex] = useState<FileSystemIndex[] | null>(null);
    const [cig, setCig] = useState([])

    const { labels, placeholders } = commonTranslations;
    const { searchParams, selectedCig, setSearchParams, setPath, setSelectedCig } = useConsult();

    useEffect(() => {
        searchParams && handleSearch(searchParams, searchParams.selectedSearch);
        setOpenSearch(searchParams ? null : "1");
    }, [])

    useSafeEffect(
        [],
        () => {
            return getDropdownByKey("cig")
        },
        (data: MasterDataResponse) => {
            data && setCig(mapToSelectData(data.values));
        },
        (err: any) => notify(operationError())
    );


    const handleSearch = async (requestParams, selectedSearch) => {
        /* Avoid search without any param */
        if (!requestParams) {
            setFileSystemIndex(null);
            setSearchParams(null);
            removeItemsFromSessionStorage("searchParams");
            return;
        }
        try {
            setIsSearching(true);
            const fsiResponse = await searchInFileSystem({ requestParams: { ...requestParams, cig: selectedCig, searchType: selectedSearch.split("search")[1] }, limit: 1000 });
            setSearchParams({ ...requestParams, selectedSearch });
            setOpenSearch(null);
            setFileSystemIndex(fsiResponse.fileSystemIndex);
        } catch (error) {
            notify(operationError());
        } finally {
            setIsSearching(false);
        }
    }

    const handleNavigation = (path: string) => {
        //TODO: Migliora gestione, aggiungere cifratura
        setPath(path);
        navigate("/consult/documents/results")
    }

    const buildItemDescription = (item: FileSystemIndex): string => {
        let stringToBuild = ""
        for (const key in common?.labels) {
            if (item[key]) {
                stringToBuild && (stringToBuild += " | ")
                stringToBuild += `${common.labels[key]}: ${item[key]}`
            }
        }
        return stringToBuild;
    }

    const tabList = [
        {
            key: 'searchCIG',
            tab: search.labels.contratto,
        },
        {
            key: 'searchCA',
            tab: search.labels.ca,
        },
        {
            key: 'searchODL',
            tab: 'OdL',
        },
        {
            key: 'searchGDPR',
            tab: 'GDPR',
        },
        {
            key: 'searchKPI',
            tab: 'KPI',
        },
        // {
        //     key: 'searchReadiness',
        //     tab: search.labels.readiness,
        // },
        {
            key: 'searchKnowledgeManagement',
            tab: search.labels.knowledgeManagement,
        },
        {
            key: 'searchProcedureAlt',
            tab: search.labels.procedureAlt,
        },
        {
            key: 'searchReportingServizi',
            tab: search.labels.reportingServizi,
        },
    ];

    const contentList = {
        searchCA: <SearchCA onSearch={handleSearch} isSearching={isSearching} />,
        searchCIG: <SearchCIG onSearch={handleSearch} isSearching={isSearching} />,
        searchODL: <SearchODL onSearch={handleSearch} isSearching={isSearching} />,
        searchGDPR: <SearchGDPR onSearch={handleSearch} isSearching={isSearching} />,
        // searchReadiness: <SearchReadiness onSearch={handleSearch} isSearching={isSearching} />,
        searchKPI: <SearchKPI onSearch={handleSearch} isSearching={isSearching} />,
        searchKnowledgeManagement: <SearchKnowledgeManagement onSearch={handleSearch} isSearching={isSearching} />,
        searchProcedureAlt: <SearchProcedureAlt onSearch={handleSearch} isSearching={isSearching} />,
        searchReportingServizi: <SearchReportingServizi onSearch={handleSearch} isSearching={isSearching} />
    };

    return <>
        <section className="col-sm-12 col-md-10 col-lg-10' p-4 section vh-80">
            <Collapse
                activeKey={[openSearch]}
                onChange={(key) => setOpenSearch(key[1])}
                expandIconPosition="right"
            >
                <Collapse.Panel header={search.title} key="1" extra={<Button type='primary' onClick={(event) => {
                    event.stopPropagation();
                    handleSearch(null, '')
                    setSelectedCig(null)
                    setOpenSearch("1")
                }}>{search.changeCig}</Button>}>
                    <Tabs defaultActiveKey={searchParams?.selectedSearch || "searchCIG"} destroyInactiveTabPane={true} onChange={() => setSearchParams(null)}>
                        {tabList.map(tab => <Tabs.TabPane key={tab.key} tab={tab.tab}>
                            {contentList[tab.key]}
                        </Tabs.TabPane>)}
                    </Tabs>
                </Collapse.Panel>
            </Collapse>


            {fileSystemIndex && !isSearching ?
                <List
                    header={<h3>{search.searchResults}</h3>}
                    itemLayout="horizontal"
                    dataSource={fileSystemIndex}
                    className='fsiList mt-4 p-2'
                    renderItem={item => (
                        <List.Item onClick={() => handleNavigation(item.path)}  >
                            <List.Item.Meta
                                avatar={<IconByMimeType mimeType='closed-directory' size={30} />}
                                title={<Button type='link' className='px-0'>{item.path}</Button>}
                                description={buildItemDescription(item)}
                            />
                        </List.Item>
                    )}
                />
                :
                isSearching && <div className="spinnerCont">
                    <Spin size='large' />
                </div>
            }
        </section>

        {!selectedCig &&
            <CustomModal
                destroyOnClose={true}
                title={search.selectCig}
                visible={!selectedCig}
                closable={false}
                okButtonProps={{ form: 'cig-selection-form', htmlType: 'submit' }}
                okText={common.buttons.confirm}
                onCancel={() => false}
                cancelButtonProps={{ style: { display: "none" } }}
                modalBody={
                    <Form id='cig-selection-form' onFinish={(values) => setSelectedCig(values.cig)}>
                        <AntdFormSelectField
                            name={"cig"}
                            label={labels.cig}
                            placeholder={placeholders.cig}
                            options={cig}
                            cols="col-12"
                            loading={false}
                            disabled={isSearching}
                            rules={getRequiredValidation("common", "cig")}
                        />
                    </Form>
                }
            />}
    </>
}

export default ConsultSearch;
