import React, { useState } from "react";
/* Components */
import { AntdSearch } from "../../";
import { AntdFormSelectField } from "../../common/dataEntry";
/* Services and Interfaces */
import { ConsultSearchInterface } from "./";
import { MasterDataResponse, getDropdownByKey } from "../../../services/masterData";
/* Translations */
import { commonTranslations } from "../../../helpers";
/* Utility */
import { mapToSelectData } from "../../../helpers";
import { useSafeEffect } from "../../../helpers/hooks";
import { notify, operationError } from "../../../services/notification-wrapper";
/* Providers */
import { useConsult } from "../../../views/consult/consultProvider";

const SearchCA: React.FC<ConsultSearchInterface> = ({
  onSearch,
  isSearching
}) => {

  const [loading, setLoading] = useState(false);
  const { labels, placeholders } = commonTranslations;

  const { years, businessUnits, societaInfragruppo, searchParams, selectedCig, suppliers, setMasterdataCache } = useConsult()

  useSafeEffect(
    [selectedCig],
    () => {
      if (!selectedCig && years.length && businessUnits.length && societaInfragruppo.length && suppliers.length) return Promise.resolve(null);
      setLoading(true);
      const yearsPromise = getDropdownByKey("year", selectedCig);
      const businessUnitsPromise = getDropdownByKey("businessUnit", selectedCig);
      const societaInfragruppoPromise = getDropdownByKey("societaInfragruppo", selectedCig);
      const supplierPromise = getDropdownByKey("supplier", selectedCig);
      return Promise.all([yearsPromise, businessUnitsPromise, societaInfragruppoPromise, supplierPromise]);
    },
    (data: [MasterDataResponse, MasterDataResponse, MasterDataResponse, MasterDataResponse]) => {
      if (data && data.length > 1) {
        const [_years, _businessUnits, _societaInfragruppo, _suppliers] = data;
        setMasterdataCache({
          years: mapToSelectData(_years?.values),
          businessUnits: mapToSelectData(_businessUnits?.values),
          societaInfragruppo: mapToSelectData(_societaInfragruppo?.values),
          suppliers: mapToSelectData(_suppliers?.values)
        })
      }
    },
    (err: any) => notify(operationError()),
    () => setLoading(false)
  );

  return <AntdSearch name="searchCA" onSearch={(formValues) => onSearch(formValues, "searchCA")} loading={isSearching} initialValues={searchParams} resetTrigger={selectedCig} atLeastOneFieldRequired>
    
    <AntdFormSelectField
      name={"supplier"}
      label={labels.supplier}
      placeholder={placeholders.supplier}
      options={suppliers}
      loading={loading}
      disabled={isSearching}
    />
    <AntdFormSelectField
      name={"year"}
      label={labels.year}
      placeholder={placeholders.year}
      options={years}
      loading={loading}
      disabled={isSearching}
    />
    <AntdFormSelectField
      name={"businessUnit"}
      label={labels.businessUnit}
      placeholder={placeholders.businessUnit}
      options={businessUnits}
      loading={loading}
      disabled={isSearching}
    />
    <AntdFormSelectField
      name={"societaInfragruppo"}
      label={labels.societaInfragruppo}
      placeholder={placeholders.societaInfragruppo}
      options={societaInfragruppo}
      loading={loading}
      disabled={isSearching}
    />
  </AntdSearch>


}

export default SearchCA;