import React from "react";
import { Form, Input, FormItemProps } from "antd";

export interface AntdFormInputFieldProps extends FormItemProps {
  /** @deprecated use name instead */
  inputName?: string;
  cols?: string;
  disabled?: boolean;
  placeholder?: string;
  label: string;
  rules?: any;
  onChange?: any;
  required?: boolean;
  maxLength?: number;
  onKeyDown?: (event: any) => void;
}

const colProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const AntdFormInputField: React.FC<AntdFormInputFieldProps> = ({
  inputName,
  cols,
  name,
  label,
  rules,
  disabled,
  placeholder,
  required,
  maxLength,
  onKeyDown,
  ...otherProps
}) => {
  return (
    <div className={cols || "col-12 col-sm-12 col-md-6 col-lg-4 my-1"}>
      <Form.Item
        name={name || inputName}
        label={label}
        rules={rules}
        {...otherProps}
        {...colProps}
      >
        <Input
          name={(name as string) || inputName}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
        />
      </Form.Item>
    </div>
  );
};

export default AntdFormInputField;
