import React from "react";
import { Form, Radio, FormItemProps, Checkbox } from "antd";

export interface AntdFormCheckboxFieldProps extends FormItemProps {
  name: string;
  label: string;
  placeholder?: string;
}

const colProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const AntdFormCheckbox: React.FC<AntdFormCheckboxFieldProps> = ({
  name,
  label,
  required,
  placeholder,
  ...otherProps
}) => {
  return (
  <Form.Item name={name} label={label} valuePropName="checked" {...otherProps} {...colProps}>
      <Checkbox>{placeholder}</Checkbox>
    </Form.Item>
  );
};

export default AntdFormCheckbox;
