import { Axios_Post } from "../../axios";
import {
  NavigationParams,
  NavigationResponse
} from ".";

export const API_NAVIGATE = "/v1/navigate";

export const navigateTo = async (navigationParams?: NavigationParams): Promise<NavigationResponse> => {
  return Axios_Post<NavigationResponse>(API_NAVIGATE, navigationParams);
};
