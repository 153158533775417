import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { withErrorBoundary } from "react-error-boundary";
import { documentTypesTranslations, commonTranslations } from "../../helpers/translations";
import { Button, Collapse, Popconfirm, Spin, Tooltip } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  UnlockOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  AntdFormInputField,
  AntdSearch,
  CustomColumnType,
  CustomGridAntd,
  Download,
  ErroredLayout,
  Layout,
} from "../../components";
import {
  DocumentTypeParams,
  DocumentType,
  DocumentTypeResponse,
} from "../../services/documentTypes";
import {
  notify,
  operationError,
  operationSuccess,
} from "../../services/notification-wrapper";
import { getDocumentTypes } from "../../services/documentTypes";
import { useSafeEffect } from "../../helpers/hooks";
import { updateDocumentType } from "../../services/documentTypes/documentTypes.service";

const DocumentTypeList = withErrorBoundary(
  () => {
    const navigate = useNavigate();

    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);

    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [params, setParams] = useState<DocumentTypeParams>();

    const [sort, setSort] = useState<any>(null);
    const [lockDeletion, setLockDeletion] = useState(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isSaving, setIsSaving] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const documentTypesTrans = documentTypesTranslations;

    useSafeEffect(
      [],
      () => {
        setTableLoading(true);
        setLoading(true);
        return getDocumentTypesListInBegin();
      },
      (data) => {
        if (data) {
          setDocumentTypes(data.documentType);
          setTotalRows(data.totalrows || 0);
        }
      },
      (e) => notify(operationError()),
      () => {
        setTableLoading(false);
        setLoading(false);
      }
    );

    const getDocumentTypesListInBegin = async (): Promise<DocumentTypeResponse> => {
      return await getDocumentTypes();
    };

    const fetchDocumentTypeList = async (newParams?: { [key: string]: any }) => {
      setTableLoading(true);

      try {
        const concatenatedParams = { ...params, ...newParams };
        setParams(concatenatedParams);
        const docTypesResponse: DocumentTypeResponse = await getDocumentTypes({
          ...params,
          ...newParams,
        });
        setDocumentTypes(docTypesResponse.documentType);
        setTotalRows(docTypesResponse.totalrows || 0);
      } catch (e) {
        console.log("Error fetching data? ", e);
        notify(operationError());
      } finally {
        setTableLoading(false);
        setLoading(false);
      }
    };

    const onDelete = async (id: number) => {
      try {
        const dT = documentTypes.find((ref) => ref.id === id);
        if (!dT) {
          notify(operationError());
        }
        await updateDocumentType({ ...dT, isDeleted: 1 });
        const filteredUsers = documentTypes.filter((bond) => bond.id !== id);
        setDocumentTypes(filteredUsers);
        setTotalRows(totalRows - 1);
        notify(operationSuccess());
      } catch (e: any) {
        notify(operationError(e.response.status));
      }
    };

    const goToEdit = (id?: number) => {
      let url = "/configuration/documentTypes/edit";
      if (id) {
        url += `/${id}`;
      }
      navigate(url);
    };

    const sortRow = (sortType: any, key: string) => {
      if (
        sortType?.sortColumns?.length &&
        key === sortType?.sortColumn?.key &&
        ((sort?.sortColumn === sortType?.sortColumn &&
          sort?.sortOrder !== sortType?.sortOrder) ||
          sort?.sortColumn !== sortType?.sortColumn)
      ) {
        setSort(sortType);
        fetchDocumentTypeList({
          order_by: sortType.sortOrder ? sortType.sortColumn.dataIndex : "",
          sort_dir: sortType.sortOrder === "descend" ? "desc" : "asc",
        }).then(() => {
          setTableLoading(false);
        });
      }
    };

    const columns: CustomColumnType[] = [
      {
        title(columnsSort) {
          sortRow(columnsSort, "label");
          return documentTypesTrans.tableHeadings.name;
        },
        dataIndex: "label",
        key: "label",
        name: documentTypesTrans.tableHeadings.name,
        sorter: (a) => false,
      },
      ...[
        {
          title() {
            return (
              <>
                <div>
                  <span>{documentTypesTrans.tableHeadings.actions}</span>
                  <Tooltip
                    title={
                      lockDeletion
                        ? commonTranslations.tooltips.unlockDeletion
                        : commonTranslations.tooltips.lockDeletion
                    }
                  >
                    <Button
                      type="link"
                      onClick={() => setLockDeletion(!lockDeletion)}
                    >
                      {lockDeletion ? (
                        <LockOutlined style={{ fontSize: "125%" }} />
                      ) : (
                        <UnlockOutlined style={{ fontSize: "125%" }} />
                      )}
                    </Button>
                  </Tooltip>
                </div>
              </>
            );
          },
          dataIndex: "actions",
          key: "actions",
          name: "",
          align: "center",
          render: (id: number) => (
            <div className="d-flex w-100 justify-content-center">
              <Tooltip title={commonTranslations.tooltips.edit}>
                <Button type="link" onClick={() => goToEdit(id)}>
                  <EditOutlined style={{ fontSize: "125%" }} />
                </Button>
              </Tooltip>

              <Popconfirm
                title={commonTranslations.notifications.deleteText}
                disabled={lockDeletion}
                onConfirm={(e) => onDelete(id)}
                okText={commonTranslations.buttons.yes}
                cancelText={commonTranslations.buttons.no}
              >
                <Tooltip title={commonTranslations.tooltips.delete}>
                  <Button
                    type="link"
                    style={{ color: lockDeletion ? "gray" : "red" }}
                    disabled={lockDeletion}
                  >
                    <DeleteOutlined style={{ fontSize: "125%" }} />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
          ),
        },
      ],
    ];

    const dataSource = documentTypes.map(({
      id,
      label
    }: DocumentType,
      index: number
    ) => ({
      key: index,
      label,
      actions: id,
    })
    );

    return (
      <Layout title={documentTypesTrans.title} setIsEditing={goToEdit}>
        <>
          {showFilters && (
            <AntdSearch
              name="documentTypeSearch"
              onSearch={(searchParams) => {
                setCurrentPage(1);
                fetchDocumentTypeList({ ...searchParams, offset: 0 })
              }}
            >
              <AntdFormInputField
                name="label"
                placeholder={documentTypesTrans.search.placeholders.name}
                label={documentTypesTrans.search.labels.name}
              />
            </AntdSearch>
          )}

          <section className="row p-3 mt-4 mx-2 section">
            {loading ? (
              <div className={"d-flex flex-fill justify-content-center p-4"}>
                <Spin size="large" />
              </div>
            ) : (
              <>
                <div className="col-12 my-2 text-right">
                  <Button
                    onClick={() => setShowFilters(!showFilters)}
                    className={"mx-1 mr-2 d-inline-block"}
                  >
                    <i className="fa fa-filter" aria-hidden="true" />
                  </Button>
                  <Button type="primary" onClick={() => goToEdit()}>{commonTranslations.buttons.new}</Button>
                </div>
                <CustomGridAntd
                  loading={tableLoading}
                  dataSource={dataSource}
                  columns={columns}
                  total={totalRows}
                  currentPage={currentPage}
                  pageChange={(page: number, pageSize?: number) => {
                    setCurrentPage(page);
                    const size = pageSize ? pageSize : 10
                    fetchDocumentTypeList({ offset: (page - 1) * size, limit: pageSize });
                  }}
                />
              </>
            )}
          </section>
        </>
      </Layout>
    );
  },
  { FallbackComponent: ErroredLayout }
);

export default DocumentTypeList;
