import React, { useEffect, useState } from 'react';
/* AntD */
import { Button, Empty } from 'antd';
import { CloseOutlined, DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
/* Components */
import { File } from './file';
import FileDetails from './fileDetails';
import FilePreview from './filePreview';
import { CustomColumnType, CustomGridAntd, CustomModal, ContextMenu, IconByMimeType, useAuth } from "../../../../components";
/* Services and interfaces */
import { FileSystemTree } from '../../../../services/navigate';
import { deleteFiles } from '../../../../services/documents';
/* Translations */
import { commonTranslations as common, consultTranslations as consult } from '../../../../helpers/translations';
/* Utility */
import { formatFileSize, singleDownload, multipleDownload, formatDate } from '../../../../helpers';
import { notify, operationError, operationSuccess } from '../../../../services/notification-wrapper';
/* Providers */
import { useConsult } from '../../consultProvider';

interface FileSectionProps {
    files: {
        directories: FileSystemTree[];
        files: FileSystemTree[];
    };
    type: string;
    loading: boolean;
    refreshList: () => void;
    toggleLocalLoading: (value: boolean) => void;
}

const FileSection: React.FC<FileSectionProps> = ({ files, type, loading, refreshList, toggleLocalLoading }) => {

    const { user } = useAuth()
    const resultTranslations = consult.results;
    const canDelete = !["ro"].includes(user?.role?.accessLevel)

    const [selectedElement, setSelectedElement] = useState<FileSystemTree>(null);
    const [actionType, setActionType] = useState<"preview" | "detail">(null);

    const [contextMenu, setContextMenu] = useState({
        visible: false,
        style: null,
        content: []
    });

    const { path, setPath, enableFileSelection, setEnableFileSelection, selectedFiles, setSelectedFiles } = useConsult();

    useEffect(() => {
        document.addEventListener("click", _handleClickOutside);
        return () => {
            document.removeEventListener("click", _handleClickOutside);
        }
    }, [])

    useEffect(() => {
        !enableFileSelection && setSelectedFiles([])
    }, [enableFileSelection])

    const _handleContextMenu = (event, record?) => {
        event.preventDefault();

        const style = {
            left: `${event.pageX - 215}px`,
            top: `${event.pageY - 170}px`,
            right: "0px"
        };

        toggleContextMenu({
            ...contextMenu,
            style,
            visible: true,
            content: _buildContextMenuButtons(record)
        });
    };

    const _handleClickOutside = event => {
        const { target } = event

        if (!target.closest(".context-menu-container")) {
            toggleContextMenu();
        }
    }

    const _buildContextMenuButtons = (record: FileSystemTree) => {
        return [
            ...(record.mimeType !== "inode/directory" ? [{
                info: common.buttons.download,
                onClick: () => handleDownload(record.s3filename, record.fsitem)
            }] : []),
            {
                info: common.buttons.details,
                onClick() {
                    selectElement(record, "detail");
                    toggleContextMenu();
                }
            },
            ...(canDelete ? [{
                info: common.buttons.delete,
                style: {
                    color: "red"
                },
                onClick() {
                    handleDelete([record.s3filename]);
                    toggleContextMenu();
                }
            }] : [])
        ]
    }

    const handleDelete = async (s3fileNameList: string[]) => {
        try {
            toggleLocalLoading(true);
            const idList = files.files.filter(file => s3fileNameList.includes(file.s3filename)).map(file => file.id);
            await deleteFiles(idList);
            notify(operationSuccess());
            setSelectedFiles([]);
            setEnableFileSelection(false);
            refreshList();
        } catch (e) {
            console.error(e);
            notify(operationError());
            toggleLocalLoading(false);
        }
    }

    const handleDownload = async (s3filename: string, filename?: string) => {
        try {
            await singleDownload(s3filename, filename);
        } catch (error) {
            notify(operationError())
        } finally {
            toggleContextMenu();
        }
    }

    const handleMultipleDownload = async () => {
        try {
            toggleLocalLoading(true);
            await multipleDownload(files?.files?.filter(file => selectedFiles.includes(file.s3filename)));
            setSelectedFiles([]);
            setEnableFileSelection(false);
            notify(operationSuccess());
        } catch (error) {
            notify(operationError());
        } finally {
            toggleLocalLoading(false);
        }
    }

    const toggleContextMenu = (data = { visible: false, style: { top: "0px", left: "0px" }, content: [] }) => {
        setContextMenu(data);
    }

    const selectElement = (element: FileSystemTree = null, action: "preview" | "detail" = null) => {
        if (action === "preview" && element.mimeType !== "application/pdf") {
            handleDownload(element.s3filename, element.fsitem);
            return;
        }

        setSelectedElement(element);
        setActionType(action);
    }

    const handleMultipleSelection = (fsitem: FileSystemTree) => {
        if (!enableFileSelection) {
            !loading && selectElement(fsitem, "preview");
            return;
        }
        const prevCheckStatus = selectedFiles.includes(fsitem.s3filename);

        prevCheckStatus ?
            setSelectedFiles(selectedFiles.filter(s3filename => s3filename !== fsitem.s3filename))
            :
            setSelectedFiles([...selectedFiles, fsitem.s3filename])
    }

    const columns: CustomColumnType[] = [
        {
            title: consult.tableHeadings.filename,
            dataIndex: "fsitem",
            key: "fsitem",
            render: (_, record) => <div className='d-flex'>
                <IconByMimeType mimeType={record?.mimeType} size={"1.5em"} />
                <span className='ml-2'>{record?.fsitem}</span>
            </div>
        },
        {
            title: consult.tableHeadings.documentType,
            dataIndex: "documentType",
            key: "documentType",
            render: (_, record) => record.mimeType === "inode/directory" ? common.labels.directory : record.documentType.label
        },
        {
            title: consult.tableHeadings.size,
            dataIndex: "size",
            key: "size",
            render: (_, record) => <span>{record.mimeType !== "inode/directory" && formatFileSize(record.size)}</span>
        },
        {
            title: consult.tableHeadings.creationDate,
            dataIndex: "creationDate",
            key: "creationDate",
            render: (_, record) => formatDate(record.creationDate, "DD/MM/yyyy HH:mm:ss")
        }]

    const dataSource = [...(files?.directories || []), ...(files?.files || [])].map((item, key) => ({ ...item, key: item.s3filename || item.id }))

    return <>



        {enableFileSelection && <div className="row w-100 d-flex justify-content-end py-3 px-2">
            <Button className='mr-1' onClick={() => setEnableFileSelection(false)} icon={<CloseOutlined />}>{selectedFiles.length} {common?.buttons?.selectedFiles}</Button>
            {canDelete && <Button className='ml-1 mr-1' danger onClick={() => selectedFiles.length && handleDelete(selectedFiles)} icon={<DeleteOutlined />} disabled={!selectedFiles.length}>{common?.buttons?.deleteFiles}</Button>}
            <Button className='ml-1' type='primary' onClick={() => selectedFiles.length && handleMultipleDownload()} icon={<DownloadOutlined />} disabled={!selectedFiles.length}>{common?.buttons?.downloadFiles}</Button>
        </div>}



        {(!!files?.directories?.length || !!files?.files?.length) ?
            <div className='row py-3 px-4'>
                {type === "table" &&
                    <CustomGridAntd
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        rowClassName="selectableTableRow context-menu-toggle"
                        onRow={(record, rowIndex) => {
                            return {
                                onClick() {
                                    if (enableFileSelection) {
                                        record.mimeType !== "inode/directory" && handleMultipleSelection(record);
                                        return;
                                    }
                                    record.mimeType === "inode/directory" ? setPath(`${path.length > 1 ? path : ""}/${record.fsitem}`) : selectElement(record, "preview")
                                },
                                onContextMenu: event => _handleContextMenu(event, record)
                            };
                        }}
                        rowSelection={enableFileSelection && {
                            onChange(selectedRowKeys: string[]) {
                                setSelectedFiles(selectedRowKeys)
                            },
                            getCheckboxProps(record) { return { ...record, disabled: record.mimeType === "inode/directory" } },
                            selectedRowKeys: selectedFiles
                        }}
                    />
                }
                {type === "grid" && <>
                    {!!files?.directories?.length &&
                        <div className="row col-12">
                            {files?.directories?.map((fsitem, index) => (
                                <File
                                    key={index}
                                    documentType={fsitem.documentType}
                                    fsitem={fsitem.fsitem}
                                    isFolder={fsitem.mimeType === "inode/directory"}
                                    mimeType={fsitem.mimeType}
                                    onClick={() => { !loading && setPath(`${path.length > 1 ? path : ""}/${fsitem.fsitem}`) }}
                                    onContextMenu={(event) => { _handleContextMenu(event, fsitem) }}
                                />
                            ))}
                        </div>
                    }
                    {!!files?.directories?.length && !!files?.files?.length && <hr className='w-100' />}
                    {!!files?.files?.length &&
                        <div className="row col-12">
                            {files?.files?.map((fsitem, index) =>
                                <File
                                    key={index}
                                    checked={selectedFiles.includes(fsitem.s3filename)}
                                    documentType={fsitem.documentType}
                                    enableFileSelection={enableFileSelection}
                                    fsitem={fsitem.fsitem}
                                    isFolder={fsitem.mimeType === "inode/directory"}
                                    mimeType={fsitem.mimeType}
                                    onClick={() => handleMultipleSelection(fsitem)}
                                    onContextMenu={(event) => { _handleContextMenu(event, fsitem) }}
                                />
                            )}
                        </div>
                    }
                </>
                }
            </div>
            :
            <Empty className='py-5 d-flex flex-column align-items-center' description={resultTranslations.noDataFound} />
        }
        {contextMenu.visible && <ContextMenu style={contextMenu.style} content={contextMenu.content} />}
        {actionType &&
            <CustomModal
                wrapClassName={`${actionType}Modal`}
                visible={!!selectedElement}
                draggable={actionType === "detail"}
                mask={actionType !== "detail"}
                maskClosable={actionType !== "detail"}
                destroyOnClose={true}
                onCancel={() => selectElement()}
                title={selectedElement.fsitem}
                modalBody={actionType === "detail" ? <FileDetails file={selectedElement} /> : <FilePreview s3fileName={selectedElement.s3filename} onError={selectElement} />}
                footer={null}
                bodyStyle={actionType === "detail" ? { minHeight: "50vh" } : null}
            />
        }
    </>
}

export default FileSection;
