import React, { ReactElement } from 'react';
import {
  FileExcelOutlined,
  FilePptOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  PlayCircleOutlined,
  FolderFilled
} from '@ant-design/icons';

interface IconByMimeTypeProps {
  mimeType?: string;
  size?: number | string;
}

const IconByMimeType = ({ mimeType, size }: IconByMimeTypeProps) => {
  const icons: { [key: string]: ReactElement } = {
    'audio/mpeg': <PlayCircleOutlined style={{ color: 'rgb(62 41 237)', fontSize: size, marginBottom: 10 }} />,
    'application/pdf': <FilePdfOutlined style={{ color: 'rgb(233 68 68)', fontSize: size, marginBottom: 10 }} />,
    'application/msword': <FileWordOutlined style={{ color: 'rgb(233 68 68)', fontSize: size, marginBottom: 10 }} />,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': (
      <FileWordOutlined style={{ color: 'rgb(22 110 183)', fontSize: size, marginBottom: 10 }} />
    ),
    'application/vnd.ms-excel': <FileExcelOutlined style={{ color: 'rgb(16 135 69)', fontSize: size, marginBottom: 10 }} />,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (
      <FileExcelOutlined style={{ color: 'rgb(16 135 69)', fontSize: size, marginBottom: 10 }} />
    ),
    'application/vnd.ms-powerpoint': <FilePptOutlined style={{ color: 'rgb(255 103 51)', fontSize: size, marginBottom: 10 }} />,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': <FilePptOutlined style={{ color: 'rgb(255 103 51)', fontSize: size, marginBottom: 10 }} />,
    'application/pkcs7-mime AND application/x-pkcs7-mime': <FileExcelOutlined style={{ color: 'rgb(16 135 69)', fontSize: size, marginBottom: 10 }} />,
    'text/html': <FileTextOutlined style={{ color: 'rgb(219 119 34)', fontSize: size, marginBottom: 10 }} />,
    'audio/mp3': <PlayCircleOutlined style={{ color: 'rgb(62 41 237)', fontSize: size, marginBottom: 10 }} />,
    'audio/wav': <PlayCircleOutlined style={{ color: 'rgb(62 41 237)', fontSize: size, marginBottom: 10 }} />,
    'image/png': <FileImageOutlined style={{ color: 'rgb(66 183 167)', fontSize: size, marginBottom: 10 }} />,
    'image/jpeg': <FileImageOutlined style={{ color: 'rgb(66 183 167)', fontSize: size, marginBottom: 10 }} />,
    'closed-directory': <FolderFilled style={{ color: 'rgb(230, 226, 131)', stroke: "black", strokeWidth: "15px", fontSize: size, marginBottom: 10 }} />,
    'inode/directory': <FolderFilled style={{ color: 'rgb(230, 226, 131)', fontSize: size, marginBottom: 10, stroke: "black", strokeWidth: "15px" }} />
  };

  return icons[mimeType] || <FileOutlined style={{ color: '#40a9ff', fontSize: size, marginBottom: 10 }} />;
};

export default IconByMimeType;
