import React, { createRef, useEffect, useState } from "react";

import { Form, FormItemProps, Select } from "antd";

export interface OptionProps {
  name: string;
  value: string | number;
  datum?: any;
}

export interface AntdFormSelectProps extends FormItemProps {
  placeholder?: string;
  name: any;
  label: string;
  disabled?: boolean;
  cols?: string;
  options: OptionProps[] | ((params?: any) => Promise<OptionProps[]>);
  customClass?: string;
  onChange?: (a: any) => any;
  onKeyDown?: (e: any) => any;
  defaultValue?: any;
  noWrapperStyle?: boolean;
  fieldKey?: any;
  loading?: boolean;
  mode?: "multiple" | "tags";
}

const colProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const AntdFormSelectField: React.FC<AntdFormSelectProps> = ({
  name,
  label,
  options,
  cols,
  placeholder,
  disabled,
  customClass,
  onChange,
  defaultValue,
  noWrapperStyle,
  fieldKey,
  loading,
  mode,
  onKeyDown,
  ...otherProps
}) => {
  const { Option } = Select;
  const [optionsArray, setOptionsArray] = useState<OptionProps[]>([]);
  let isActive = true;

  useEffect(() => {
    const initOptions = async (params?: any) => {
      let tempOpts: OptionProps[] = [];

      try {
        if (options instanceof Function) {
          tempOpts = await options(params);
        } else if (Array.isArray(options)) {
          tempOpts = options;
        }
      } catch (e: any) {
        console.log(e);
        tempOpts = [];
      } finally {
        if (isActive) {
          setOptionsArray(tempOpts);
        }
      }
    };

    initOptions();
    return () => {
      isActive = false;
    };
  }, [options]);

  return (
    <div
      className={
        !noWrapperStyle
          ? `${cols || "col-12 col-sm-12 col-md-6 col-lg-4"} my-1 ${customClass}`
          : ""
      }
    >
      <Form.Item
        name={name}
        fieldKey={fieldKey}
        label={label}
        {...otherProps}
        {...colProps}
        initialValue={defaultValue}
      >
        <Select
          {...otherProps}
          allowClear
          disabled={disabled}
          placeholder={placeholder}
          showSearch
          onKeyDown={onKeyDown}
          optionFilterProp="children"
          onChange={(e) => (onChange ? onChange(e) : false)}
          filterOption={(input: string, option: any) =>
            option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children?.toString()
              .toLowerCase()
              .localeCompare(optionB.children?.toString().toLowerCase())
          }
          loading={loading}
          mode={mode}
        >
          {optionsArray.map((optProps: OptionProps, index: number) => (
            <Option key={index} value={optProps.value}>
              {optProps.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default AntdFormSelectField;
