import React, { useEffect, useState } from 'react';
/* AntD */
import { Button, InputNumber, Spin } from 'antd';
import {LeftOutlined, RightOutlined} from "@ant-design/icons"
/* Services and interfaces */
import { getStorageDocumentS3ROKeys } from '../../../../services/documents';
/* Translations */
import {commonTranslations as common} from "./../../../../helpers/translations"
/* Utility */
import AWS from 'aws-sdk';
import { notify, operationError } from '../../../../services/notification-wrapper';
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface FilePreviewProps {
    s3fileName: string;
    onError: () => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ s3fileName, onError }) => {

    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pdfString, setPdfString] = useState("");

    useEffect(() => {
        getFile();
    }, []);

    const getFile = async () => {
        try {
            const keys = await getStorageDocumentS3ROKeys();

            const s3 = new AWS.S3({
                credentials: {
                    accessKeyId: keys.id,
                    secretAccessKey: keys.secret
                }
            })

            const objectData = {
                Bucket: keys.bucketname,
                Key: s3fileName,
            }

            s3.getObject(objectData, (err, result) => {
                if (err) {
                    onError();
                    return;
                }
                //@ts-ignore
                const base64String = btoa(new Uint8Array(result.Body).reduce(
                    function (data, byte) {
                        return data + String.fromCharCode(byte);
                    },
                    ''
                ));
                setPdfString(base64String);
            })

        } catch (error) {
            onError();
            notify(operationError());
        } finally {
            setLoading(false);
        }
    }

    const handlePageChange = (newPage: number) => {
        if(newPage < 1 || newPage > pageNumber) {
            newPage = newPage < 1 ? 1 : pageNumber
        } 
        setCurrentPage(newPage);
    }

    const removeElementsByClass = (className) => {
        const elements = document.getElementsByClassName(className);
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    return <>
        {pageNumber && <div className="d-flex justify-content-center align-items-center">
            <Button type='link' disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)} title={common?.tables?.previousPage} icon={<LeftOutlined />}></Button>
            {common?.tables?.capitalPage} 
            <InputNumber className='mx-2' style={{ width: "3rem" }} type="number" max={pageNumber} min={1} value={currentPage} onBlur={(e) => handlePageChange(Number(e.target.value))} />
            {`${common?.tables?.of} ${pageNumber}`} 
            <Button type='link' disabled={currentPage >= pageNumber} onClick={() => setCurrentPage(currentPage + 1)} title={common?.tables?.nextPage} icon={<RightOutlined />}></Button>
        </div>}
        <div id="filePreviewContainer" style={{ width: "100%", height: "99%", overflowY: "auto" }}>
            {loading ? <div className="spinnerCont">
                <Spin size='large' />
            </div>
                :
                <>
                    <Document
                        file={`data:application/pdf;base64,${pdfString}`}
                        onLoadSuccess={({ numPages }) => setPageNumber(numPages)}
                        onRender={() => removeElementsByClass("react-pdf__Page__annotations annotationLayer")}
                    >
                        <Page pageNumber={currentPage} />
                    </Document>
                </>
            }
        </div>
    </>
}

export default FilePreview;
