import React from "react";
import { Form, Input, FormItemProps } from "antd";

export interface AntdFormTextAreaFieldFieldProps extends FormItemProps {
  name: string;
  disabled?: boolean;
  placeholder?: string;
  label: string;
  rules?: any;
  rows?: number;
  onChange?: any;
  onInput?: any;
  noWrapperStyle?: boolean;
}

const colProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const AntdFormTextAreaField: React.FC<AntdFormTextAreaFieldFieldProps> = ({
  name,
  label,
  rules,
  disabled,
  placeholder,
  required,
  rows,
  noWrapperStyle,
  ...otherProps
}) => {
  return (
    <div
      className={!noWrapperStyle ? "col-12 col-sm-12 col-md-6 col-lg-6" : ""}
    >
      <Form.Item
        name={name}
        label={label}
        rules={rules}
        {...otherProps}
        {...colProps}
      >
        <Input.TextArea
          disabled={disabled}
          {...otherProps}
          rows={rows}
          placeholder={placeholder}
        />
      </Form.Item>
    </div>
  );
};

export default AntdFormTextAreaField;
