import React from "react";
/* AntD */
import { Button, Spin } from "antd";
/* i18next */
import { useTranslation } from "react-i18next";
/* Assets */
import fst_logo from "../../assets/images/fs_technology.png";
import alleti from "../../assets/images/logo_alleti.png";
/* Utility */
import { getValueFromSession } from "../../services/storage-wrapper";

interface PageHeadingProps {
  disableInsert: boolean;
  isAdminOnly?: boolean;
  isEditing?: boolean;
  pageTitle?: string;
  leaveEdit?: any;
  saveChanges?: any;
  setIsEditing?: any;
  isSaving?: boolean;
}

const PageHeading: React.FC<PageHeadingProps> = ({
  disableInsert,
  pageTitle,
  leaveEdit,
  saveChanges,
  setIsEditing,
  isSaving,
}) => {
  const { t } = useTranslation();

  const Buttons: any = t("Buttons", { returnObjects: true });
  const userRole = getValueFromSession<string>("role") || "";
  const condition = !disableInsert;

  return (
    <section className="row d-flex justify-content-between p-3 mb-4 section">
      <div className="d-flex align-items-end pageLogo">
        <img src={fst_logo} alt="Logo FS Technology" />
        <h1 className="d-inline ml-4">{pageTitle}</h1>
      </div>
      <div className="headingBtns">

        {leaveEdit &&
          <Button
            className="mr-2"
            onClick={() => leaveEdit()}
            disabled={isSaving}
          >
            {Buttons.back}
          </Button>}
        {condition && saveChanges && (
          <Button
            type="primary"
            className="ml-2"
            onClick={() => saveChanges()}
            disabled={isSaving}
          >
            {isSaving ? <Spin /> : `${Buttons.save}`}
          </Button>
        )}

        {condition && setIsEditing && (
          <Button onClick={() => setIsEditing()}>{Buttons.new}</Button>
        )}
        <img src={alleti} alt="Logo ALT" className='img-fluid' style={{ height: "50px" }} />
      </div>
    </section>
  );
};

export default PageHeading;
