import React, { useState } from "react";
/* Components */
import { AntdSearch } from "../../"
import { useSafeEffect } from "../../../helpers/hooks";
import { AntdFormSelectField } from "../../common/dataEntry";
/* Services and Interfaces */
import { ConsultSearchInterface } from "./"
import { MasterDataResponse, getDropdownByKey } from "../../../services/masterData"
/* Translations */
import { commonTranslations } from "../../../helpers";
/* Utility */
import { mapToSelectData } from "../../../helpers";
import { notify, operationError } from "../../../services/notification-wrapper";
/* Provider */
import { useConsult } from "../../../views/consult/consultProvider";

const SearchODL: React.FC<ConsultSearchInterface> = ({
    onSearch,
    isSearching
}) => {

    const [loading, setLoading] = useState(false);
    const { labels, placeholders } = commonTranslations

    const { odl, suppliers, partners, searchParams, selectedCig, setMasterdataCache } = useConsult();

    useSafeEffect(
        [selectedCig],
        () => {
            if (!selectedCig && odl.length && suppliers.length && partners.length) return Promise.resolve(null);
            setLoading(true);
            const odlPromise = getDropdownByKey("odl", selectedCig);
            const supplierPromise = getDropdownByKey("supplier", selectedCig);
            const partnerPromise = getDropdownByKey("partner", selectedCig);
            return Promise.all([odlPromise, supplierPromise, partnerPromise]);
        },
        (data: [MasterDataResponse, MasterDataResponse, MasterDataResponse]) => {
            if (data && data.length > 1) {
                const [_odl, _suppliers, _partners] = data;
                setMasterdataCache({
                    odl: mapToSelectData(_odl?.values),
                    suppliers: mapToSelectData(_suppliers?.values),
                    partners: mapToSelectData(_partners?.values)
                })
            }
        },
        (err: any) => notify(operationError()),
        () => setLoading(false)
    );

    return <AntdSearch name="searchODL" onSearch={(formValues) => onSearch(formValues, "searchODL")} loading={isSearching} initialValues={searchParams} resetTrigger={selectedCig} atLeastOneFieldRequired>
        <AntdFormSelectField
            name={"odl"}
            label={labels.odl}
            placeholder={placeholders.odl}
            options={odl}
            loading={loading}
            disabled={isSearching}
        />
        <AntdFormSelectField
            name={"supplier"}
            label={labels.supplier}
            placeholder={placeholders.supplier}
            options={suppliers}
            loading={loading}
            disabled={isSearching}
        />
        <AntdFormSelectField
            name={"partner"}
            label={labels.partner}
            placeholder={placeholders.partner}
            options={partners}
            loading={loading}
            disabled={isSearching}
        />
    </AntdSearch>
}

export default SearchODL;
