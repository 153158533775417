import React from 'react';
import { Layout } from './Layout';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

export const ErroredLayout = () => {
  const { t } = useTranslation();
  const errorTexts: any = t('Error', { returnObjects: true });

  return (
    <>
      <Layout title={'Oops!'} isEditing={false}>
        <Card title={errorTexts.title}>
          <p>{errorTexts.message}</p>
        </Card>
      </Layout>
    </>
  );
};
