import React from 'react';
/* Components */
import IconByMimeType from '../../../../components/Utility/IconByMimeType';
/* Services and interfaces */
import { FileSystemTree } from '../../../../services/navigate';
/* Translations */
import { consultTranslations as consult } from '../../../../helpers/translations';
/* Utility */
import { formatDate, formatFileSize } from '../../../../helpers';

interface FileDetailsProps {
    file: FileSystemTree
}

const FileDetails: React.FC<FileDetailsProps> = ({ file }) => {

    const { fileType, mimeType, dimensions, creationDate, deletionDate, path } = consult.fileDetails;
    const isDirectory = file?.mimeType === "inode/directory"

    return <div className='px-3 pb-3 pt-1'>
        <section className='pb-2 border-bottom'>
            <IconByMimeType mimeType={file?.mimeType} size={30} />
            <span className='ml-3'>{file?.fsitem}</span>
        </section>
        <section className='d-flex flex-column border-bottom py-3'>
            <div className='d-flex'>
                <span>{path}: </span>
                <span className='ml-2'> {file.path}</span>
            </div>
        </section>
        <section className='d-flex flex-column border-bottom py-3'>
            {!isDirectory && <div className='d-flex'>
                <span>{fileType}: </span>
                <span className='ml-2'> {file?.documentType?.label}</span>
            </div>}
            <div className='d-flex'>
                <span>{mimeType}: </span>
                <span className='ml-2'> {file?.mimeType}</span>
            </div>
            {!isDirectory && <div className='d-flex'>
                <span>{dimensions}: </span>
                <span className='ml-2'> {formatFileSize(file?.size)}</span>
            </div>}
        </section>
        {/* TODO: Add, creation date and last modification date? */}
        <section className='d-flex flex-column border-bottom py-3'>
            <div className='d-flex'>
                <span>{creationDate}: </span>
                <span className='ml-2'> {!!file?.creationDate && formatDate(file?.creationDate, "DD/MM/yyyy HH:mm:ss")}</span>
            </div>
            {file.dateDeleted &&
                <div className='d-flex'>
                    <span>{deletionDate}: </span>
                    <span className='ml-2'> {formatDate(file?.dateDeleted, "DD/MM/yyyy HH:mm:ss")}</span>
                </div>
            }
        </section>
    </div>
}

export default FileDetails;
