import React, { useState } from "react";
/* Components */
import { AntdSearch } from "../../";
import { useSafeEffect } from "../../../helpers/hooks";
import { AntdFormSelectField } from "../../common/dataEntry";
/* Services and Interfaces */
import { ConsultSearchInterface } from "./";
import { MasterDataResponse, getDropdownByKey } from "../../../services/masterData";
/* Translations */
import { commonTranslations } from "../../../helpers";
/* Utility */
import { mapToSelectData } from "../../../helpers";
import { notify, operationError } from "../../../services/notification-wrapper";
/* Provider */
import { useConsult } from "../../../views/consult/consultProvider";

const SearchCIG: React.FC<ConsultSearchInterface> = ({
    onSearch,
    isSearching
}) => {

    const [loading, setLoading] = useState(false);
    const { labels, placeholders } = commonTranslations;

    const { cig, searchParams, selectedCig, setMasterdataCache } = useConsult();

    useSafeEffect(
        [selectedCig],
        () => {
            if (!selectedCig && cig.length) return;
            setLoading(true);
            return getDropdownByKey("cig", selectedCig);
        },
        (data: MasterDataResponse) => {
            data && setMasterdataCache({ cig: mapToSelectData(data.values) });
        },
        (err: any) => notify(operationError()),
        () => setLoading(false)
    );

    return <AntdSearch
        name="searchCIG"
        onSearch={(formValues) => onSearch(formValues, "searchCIG")}
        loading={isSearching}
        initialValues={searchParams}
        valuesToSet={{cig: selectedCig}}
        resetTrigger={selectedCig} 
        atLeastOneFieldRequired
    >
        <AntdFormSelectField
            name={"cig"}
            label={labels.cig}
            placeholder={placeholders.cig}
            options={cig}
            cols="col-12"
            loading={loading}
            defaultValue={selectedCig}
            disabled={isSearching}
        />
    </AntdSearch>
}

export default SearchCIG;