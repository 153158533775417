import React, { useEffect, useState } from 'react';
/* AntD */
import { Button, Empty, Radio } from 'antd';
import { CloseOutlined, DeleteOutlined, DownloadOutlined, UnorderedListOutlined, AppstoreOutlined } from "@ant-design/icons";
/* Components */
import { File } from '../consult/components/results/file';
import FileDetails from '../consult/components/results/fileDetails';
import FilePreview from '../consult/components/results/filePreview';
import { CustomColumnType, CustomGridAntd, CustomModal, ContextMenu, IconByMimeType, useAuth } from "../../components";
/* Services and interfaces */
import { FileSystemTree } from '../../services/navigate';
import { deleteFiles } from '../../services/documents';
/* Translations */
import { commonTranslations as common, fileSearchTranslations as fileSearch } from '../../helpers/translations';
/* Utility */
import { formatFileSize, singleDownload, multipleDownload, formatDate } from '../../helpers';
import { notify, operationError, operationSuccess } from '../../services/notification-wrapper';

interface FileSearchResultsProps {
    files: FileSystemTree[];
    loading: boolean;
    refreshList: () => void;
    toggleLocalLoading: (value: boolean) => void;
}

const FileSearchResults: React.FC<FileSearchResultsProps> = ({ files, loading, refreshList, toggleLocalLoading }) => {

    const { user } = useAuth();
    const resultTranslations = fileSearch.results;
    const canDelete = !["ro"].includes(user?.role?.accessLevel)
    /* State */
    const [selectedElement, setSelectedElement] = useState<FileSystemTree>(null);
    const [actionType, setActionType] = useState<"preview" | "detail">(null);

    const [gridType, setGridType] = useState("table");
    const [fileSelection, toggleFileSelection] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [contextMenu, setContextMenu] = useState({
        visible: false,
        style: null,
        content: []
    });

    useEffect(() => {
        document.addEventListener("click", _handleClickOutside);
        return () => {
            document.removeEventListener("click", _handleClickOutside);
        }
    }, [])

    useEffect(() => {
        !fileSelection && setSelectedFiles([])
    }, [fileSelection])

    const _handleContextMenu = (event, record?) => {
        event.preventDefault();

        const style = {
            left: `${event.pageX - 215}px`,
            top: `${event.pageY - 170}px`,
            right: "0px"
        };

        toggleContextMenu({
            ...contextMenu,
            style,
            visible: true,
            content: _buildContextMenuButtons(record)
        });
    };

    const _handleClickOutside = event => {
        const { target } = event

        if (!target.closest(".context-menu-container")) {
            toggleContextMenu();
        }
    }

    const _buildContextMenuButtons = (record: FileSystemTree) => {
        return [
            ...(record.mimeType !== "inode/directory" ? [{
                info: common.buttons.download,
                onClick: () => handleDownload(record.s3filename, record.fsitem)
            }] : []),
            {
                info: common.buttons.details,
                onClick() {
                    selectElement(record, "detail");
                    toggleContextMenu();
                }
            },
            ...(canDelete && !record.dateDeleted ? [{
                info: common.buttons.delete,
                style: {
                    color: "red"
                },
                onClick() {
                    handleDelete([record.s3filename]);
                    toggleContextMenu();
                }
            }] : [])
        ]
    }

    const handleDelete = async (s3fileNameList: string[]) => {
        try {
            toggleLocalLoading(true);
            const idList = files.filter(file => s3fileNameList.includes(file.s3filename)).map(file => file.id);
            await deleteFiles(idList);
            notify(operationSuccess());
            setSelectedFiles([]);
            toggleFileSelection(false);
            refreshList();
        } catch (e) {
            console.error(e);
            notify(operationError());
            toggleLocalLoading(false);
        }
    }

    const handleDownload = async (s3filename: string, filename?: string) => {
        try {
            await singleDownload(s3filename, filename);
        } catch (error) {
            notify(operationError())
        } finally {
            toggleContextMenu();
        }
    }

    const handleMultipleDownload = async () => {
        try {
            toggleLocalLoading(true);
            await multipleDownload(files?.filter(file => selectedFiles.includes(file.s3filename)));
            setSelectedFiles([]);
            toggleFileSelection(false);
            notify(operationSuccess());
        } catch (error) {
            notify(operationError());
        } finally {
            toggleLocalLoading(false);
        }
    }

    const toggleContextMenu = (data = { visible: false, style: { top: "0px", left: "0px" }, content: [] }) => {
        setContextMenu(data);
    }

    const selectElement = (element: FileSystemTree = null, action: "preview" | "detail" = null) => {
        if (action === "preview" && element.mimeType !== "application/pdf") {
            handleDownload(element.s3filename, element.fsitem);
            return;
        }

        setSelectedElement(element);
        setActionType(action);
    }

    const handleMultipleSelection = (fsitem: FileSystemTree) => {
        if (!fileSelection) {
            !loading && selectElement(fsitem, "preview");
            return;
        }
        const prevCheckStatus = selectedFiles.includes(fsitem.s3filename);

        prevCheckStatus ?
            setSelectedFiles(selectedFiles.filter(s3filename => s3filename !== fsitem.s3filename))
            :
            setSelectedFiles([...selectedFiles, fsitem.s3filename])
    }

    const columns: CustomColumnType[] = [
        {
            title: resultTranslations.tableHeadings.filename,
            dataIndex: "fsitem",
            key: "fsitem",
            render: (_, record) => <div className='d-flex'>
                <IconByMimeType mimeType={record?.mimeType} size={"1.5em"} />
                <span className='ml-2'>{record?.fsitem}</span>
            </div>
        },
        {
            title: resultTranslations.tableHeadings.documentType,
            dataIndex: "documentType",
            key: "documentType",
            render: (_, record) => record.mimeType === "inode/directory" ? common.labels.directory : record.documentType.label
        },
        {
            title: resultTranslations.tableHeadings.size,
            dataIndex: "size",
            key: "size",
            render: (_, record) => <span>{record.mimeType !== "inode/directory" && formatFileSize(record.size)}</span>
        },
        {
            title: resultTranslations.tableHeadings.creationDate,
            dataIndex: "creationDate",
            key: "creationDate",
            render: (_, record) => formatDate(record.creationDate, "DD/MM/yyyy HH:mm:ss")
        }]

    const dataSource = files.map((item, key) => ({ ...item, key: item.s3filename || item.id }))

    return <>
        {!!files.length &&
            <div className="row w-100 d-flex justify-content-end py-3 px-2">
                {fileSelection ? <>
                    <Button className='mr-1' onClick={() => toggleFileSelection(false)} icon={<CloseOutlined />}>{selectedFiles.length} {common?.buttons?.selectedFiles}</Button>
                    {canDelete && <Button className='ml-1 mr-1' danger onClick={() => selectedFiles.length && handleDelete(selectedFiles)} icon={<DeleteOutlined />} disabled={!selectedFiles.length}>{common?.buttons?.deleteFiles}</Button>}
                    <Button className='ml-1' type='primary' onClick={() => selectedFiles.length && handleMultipleDownload()} icon={<DownloadOutlined />} disabled={!selectedFiles.length}>{common?.buttons?.downloadFiles}</Button>

                </>
                    :
                    <Button className='mr-2' onClick={() => toggleFileSelection(true)}>{common?.buttons?.selectFiles}</Button>
                }
                <Radio.Group
                    name='gridType'
                    className='d-flex ml-3'
                    options={[{ label: <UnorderedListOutlined title={common.filters.tableView} />, value: "table" }, { label: <AppstoreOutlined title={common.filters.gridView} />, value: "grid" }]}
                    value={gridType}
                    onChange={(e) => setGridType(e.target.value)}
                    optionType="button"
                    buttonStyle="solid"
                />
            </div>
        }

        {files?.length ?
            <div className='row py-3 px-4'>
                {gridType === "table" &&
                    <CustomGridAntd
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        rowClassName={(record) => `selectableTableRow context-menu-toggle${record.dateDeleted ? " deletedFile" : ""}`}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick() {
                                    fileSelection && record.mimeType !== "inode/directory" && handleMultipleSelection(record);
                                },
                                onContextMenu: event => _handleContextMenu(event, record)
                            };
                        }}
                        rowSelection={fileSelection && {
                            onChange(selectedRowKeys: string[]) {
                                setSelectedFiles(selectedRowKeys)
                            },
                            getCheckboxProps(record) { return { ...record, disabled: record.mimeType === "inode/directory" } },
                            selectedRowKeys: selectedFiles
                        }}
                    />
                }
                {gridType === "grid" && <>
                    {!!files?.length &&
                        <div className="row col-12">
                            {files?.map((fsitem, index) =>
                                <File
                                    key={index}
                                    checked={selectedFiles.includes(fsitem.s3filename)}
                                    documentType={fsitem.documentType}
                                    enableFileSelection={fileSelection}
                                    fsitem={fsitem.fsitem}
                                    isFolder={fsitem.mimeType === "inode/directory"}
                                    mimeType={fsitem.mimeType}
                                    onClick={() => handleMultipleSelection(fsitem)}
                                    onContextMenu={(event) => { _handleContextMenu(event, fsitem) }}
                                    isDeleted={!!fsitem.dateDeleted}
                                />
                            )}
                        </div>
                    }
                </>
                }
            </div>
            :
            <Empty className='py-5 d-flex flex-column align-items-center' description={resultTranslations.noDataFound} />
        }
        {contextMenu.visible && <ContextMenu style={contextMenu.style} content={contextMenu.content} />}
        {actionType &&
            <CustomModal
                wrapClassName={`${actionType}Modal`}
                visible={!!selectedElement}
                draggable={actionType === "detail"}
                mask={actionType !== "detail"}
                maskClosable={actionType !== "detail"}
                destroyOnClose={true}
                onCancel={() => selectElement()}
                title={selectedElement.fsitem}
                modalBody={actionType === "detail" ? <FileDetails file={selectedElement} /> : <FilePreview s3fileName={selectedElement.s3filename} onError={selectElement} />}
                footer={null}
                bodyStyle={actionType === "detail" ? { minHeight: "50vh" } : null}
            />
        }
    </>
}

export default FileSearchResults;
