import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
/* Components */
import { Layout } from "../../components";
/* Translations */
import { consultTranslations } from '../../helpers/translations';
/* Utility */
import { removeItemsFromSessionStorage } from '../../services/storage-wrapper';
/* Providers */
import { ConsultProvider } from './consultProvider';

const Consult = () => {

  const consult: any = consultTranslations;

  useEffect(() => {    
    return () => {
      removeItemsFromSessionStorage("path", "searchParams");
    }
  }, [])

  return (
    <Layout title={consult.title} disableInsert={true}>
      <ConsultProvider>
        <div className="row d-flex justify-content-center mx-3">
          <Outlet />
        </div>
      </ConsultProvider>
    </Layout>
  )
}

export default Consult;
