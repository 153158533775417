import {
  Axios_Get,
  Axios_Post,
  Axios_Put
} from "../../axios";
import {
  DocumentTypeParams,
  DocumentTypeResponse
} from ".";
import {
  DocumentTypeCreateInterface,
  DocumentTypeUpdateInterface,
  GetDocumentTypeByIdResponseInterface
} from "./documentTypes.interface";

export const API_DOCUMENT_TYPES = "/v1/documentTypes";
export const API_DOCTYPE_URL = "/v1/documentType";
export const API_DOCTYPE_CHANGE_URL = "/v1/configuration/documentType";
export const API_TAGS = "/v1/tags";
export const LS_API = "/v1/ls";

export const getDocumentTypes = async (
  filter?: DocumentTypeParams
): Promise<DocumentTypeResponse> => {
  return Axios_Post<DocumentTypeResponse>(API_DOCUMENT_TYPES, filter);
};

export const getDocumentTypesById = async (
  id: number
): Promise<DocumentTypeResponse> => {
  return Axios_Post<GetDocumentTypeByIdResponseInterface>(`${API_DOCTYPE_URL}`, {id});
};

export const createDocumentType = async (
  documentType: DocumentType
): Promise<DocumentTypeCreateInterface> => {
  return Axios_Post<DocumentTypeCreateInterface>(API_DOCTYPE_CHANGE_URL, documentType);
};

export const updateDocumentType = async (
  documentType: DocumentTypeUpdateInterface
): Promise<DocumentType> => {
  return Axios_Put<DocumentType>(`${API_DOCTYPE_CHANGE_URL}/${documentType.id}`, documentType);
};

export interface TagResponse {
  statusCode: string;
  values: string[];
}

export const getTags = async (tag: string): Promise<TagResponse> => {
  return Axios_Post(API_TAGS, { tag });
}

export interface LSResponse {
  statusCode: string;
  ls: string[];
}

export const getFolderSuggestions = (folderName: string): Promise<LSResponse> => {
  return Axios_Post(LS_API, { cd: folderName });
}
