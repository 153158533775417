import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import config from "../config.json";
import { refreshToken } from "../services/auth";
import {
  clearLocalStorage,
  getValueFromLocalStorage,
  getValueFromSession,
  saveInLocalStorage,
} from "../services/storage-wrapper";

axiosRetry(axios, {
  retries: config.ConfigurableParams.retries,
  retryDelay: (retryCount) => retryCount * config.ConfigurableParams.retryDelay,
  retryCondition(error: AxiosError) {
    if (error.config.method !== "post") {
      return (
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        error.response?.status === 408
      );
    }
    return false;
  },
});

axiosRetry(axios, {
  retries: 2,
  retryDelay: (retryCount) => retryCount * 10,
  async retryCondition(error: AxiosError) {
    if (error.response.status === 401) {
      try {
        const rtoken = getValueFromSession<string>("refreshToken");
        if (!rtoken) { return false }
        const response = await refreshToken()
        if (response instanceof Error) {
          clearLocalStorage();
          return true;
        }
        const data = {
          ...JSON.parse(getValueFromLocalStorage("session")),
          ...response,
        };
        saveInLocalStorage("session", JSON.stringify(data));

        delete error.config.headers["Authorization"]
        return (axiosRetry.isNetworkOrIdempotentRequestError(error));
      } catch (err) {
        return true;
      }
    }
  },
});

const onRequestSuccess = (axiosConfig: AxiosRequestConfig<any>) => {
  const token = getValueFromSession<string>("token");
  const headers: { [key: string]: string } = {};
  token ? (headers["Authorization"] = token) : null;
  axiosConfig.headers = { ...axiosConfig.headers, ...headers };
  return axiosConfig;
};

const onResponseSuccess = (response: AxiosResponse<any, any>) => response;

const onResponseError = (err: AxiosError) => {
  return Promise.reject(err);
};

axios.interceptors.request.use(onRequestSuccess);

axios.interceptors.response.use(onResponseSuccess, onResponseError);

const environment: string = process.env.REACT_APP_ENVIRONMENT || "none";
const appConfig: any = config;
const uri = appConfig.Environment[environment].apiContextPath;

export const Axios_Get = async <T>(
  url: string,
  data?: { [key: string]: any } | null
): Promise<T> => {
  if (data) {
    url += "?";
    Object.keys(data).forEach((key) => {
      if (data[key]) url += `${key}=${data[key]}&`;
    });
    url = url.slice(0, -1);
  }

  const response = await axios.get(uri + url);
  if (response.data && !response.data.errorMessage) {
    return response.data as T;
  }

  throw new Error(response.data && response.data.errorMessage);
};

export const Axios_Post = async <T>(
  url: string,
  formData: { [key: string]: any } | string,
  isAuth?: boolean
): Promise<T> => {
  const response = await axios.post(
    isAuth ? url : uri + url,
    isAuth ? formData : getFormData(formData),
    isAuth ? skipAuthHeader() : {}
  );
  if (response.data && !response.data.errorMessage) {
    return response.data as T;
  }

  throw new Error(response.data && response.data.errorMessage);
};

export const Axios_Put = async <T>(
  url: string,
  formData: { [key: string]: any }
): Promise<T> => {
  const response = await axios.put(uri + url, getFormData(formData));
  if (response.data && !response.data.errorMessage) {
    return response.data;
  }

  throw new Error(response.data && response.data.errorMessage);
};

export const Axios_Delete = async (url: string): Promise<void> => {
  return await axios.delete(uri + url);
};

export const Axios_Post_FileUploader = async (
  url_: string,
  formData: { [key: string]: any }
) => {
  try {
    return await axios({
      method: "post",
      url: url_,
      headers: {
        "cache-control": "no-cache",
        "content-type": "multipart/form-data",
      },
      data: formData,
    });
  } catch (e: any) {
    throw new Error(e);
  }
};

export const Axios_Patch = async (url: string, dati: any) => {
  try {
    return await axios.post(url, dati);
  } catch (e: any) {
    throw new Error(e);
  }
};

function skipAuthHeader() {
  return {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
}

function getFormData(formData: any) {
  return {
    ...formData
  };
}
