import { Axios_Get, Axios_Post, Axios_Put } from "../../axios";
import {
  DocumentS3Keys,
  MultiFileDownloadResponse
} from ".";
import { FileSystemTree } from "../navigate";

/* Staging key route */
const API_DOCUMENT_KEYS = "/v1/attachmentskeys";
/* Document keys route */
const API_STORAGE_DOCUMENT_KEYS_RO = "/v1/ro-storagekeys"
const API_STORAGE_DOCUMENT_KEYS_RW = "/v1/rw-storagekeys"
/* Files route */
const API_FILES = "/v1/files"
const API_MULTI_FILE_DOWNLOAD = "/v1/multiDownload"
const API_DELETE_FILES = "/v1/filesDelete"

/* S3 keys */
export const getDocumentS3Keys = async (): Promise<DocumentS3Keys> => {
  return Axios_Get<DocumentS3Keys>(API_DOCUMENT_KEYS);
};

export const getStorageDocumentS3ROKeys = (): Promise<DocumentS3Keys> => {
  return Axios_Get<DocumentS3Keys>(API_STORAGE_DOCUMENT_KEYS_RO)
}

export const getStorageDocumentS3RWKeys = (): Promise<DocumentS3Keys> => {
  return Axios_Get<DocumentS3Keys>(API_STORAGE_DOCUMENT_KEYS_RW)
}

/* Files */
export const multiFileDownload = (filenames: string[]): Promise<MultiFileDownloadResponse> => {
  return Axios_Post<MultiFileDownloadResponse>(API_MULTI_FILE_DOWNLOAD, {filenames})
}

export const updateFile = (file: FileSystemTree): Promise<FileSystemTree> => {
  return Axios_Put<FileSystemTree>(`${API_FILES}/${file.id}`, file)
}

export const deleteFiles = (idList: number[] | string[]): Promise<any> => {
  return Axios_Post<any>(API_DELETE_FILES, {idList})
}