import React, { Suspense } from "react";
import { Route, Routes, Navigate, BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
/* i18n */
import i18n from "./i18next";
import { I18nextProvider, useTranslation } from "react-i18next";
/* Views */
import {
  Home,
  DocumentUpload,
  Consult,
  ConsultSearch,
  ConsultResults,
  FileSearch,
  Users,
  UserEdit,
  Groups,
  GroupEdit,
  DocumentTypeList,
  DocumentTypeEdit,
  Console
} from "./views";
/* Components */
import {
  AuthProvider,
  AuthRoute,
  Header
} from "./components";
/* Assets */
import "./assets/css/custom-theme.css"
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./assets/css/font-awesome.min.css";
import "./assets/css/custom.css";

import moment from "moment";
import 'moment/locale/it'  // without this line it didn't work
moment.locale('it')

function Root() {
  const { t } = useTranslation();

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <AuthProvider>
          <Suspense fallback="">
            <Header />
            <main id="main" className="container-fluid pb-4">
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/consult/documents" element={<Consult />}>
                  <Route path="/consult/documents/search" element={<AuthRoute path="/consult/documents/search"><ConsultSearch /></AuthRoute>} />
                  <Route path="/consult/documents/results" element={<AuthRoute path="/consult/documents/results"><ConsultResults /></AuthRoute>} />
                  <Route path="/consult/documents" element={<AuthRoute path="/consult/documents"><Navigate replace to="/consult/documents/search" /></AuthRoute>} />
                  <Route path="/consult/documents/*" element={<AuthRoute path="/consult/documents/*"><Navigate replace to="/consult/documents/search" /></AuthRoute>} />
                </Route>
                <Route path="/consult/fileSearch" element={<FileSearch />}></Route>
                <Route path="/upload" element={<AuthRoute path="/upload"><DocumentUpload /></AuthRoute>} />
                <Route path="/upload/console" element={<AuthRoute path="/upload/console"><Console /></AuthRoute>} />

                {/* Configuration pages */}
                <Route path="/configuration/users" element={<Users />} />
                <Route path="/configuration/users/edit" element={<AuthRoute path="/configuration/users/edit"><UserEdit /></AuthRoute>} />
                <Route path="/configuration/users/edit/:id" element={<AuthRoute path="/configuration/users/edit/:id"><UserEdit /></AuthRoute>} />

                <Route path="/configuration/documentTypes" element={<DocumentTypeList />} />
                <Route path="/configuration/documentTypes/edit" element={<AuthRoute path="/configuration/documentTypes/edit"><DocumentTypeEdit /></AuthRoute>} />
                <Route path="/configuration/documentTypes/edit/:id" element={<AuthRoute path="/configuration/documentTypes/edit/:id"><DocumentTypeEdit /></AuthRoute>} />

                <Route path="/configuration/groups" element={<Groups />} />
                <Route path="/configuration/groups/edit" element={<AuthRoute path="/configuration/groups/edit"><GroupEdit /></AuthRoute>} />
                <Route path="/configuration/groups/edit/:id" element={<AuthRoute path="/configuration/groups/edit/:id"><GroupEdit /></AuthRoute>} />
                <Route path="*" element={<Navigate replace to="/home" />} />
              </Routes>
            </main>
          </Suspense>
        </AuthProvider>
      </Router>
    </I18nextProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));
