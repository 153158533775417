
import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
/* Components */
import { OptionProps } from "../../components";
/* Utils */
import { getValueFromSessionStorage, removeItemsFromSessionStorage, saveInSessionStorage } from "../../services/storage-wrapper";

interface ConsultContextType {
    cig: OptionProps[];
    years: OptionProps[];
    businessUnits: OptionProps[];
    societaInfragruppo: OptionProps[];
    odl: OptionProps[];
    suppliers: OptionProps[];
    partners: OptionProps[];
    path: string;
    prevPath: string;
    searchParams: { [key: string]: string } | null;
    selectedCig: string;
    enableFileSelection: boolean;
    selectedFiles: string[];
    setMasterdataCache: (updates: { [key: string]: OptionProps[] | null }) => void;
    setSearchParams: (params: { [key: string]: string }) => void;
    setPath: (path: string) => void;
    setSelectedCig: (value: string) => void;
    setEnableFileSelection: (value: boolean) => void;
    setSelectedFiles: (idList: string[]) => void;
}

const ConsultContext = createContext({} as ConsultContextType);

export const ConsultProvider = (props: {
    [key: string]: any;
    children: JSX.Element;
}) => {

    const navigate = useNavigate()
    const location = useLocation()

    const { children } = props;

    const [path, setPathValue] = useState(null);
    const [prevPath, setPrevPath] = useState(null);
    const [searchParams, setSearchParams] = useState(null);
    const [masterData, setMasterData] = useState({
        cig: [],
        years: [],
        businessUnits: [],
        societaInfragruppo: [],
        odl: [],
        suppliers: [],
        partners: []
    });
    const [selectedCig, setSelectedCig] = useState(null);
    const [mounted, setMounted] = useState(false);
    const [enableFileSelection, setEnableFileSelection] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

    useEffect(() => {
        const sessionParams = getValueFromSessionStorage("searchParams");
        sessionParams && setSearchParams(JSON.parse(sessionParams));

        const sessionPath = getValueFromSessionStorage("path");
        sessionPath ? setPath(sessionPath) : navigate("/consult/documents/search");

        const selectedCigSession = getValueFromSessionStorage("selectedCig");
        selectedCigSession && setSelectedCig(selectedCigSession);
        setMounted(true);
    }, []);

    useEffect(() => {
        if (location?.state?.resetData) {
            removeItemsFromSessionStorage("searchParams", "path", "selectedCig")
            setSearchParams(null);
            setPath(null);
            setSelectedCig(null);
            setSelectedFiles([]);
        }
    }, [location?.state?.resetData]);

    useEffect(() => {
        searchParams && saveInSessionStorage("searchParams", JSON.stringify(searchParams));
    }, [searchParams])

    useEffect(() => {
        selectedCig ? saveInSessionStorage("selectedCig", selectedCig) : removeItemsFromSessionStorage("selectedCig");
    }, [selectedCig])

    useEffect(() => {
        if(path) {
            saveInSessionStorage("path", path);
            setSelectedFiles([]);
            setEnableFileSelection(false);
        }
    }, [path])

    const setMasterdataCache = (updates: { [key: string]: OptionProps[] | null }) => {
        setMasterData({ ...masterData, ...updates });
    }

    const setPath = (newPath: string) => {
        /* Prevent loop in case of error on the same path */
        if (newPath === path === prevPath) return;
        setPrevPath(path);
        setPathValue(newPath)
    }

    const memoedValues = useMemo<ConsultContextType>(
        () => ({
            setMasterdataCache,
            cig: masterData.cig,
            years: masterData.years,
            businessUnits: masterData.businessUnits,
            societaInfragruppo: masterData.societaInfragruppo,
            odl: masterData.odl,
            suppliers: masterData.suppliers,
            partners: masterData.partners,
            path,
            setPath,
            prevPath,
            searchParams,
            setSearchParams,
            setSelectedCig,
            selectedCig,
            selectedFiles,
            setSelectedFiles,
            enableFileSelection,
            setEnableFileSelection
        }), [masterData, path, searchParams, selectedCig, enableFileSelection, selectedFiles]
    );

    return (
        <ConsultContext.Provider value={memoedValues}>{mounted && children}</ConsultContext.Provider>
    );
};

export function useConsult() {
    return useContext(ConsultContext);
}
