import React, { useEffect, useState } from 'react';
/* AntD */
import { Collapse, Pagination, Spin } from 'antd';
/* Components */
import { Layout } from "../../components";
import FileSearchResearch from './fileSearchResearch';
import FileSearchResults from './fileSearchResults';
/* Services and interfaces */
import { advancedFileSearch } from '../../services/fileSearch';
/* Translations */
import { fileSearchTranslations } from '../../helpers/translations';
/* Utility */
import { notify, operationError } from '../../services/notification-wrapper';
import { formatDate } from '../../helpers';

const FileSearch = () => {

  const fileSearch: any = fileSearchTranslations;
  /* State */
  const [params, setParams] = useState(null);
  const [page, setPage] = useState(1);
  const [isSearching, setIsSearching] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [openSearch, setOpenSearch] = useState(true);

  useEffect(() => {
    searchFiles();
  }, [params])


  const searchFiles = async () => {
    try {
      if (!params || (params && !Object.values(params).find((param: any) => ![undefined, ""].includes(param)))) {
        setFiles([]);
        setTotalRows(0);
        setPage(1);
        return;
      }
      !files.length ? setIsSearching(true) : setLocalLoading(true);
      const filesResponse = await advancedFileSearch(params);
      setFiles(filesResponse.fileSystemTree);
      setTotalRows(filesResponse.totalrows);
      filesResponse.fileSystemTree.length && setOpenSearch(false);
    } catch (error) {
      notify(operationError());
    } finally {
      setIsSearching(false);
      setLocalLoading(false);
    }
  }

  return (
    <Layout title={fileSearch.title} disableInsert={true}>
      <div className="row d-flex justify-content-center mx-3">
        <section className="col-sm-12 col-md-11 col-lg-11 p-4 section vh-80">
          <Collapse
            activeKey={openSearch ? ["1"] : null}
            onChange={() => setOpenSearch(!openSearch)}
            expandIconPosition="right"
          >
            <Collapse.Panel key="1" header={fileSearch.search.title} >
              <FileSearchResearch
                handleSearch={(values) => {
                  setPage(1);
                  if(values?.creationDate) {
                    values["creationStartDate"] = formatDate(values.creationDate[0], "yyyy-MM-DD");
                    values["creationEndDate"] = formatDate(values.creationDate[1], "yyyy-MM-DD");
                    delete values.creationDate;
                  }
                  setParams(values);
                }}
              />
            </Collapse.Panel>
          </Collapse>


          {files && !isSearching ?
            <>
              {localLoading && <div className="localSpinner">
                <Spin size='large' />
              </div>}
              <FileSearchResults
                files={files}
                loading={localLoading}
                toggleLocalLoading={() => setLocalLoading((prev) => !prev)}
                refreshList={searchFiles}
              />
              <Pagination className='text-right' total={totalRows} pageSize={10} showSizeChanger={false} hideOnSinglePage={true} current={page} onChange={(newPage, pageSize) => { setParams({ ...params, offset: (newPage - 1) * pageSize }); setPage(newPage) }} />
            </>
            :
            isSearching && <div className="spinnerCont">
              <Spin size='large' />
            </div>
          }
        </section>
      </div>
    </Layout>
  )
}

export default FileSearch;
