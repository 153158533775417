export const saveInSessionStorage = (key: string, payload: string) => {
  sessionStorage.setItem(key, payload);
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const removeItemsFromSessionStorage = (...keys: string[]) => {
  for (const key of keys)
    sessionStorage.removeItem(key);
};

export function getValueFromSessionStorage(key: string): any | null {
  if (!existsInSessionStorage(key)) {
    return null;
  }
  return sessionStorage.getItem(key);
}

export const existsInSessionStorage = (key: string): boolean => {
  const item: string | null = sessionStorage.getItem(key);
  return item !== null;
};
