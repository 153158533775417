import {Axios_Post} from "../../axios";
/* Interfaces */
import {RoleResponseInterface} from ".";
import { ParamsInterface } from "../core/Common.interface";

const API_ROLES_URL = '/v1/roles';

export const postRoles = async (params?: ParamsInterface): Promise<RoleResponseInterface> => {
  return Axios_Post<RoleResponseInterface>(API_ROLES_URL, params);
}
