import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getValueFromSessionStorage, saveInSessionStorage } from "../services/storage-wrapper";

export const useSafeEffect = <T>(
  depsList: any[],
  asyncFn: () => Promise<T>,
  onSuccess: (data: T) => void,
  onError?: (e: Error) => void,
  onFinal?: () => void,
  onClear?: () => void
) => {
  useEffect(() => {
    let isActive = true;
    asyncFn()?.then(data => {
        if (isActive && onSuccess) onSuccess(data);
      })
      .catch(e => {
        if (onError && isActive) onError(e);
      })
      .finally(() => {
        if (onFinal && isActive) onFinal();
      });
    return () => {
      isActive = false;
      if (onClear) {
        onClear();
      }
    };
  }, depsList);
};


export const useStorageState = <T>(
  keyName: string,
  defaultValue: T,
  typeName?: string
): [T, Dispatch<SetStateAction<T>>] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = getValueFromSessionStorage(keyName) || "";
      if (typeName && typeName === Map.name) {
        const arr = JSON.parse(value);
        const res: any = new Map(arr);
        return res as T;
      }
      return JSON.parse(value) as T;
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue: Dispatch<SetStateAction<T>> = (
    newValue: SetStateAction<T>
  ): void => {
    try {
      if (
        typeof newValue === "object" &&
        (newValue instanceof Map || newValue instanceof Set)
      ) {
        saveInSessionStorage(
          keyName,
          JSON.stringify(Array.from(newValue.entries()))
        );
      } else {
        saveInSessionStorage(keyName, JSON.stringify(newValue));
      }
    } catch (err) {
      console.error("error saving in sessin storage", keyName, newValue);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};