import { Axios_Get, Axios_Post, Axios_Put } from "../../axios";
import {
  GetUserByIdResponseInterface,
  UserCreateInterface,
  UserInterface,
  UserParamsInterface,
  UserResponseInterface,
  UserUpdateInterface,
} from ".";
export const API_USERS_URL = "/v1/users";
export const API_USER_URL = "/v1/user";
export const API_USER_URL1 = "/v1/configuration/users";

export const getUsers = async (
  filter?: UserParamsInterface
): Promise<UserResponseInterface> => {
  return Axios_Post<UserResponseInterface>(API_USERS_URL, filter);
};

export const getUserByID = async (
  id: number
): Promise<GetUserByIdResponseInterface> => {
  return Axios_Post<GetUserByIdResponseInterface>(`${API_USER_URL}`, {id});
};

export const createUser = async (
  user: UserCreateInterface
): Promise<UserInterface> => {
  return Axios_Post<UserInterface>(API_USER_URL1, user);
};

export const updateUser = async (
  user: UserUpdateInterface
): Promise<UserInterface> => {
  return Axios_Put<UserInterface>(`${API_USER_URL1}/${user.id}`, user);
};
