import React from 'react';
import { Form, FormItemProps, Upload, Button } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import { commonTranslations } from '../../../helpers';
import { UploadFile } from 'antd/lib/upload/interface';

const colProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export interface AntdFormUploadProps extends FormItemProps {
  inputName: string;
  label?: string;
  uploadProps: DraggerProps;
  showUploadList?: boolean;
  accept?: string;
  type?: any;
  multiple?: boolean;
  cols?: string;
  fileList: UploadFile[];
  onChange?: () => void;
}

const AntdFormDragger: React.FC<AntdFormUploadProps> = ({
  inputName,
  label,
  uploadProps,
  showUploadList,
  cols,
  multiple,
  accept,
  fileList,
  type,
  ...otherProps
}) => {

  const draggerTranslations = commonTranslations.fields.dragger;

  return (
    <div className={`${cols || 'col-12 col-sm-12 col-md-6 col-lg-4'} my-1 mb-4`}>
      <Form.Item name={inputName} label={label} {...colProps} {...otherProps} >
        <Dragger {...uploadProps} showUploadList={showUploadList} accept={accept} fileList={fileList} >
          <p className="ant-upload-drag-icon">
            <FileAddOutlined />
          </p>
          <p className="ant-upload-text">{draggerTranslations.text}</p>
          <p className="ant-upload-hint">{draggerTranslations.hint}</p>
        </Dragger>
      </Form.Item>
    </div>
  );
};

export default AntdFormDragger;
