import React, {
    useState
} from "react";
import {
    Button,
    Form,
    Skeleton
} from "antd";
import { useTranslation } from "react-i18next";
import {
    useNavigate,
    useParams
} from "react-router-dom";
import {
    AntdFormInputField,
    AntdFormSelectField,
    AntdFormTextAreaField,
    Layout
} from "../../components";
import { getRequiredValidation } from "../../helpers";
import { useSafeEffect } from "../../helpers/hooks";
import {
    commonTranslations,
    groupsTranslations
} from "../../helpers/translations";
import {
    postGroups,
    getGroupByID,
    createGroup,
    updateGroup
} from "../../services/groups/Groups.service";
import {
    notify,
    operationError,
    operationSuccess
} from "../../services/notification-wrapper";
import {
    GetGroupByIdResponseInterface,
    Group,
    GroupsResponse
} from "../../services/groups/Groups.interface";

interface GroupInitialValues {
    label: string;
}

const GroupEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const [form] = Form.useForm();
    const isEdit = id !== undefined;

    const { t } = useTranslation();
    const groupsTrans = groupsTranslations;
    const [userGroupsOptions, setUserGroupsOptions] = useState<Group[]>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [group, setGroup] = useState<Group>();
    const [initialValues, setInitialValues] = useState<
        Partial<GroupInitialValues>
    >({});

    const fetchGroupInfo = async () => {
        try {
            const groupResp: GetGroupByIdResponseInterface = await getGroupByID(Number(id));
            return groupResp.group;
        } catch (e) {
            console.log("Error fetching data? ", e);
            notify(operationError());
        }
    };

    const fetchGroupOptions = async () => {
        try {
            const optionGroups: GroupsResponse = await postGroups({limit: 1000});
            return optionGroups.groups;
        } catch (e) {
            console.log("error fetching groups", e);
        }
        return [];
    };

    useSafeEffect(
        [],
        () => {
            return fetchGroupOptions();
        },
        (data: any) => {
            if (data) {
                setUserGroupsOptions(data);
            }
        }
    );

    useSafeEffect(
        [],
        () => {
            setLoading(true);
            return !isEdit ? Promise.resolve(null) : fetchGroupInfo();
        },
        (data?: Group) => {
            if (data) {
                if (typeof data.acljson === 'object') {
                    data.acljson = JSON.stringify(data.acljson, null, 2);
                }
                form.setFieldsValue(data)
                setGroup(data);
            }
        },
        (e: any) => notify(operationError(e.response.status)),
        () => {
            setLoading(false)
        }
    );

    const goToView = () => {
        navigate("/configuration/groups");
    };

    const onSave = async (value) => {
        try {
            setIsSaving(true);
            value = { ...value, acljson: JSON.parse(value.acljson) }
            if (isEdit) {
                await updateGroup({ id: group.id, ...value });
            } else {
                await createGroup({ ...value });
            }
            notify(operationSuccess());
            goToView();
        } catch (e) {
            notify(operationError(e?.response?.status));
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Layout
            title=""
            leaveEdit={goToView}
            isEditing={true}
            isSaving={isSaving}
            disableInsert={true}
        >
            <section className="p-4 mt-3 mx-1 section">
                <Skeleton loading={loading} active>
                    <Form
                        className="column"
                        layout="horizontal"
                        name="ProcessEditForm"
                        form={form}
                        initialValues={initialValues}
                        onFinish={onSave}
                    >
                        <div>
                            <h1 className="mt-1">{isEdit && group ? group.groupname : groupsTrans.new}</h1>

                            <div>
                                <h4 className="mt-1 mt-3">{groupsTrans.sections.appData}</h4>

                                <div className="row px-2">
                                    <AntdFormInputField
                                        name="groupname"
                                        placeholder={groupsTrans.search.placeholders.groupname}
                                        label={groupsTrans.search.labels.groupname}
                                        rules={getRequiredValidation("groups", "groupname")}
                                    />

                                    <AntdFormSelectField
                                        name="groups_id"
                                        placeholder={groupsTrans.search.placeholders.parentGroup}
                                        label={groupsTrans.search.labels.parentGroup}
                                        options={userGroupsOptions?.filter(
                                            groupItem => groupItem.id !== group?.id
                                        )
                                            .map((uG) => ({
                                                key: uG.id,
                                                value: Number(uG.id),
                                                name: uG.groupname
                                            }))}
                                    />
                                </div>

                                <div>
                                    <h4 className="mt-1 mt-3">{groupsTrans.sections.permissions}</h4>

                                    <div className="row px-3 justify-content-start">
                                        <AntdFormTextAreaField
                                            rows={10}
                                            name="acljson"
                                            placeholder={groupsTrans.search.placeholders.acljson}
                                            label={groupsTrans.search.labels.acljson}
                                            validateTrigger="onBlur"
                                            rules={[
                                                ...getRequiredValidation("groups", "acljson"),
                                                {
                                                    required: true,
                                                    message: commonTranslations.formValidations.acljsonRule,
                                                    validator(validationData, value) {
                                                        try {
                                                            JSON.parse(value);
                                                            return Promise.resolve();
                                                        } catch (error) {
                                                            return Promise.reject(commonTranslations.formValidations.acljsonRule);
                                                        }
                                                    },
                                                }
                                            ]}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="row px-2 d-flex justify-content-end">
                                        <Button
                                            className="m-1"
                                            onClick={() => goToView()}
                                            disabled={isSaving}
                                        >
                                            {commonTranslations?.buttons?.back}
                                        </Button>

                                        <Button
                                            className="m-1"
                                            type="primary"
                                            htmlType="submit"
                                            loading={isSaving}
                                        >
                                            {commonTranslations.buttons.save}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Skeleton>
            </section>
        </Layout>
    );
};

export default GroupEdit;
