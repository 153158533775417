export const saveInLocalStorage = (key: string, payload: string) => {
  localStorage.setItem(key, payload);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export function getValueFromSession<T>(key: string): T | null {
  if (!existsInLocalStorage("session")) {
    return null;
  }

  const sessionString: string = localStorage.getItem("session") || "";

  try {
    const sessionObject = JSON.parse(sessionString);
    return sessionObject[key];
  } catch (e) {
    return null;
  }
}

export function getValueFromLocalStorage(key: string): any | null {
  if (!existsInLocalStorage(key)) {
    return null;
  }
  return localStorage.getItem(key);
}

export function addValueInSession<T>(
  key: string,
  value: unknown
): null | unknown {
  if (!existsInLocalStorage("session")) {
    return null;
  }
  const sessionString: string | null = localStorage.getItem("session");

  if (sessionString === null) {
    return null;
  }

  try {
    const sessionObject = JSON.parse(sessionString);
    localStorage.setItem(
      "session",
      JSON.stringify({ ...sessionObject, [key]: value })
    );
  } catch (e) {
    return null;
  }
}

export function removeValueFromSession(key: string): void {
  if (!existsInLocalStorage("session")) {
    return;
  }

  const sessionString: string | null = localStorage.getItem("session");

  if (sessionString === null) {
    return;
  }

  try {
    const sessionObject = JSON.parse(sessionString);
    if (key in sessionObject) {
      delete sessionObject[key];
    }
    localStorage.setItem("session", JSON.stringify(sessionObject));
  } catch (e) {
    console.error(e);
  }
}

export const existsInLocalStorage = (key: string): boolean => {
  const item: string | null = localStorage.getItem(key);
  return item !== null;
};
