import { Axios_Post } from "../../axios";
import {
  FileSearchParams,
  FileSearchResponse
} from ".";

export const API_FILE_SEARCH = "/v1/fileSearch";

export const advancedFileSearch = async (fileSearchParams?: FileSearchParams): Promise<FileSearchResponse> => {
  if(Object.prototype.hasOwnProperty.call(fileSearchParams, "cig")) {
    fileSearchParams["cig"] = fileSearchParams["cig"]?.split(" - ")[0]
  }
  return Axios_Post<FileSearchResponse>(API_FILE_SEARCH, fileSearchParams);
};
