import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
/* AntD */
import { Button, Form, Skeleton } from "antd";
/* Components */
import {
  AntdFormDatePicker,
  AntdFormInputField,
  AntdFormSelectField,
  AntdFormSwitchField,
  AntdFormTextAreaField,
  Layout
} from "../../components";
/* Services and interfaces */
import { getGroup, Group, GroupsResponse } from "../../services/groups";
import { postRoles, RoleInterface, RoleResponseInterface } from "../../services/role";
import { createUser, getUserByID, GetUserByIdResponseInterface, updateUser, UserInterface } from "../../services/user";
/* Utils */
import { getRequiredValidation, mapToSelectData } from "../../helpers";
import { useSafeEffect } from "../../helpers/hooks";
import { usersTranslations, commonTranslations, } from "../../helpers/translations";
import { notify, operationError, operationSuccess } from "../../services/notification-wrapper";
import moment from "moment";
import { getDropdownByKey, MasterDataResponse } from "../../services/masterData";


const UserEdit = () => {

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const isEdit = id !== undefined;
  const usersTrans = usersTranslations;
  const expirationInitialValue = moment(new Date()).add(99, 'y')
  /* Application data */
  const [user, setUser] = useState<UserInterface>(null);
  const [userRolesOptions, setUserRolesOptions] = useState<RoleInterface[]>([]);
  const [companies, setCompanies] = useState([]);
  const [userGroupsOptions, setUserGroupsOptions] = useState<Group[]>([]);
  /* Loadings */
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchUserInfo = async () => {
    try {
      const userResp: GetUserByIdResponseInterface = await getUserByID(Number(id));
      return userResp.user;
    } catch (e) {
      console.log("Error fetching data? ", e);
      notify(operationError());
    }
  };

  useSafeEffect(
    [],
    () => {
      setLoading(true);
      return !isEdit ? Promise.resolve(null) : fetchUserInfo();
    },
    (data?: UserInterface) => {
      if (data) {
        if (typeof data.acljson === 'object') {
          data.acljson = JSON.stringify(data.acljson, null, 2);
        }
        form.setFieldsValue({ isActive: data.isActive, expireDate: data.expireDate ? moment(data.expireDate) : expirationInitialValue })
        setUser(data);
      }
    },
    (e: any) => notify(operationError(e.response.status)),
    () => setLoading(false)
  );

  useSafeEffect(
    [],
    () => {
      return fetchUserGroupsOptions();
    },
    (data: any) => {
      if (data) {
        setUserGroupsOptions(data);
      }
    }
  );

  useSafeEffect(
    [],
    () => {
      return fetchUserRoleOptions();
    },
    (data: any) => {
      if (data) {
        setUserRolesOptions(data);
      }
    }
  );

  useSafeEffect(
    [],
    () => {
      setLoading(true);
      const supplierPromise = getDropdownByKey("supplier");
      const partnerPromise = getDropdownByKey("partner");
      const customerPromise = getDropdownByKey("customer");
      return Promise.all([supplierPromise, customerPromise, partnerPromise]);
    },
    (data: [MasterDataResponse, MasterDataResponse, MasterDataResponse]) => {
      if (data && data.length > 1) {
        const [_suppliers, _customer, _partner] = data;
        setCompanies(mapToSelectData([..._suppliers.values, ..._customer.values, ..._partner.values]))
      }
    }
  );

  const fetchUserGroupsOptions = async () => {
    try {
      const optionGroups: GroupsResponse = await getGroup({ limit: 1000 });
      return optionGroups.groups;
    } catch (e) {
      console.log("error fetching user groups", e);
    }
    return [];
  };

  const fetchUserRoleOptions = async () => {
    try {
      const optionRole: RoleResponseInterface = await postRoles({ limit: 1000 });
      return optionRole.roles;
    } catch (e) {
      console.log("error fetching user roles", e);
    }
    return [];
  };

  // const fetchCustomer = async () => {
  //   try {
  //     const optionCustomer: MasterDataResponse = await getDropdownByKey("customer");
  //     return optionCustomer.values;
  //   } catch (e) {
  //     notify(operationError(e?.response?.status))
  //   };
  //   return [];
  // };

  // const fetchPartner = async () => {
  //   try {
  //     const optionPartner: MasterDataResponse = await getDropdownByKey("partner");
  //     return optionPartner.values;
  //   } catch (e) {
  //     notify(operationError(e?.response?.status))
  //   };
  //   return [];
  // };

  const onSave = async (formValues: any) => {
    try {
      setIsSaving(true);
      formValues = { ...formValues, acljson: formValues.acljson ? JSON.parse(formValues.acljson) : "" };
      if (isEdit) {
        await updateUser({ ...user, ...formValues });
      } else {
        await createUser({ ...formValues });
      }
      notify(operationSuccess());
      goToView();
    } catch (e) {
      notify(operationError(e?.response?.status));
    } finally {
      setIsSaving(false);
    }
  };

  const goToView = () => {
    navigate("/configuration/users");
  };

  return (
    <Layout
      title=""
      leaveEdit={goToView}
      isEditing={true}
      isSaving={isSaving}
      disableInsert={true}
    >
      <section className="p-4 mt-3 mx-1 section">
        <Skeleton loading={loading} active>
          <Form
            className="column"
            layout="horizontal"
            name="ProcessEditForm"
            form={form}
            initialValues={user ? { ...user, groupIdList: user.groups?.map(group => group.groups_id) } : { isActive: true }}
            onFinish={onSave}
          >
            <div>
              <h1 className="mt-1">{isEdit && user ? `${user.lastName} ${user.firstName}` : usersTrans.new}</h1>

              <div className="mt-4">
                <h4 className="mt-1">{usersTrans.sections.personal_data}</h4>

                <div className="row px-2">
                  <AntdFormInputField
                    name="CF"
                    placeholder={usersTrans.search.placeholders.CF}
                    label={usersTrans.search.labels.CF}
                  />

                  <AntdFormInputField
                    name="firstName"
                    placeholder={usersTrans.search.placeholders.firstName}
                    label={usersTrans.search.labels.firstName}
                    rules={getRequiredValidation("users", "firstName")}
                    required={true}
                  />

                  <AntdFormInputField
                    inputName="lastName"
                    placeholder={usersTrans.search.placeholders.lastName}
                    label={usersTrans.search.labels.lastName}
                    rules={getRequiredValidation("users", "lastName")}
                    required={true}
                  />
                </div>

                <div className="row px-2">
                  <AntdFormInputField
                    inputName="username"
                    placeholder={usersTrans.search.placeholders.username}
                    label={usersTrans.search.labels.username}
                    rules={getRequiredValidation("users", "username")}
                    required={true}
                  />

                  <AntdFormInputField
                    inputName="email"
                    placeholder={usersTrans.search.placeholders.email}
                    label={usersTrans.search.labels.email}
                    rules={getRequiredValidation("users", "email")}
                    required={true}
                  />
                </div>
              </div>

              <div>
                <h4 className="mt-1 mt-3">{usersTrans.sections.appData}</h4>

                <div className="row px-2">
                  <AntdFormSelectField
                    name="groupIdList"
                    placeholder={usersTrans.search.placeholders.groups}
                    label={usersTrans.search.labels.groups}
                    rules={getRequiredValidation("users", "groups")}
                    mode="multiple"
                    options={
                      userGroupsOptions?.map((uG) => ({
                        name: uG.groupname,
                        value: uG.id
                      }))
                    }
                    required={true}
                  />

                  <AntdFormSelectField
                    name="roles_id"
                    placeholder={usersTrans.search.placeholders.role}
                    label={usersTrans.search.labels.role}
                    rules={getRequiredValidation("users", "role")}
                    options={userRolesOptions?.map((uR) => ({
                      name: uR.label,
                      value: Number(uR.id)
                    }))}
                    required={true}
                  />

                  <AntdFormSelectField
                    name="company"
                    placeholder={usersTrans.search.placeholders.company}
                    label={usersTrans.search.labels.company}
                    rules={getRequiredValidation("users", "company")}
                    options={companies}
                    required={true}
                  />
                </div>

                <div className="row px-2">
                  <AntdFormDatePicker
                    initialValue={expirationInitialValue}
                    name="expireDate"
                    rules={getRequiredValidation("expireDate", usersTrans.labels.expireDate)}
                    label={usersTrans.labels.expireDate}
                    placeholder={usersTrans.search.placeholders.expireDate}
                  />

                  <AntdFormSwitchField
                    name="isActive"
                    label={usersTrans.search.labels.isActive}
                    switchClassNames="float-left"
                  />
                </div>

                <div>
                  <h4 className="mt-1 mt-3">{usersTrans.sections.permissions}</h4>

                  <div className="row px-3 justify-content-start">
                    <AntdFormTextAreaField
                      rows={10}
                      name="acljson"
                      placeholder={usersTrans.search.placeholders.acljson}
                      label={usersTrans.search.labels.acljson}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          message: commonTranslations.formValidations.acljsonRule,
                          validator(validationData, value) {
                            try {
                              value && JSON.parse(value);
                              return Promise.resolve();
                            } catch (error) {
                              return Promise.reject(commonTranslations.formValidations.acljsonRule);
                            }
                          },
                        }
                      ]}
                    />
                  </div>
                </div>

                <div>
                  <div className="row px-2 d-flex justify-content-end">
                    <Button
                      className="m-1"
                      onClick={() => goToView()}
                      disabled={isSaving}
                    >
                      {commonTranslations?.buttons?.back}
                    </Button>

                    <Button
                      className="m-1"
                      type="primary"
                      htmlType="submit"
                      loading={isSaving}
                    >
                      {commonTranslations.buttons.save}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Skeleton>
      </section>
    </Layout>
  );
};

export default UserEdit;
