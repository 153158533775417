import React, { useEffect, useState } from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import config from "../../config.json"

const AuthRoute = ({ children, path }: RouteProps) => {

    const { user } = useAuth();
    const menu = config.Menu;

    const searchForMenuItem = () => {
        let item: any;
        for (const menuItemKey in menu) {
            if (menuItemKey) {
                const menuItem = menu[menuItemKey];
                if (path.includes(menuItem.url)) {
                    item = menuItem;
                }
                if (!item && menuItem?.subPages?.length) {
                    for (const submenuItem of menuItem.subPages) {
                        if (path.includes(submenuItem.url)) {
                            item = submenuItem;
                        }
                    }
                }
                if (item) break;
            }
        }
        return item;
    }

    const isAllowed = () => {
        const currentItem = searchForMenuItem();
        const notAllowedTo = currentItem.notVisibleFor;
        return !notAllowedTo.includes(user?.role?.accessLevel);
    }

    return <>
        {isAllowed() ? children : <Navigate to={"/home"} />}
    </>
};

export default AuthRoute;
