import React from "react";
import { Form, Switch, FormItemProps } from "antd";

export interface AntdFormSwitchFieldProps extends FormItemProps {
  name: string;
  label: string;
  initialCheck?: boolean;
  value?: boolean;
  switchClassNames?: string;
}

const colProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const AntdFormSwitchField: React.FC<AntdFormSwitchFieldProps> = ({
  name,
  label,
  initialCheck,
  value,
  switchClassNames,
  ...otherProps
}) => {
  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
      <Form.Item
        name={name}
        label={label}
        valuePropName="checked"
        initialValue={value}
        {...colProps}
      >
        <Switch className={switchClassNames || ""} />
      </Form.Item>
    </div>
  );
};

export default AntdFormSwitchField;
