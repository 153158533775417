import React, { useRef, useState } from 'react';
import { Modal, ModalProps } from 'antd';
import Draggable from 'react-draggable';

export interface AntdModalProps extends ModalProps {
  modalBody: React.ReactNode;
  draggable?: boolean
}

const CustomModal: React.FC<AntdModalProps> = props => {

  const [disableDraggable, setDisableDraggable] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })

  const draggableRef = useRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = (draggableRef.current as any)?.getBoundingClientRect(); /* CHECK TIPO? */
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    })
  };

  return (
    <>
      <Modal
        {...props}
        title={
          props.draggable ? <div
          style={{
            width: '100%',
            cursor: 'default',
          }}
          onMouseOver={() => {
            if (disableDraggable) {
              setDisableDraggable(false)
            }
          }}
          onMouseOut={() => {
            setDisableDraggable(true)
          }}
        >
          {props.title}
        </div> : props.title
        }
        footer={props.footer}
        destroyOnClose={props.destroyOnClose}
        onCancel={() => props.onCancel(null)}
        mask={props.mask !== undefined ? props.mask : true}
        modalRender={modal => (
          props.draggable ? <Draggable
            disabled={disableDraggable}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggableRef}>{modal}</div>
          </Draggable> : modal
        )}
        maskClosable={props.maskClosable !== undefined ? props.maskClosable : true}
      >{props.modalBody}</Modal>
    </>
  );
};

export default CustomModal;
