import React, { FC, useEffect, useState } from "react";
import {
  AntdFormRangePicker,
  AntdFormSelectField,
  AntdSearch,
  CustomColumnType,
  CustomGridAntd,
  Layout
} from "../../components";
import { getFileUploads } from "../../services/console/console.service";
import { Button, Tag } from "antd";
import { uploadConsoleTranslations } from "../../helpers/translations";
import { UserParamsInterface } from "../../services/user";
import { notify, operationError } from "../../services/notification-wrapper";
import moment from "moment";
import { getConsoleMasterData } from "../../services/consoleMasterData/consoleMaterData.service";
import { formatDate } from "../../helpers";

const Console: FC = () => {
  const uploadTrans = uploadConsoleTranslations;
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [files, setFiles] = useState([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userNames, setUserNames] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [params, setParams] = useState<UserParamsInterface>({});
  const [sort, setSort] = useState<any>(null);
  /* Loadings */
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);

  const fetchFileUploadConsoles = async (newParams?: { [key: string]: any }) => {
    try {
      setTableLoading(true);
      const concatenatedParams = newParams?.limit ? { ...params, ...newParams } : newParams;
      setParams(concatenatedParams);
      const filesResponse = await getFileUploads(concatenatedParams);
      setFiles(filesResponse.FileUploadConsole);
      setTotalRows(filesResponse.totalrows || 0);
    } catch (e) {
      console.log("Error fetching data? ", e);
      notify(operationError());
    } finally {
      setTableLoading(false);
    }
  }
  useEffect(() => {
    (async () => {
      await fetchFileUploadConsoles();
      try {
        const usersResponse: { status: number, values: string[] } = await getConsoleMasterData('username');
        const statusResponse: { status: number, values: string[] } = await getConsoleMasterData('status');
        setUserNames(usersResponse.values)
        setStatuses(statusResponse.values)
      } catch (e) {
        notify(operationError())
      } finally {
        setLoading(false)
      }

    })()
  }, [])

  const sortRow = (sortType: any, key: string) => {
    if (
      sortType?.sortColumns?.length &&
      key === sortType?.sortColumn?.key &&
      ((sort?.sortColumn === sortType?.sortColumn &&
        sort?.sortOrder !== sortType?.sortOrder) ||
        sort?.sortColumn !== sortType?.sortColumn)
    ) {
      setSort(sortType);
      fetchFileUploadConsoles({
        order_by: sortType.sortOrder ? sortType.sortColumn.dataIndex : "",
        sort_dir: sortType.sortOrder === "descend" ? "desc" : "asc",
      }).then(() => {
        setTableLoading(false);
      });
    }
  };

  const columns: CustomColumnType[] = [
    {
      title(columnsSort) {
        sortRow(columnsSort, "filename");
        return uploadTrans.tableHeadings.filename;
      },
      dataIndex: "filename",
      key: "filename",
      name: uploadTrans.tableHeadings.filename,
      sorter: (a) => false,
      render: (name, record) => <div className="d-flex flex-column">
        {record?.filename?.split("|").map((filename, index) => <span key={index} className={index !== 0 ? "ml-4 mt-1" : ""}>{filename}</span>)}
      </div>
    },
    {
      title(columnsSort) {
        sortRow(columnsSort, "username");
        return uploadTrans.tableHeadings.username;
      },
      dataIndex: "username",
      key: "username",
      name: uploadTrans.tableHeadings.username,
      sorter: (a) => false,
    },
    {
      title(columnsSort) {
        sortRow(columnsSort, "start");
        return uploadTrans.tableHeadings.start;
      },
      dataIndex: "start",
      key: "start",
      name: uploadTrans.tableHeadings.start,
      sorter: (a) => false,
    },
    {
      title(columnsSort) {
        sortRow(columnsSort, "stop");
        return uploadTrans.labels.stop;
      },
      dataIndex: "stop",
      key: "stop",
      name: uploadTrans.labels.stop,
      sorter: (a) => false,
    },
    {
      title(columnsSort) {
        sortRow(columnsSort, "status");
        return uploadTrans.tableHeadings.status;
      },
      dataIndex: "status",
      key: "status",
      name: uploadTrans.tableHeadings.status,
      sorter: (a) => false,
      render(name, record) {
        let color = 'warning';
        if (record.status.toLowerCase() === 'ok') color = 'success';
        if (record.status.toLowerCase() === 'no') color = 'error'
        return <Tag color={color}>{record.status}</Tag>
      }
    },
    {
      title(columnsSort) {
        sortRow(columnsSort, "errorMessage");
        return uploadTrans.tableHeadings.errorMessage;
      },
      dataIndex: "errorMessage",
      key: "errorMessage",
      name: uploadTrans.tableHeadings.errorMessage,
      sorter: (a) => false,
    },
  ];

  const dataSource = files.map((file, index: number) => ({
    ...file,
    start: moment(file.start).format('DD/MM/yyyy HH:mm:SS'),
    stop: moment(file.stop).format('DD/MM/yyyy HH:mm:SS'),
    key: index,
    actions: file.id
  }));


  return (
    <Layout title={uploadTrans.title} disableInsert={true}>
      <>
        {showFilters && (
          <AntdSearch
            name="usersSearch"
            onSearch={(searchParams) => {
              if (searchParams) {
                let startTo, startFrom, stopFrom, stopTo;
                const { start, stop, username, status } = searchParams;
                if (start) {
                  [startFrom, startTo] = start;
                  startFrom = formatDate(startFrom, "yyyy-MM-DD HH:mm:ss");
                  startTo = formatDate(startTo, "yyyy-MM-DD HH:mm:ss");
                }
                if (stop) {
                  [stopFrom, stopTo] = stop;
                  stopFrom = formatDate(stopFrom, "yyyy-MM-DD HH:mm:ss");
                  stopTo = formatDate(stopTo, "yyyy-MM-DD HH:mm:ss");
                }
                searchParams = { username, status, startFrom, startTo, stopFrom, stopTo }
              }
              setCurrentPage(1);
              fetchFileUploadConsoles(searchParams);
            }}
          >
            <AntdFormSelectField
              name="username"
              label={uploadTrans.labels.username}
              placeholder={uploadTrans.placeholders.username}
              options={userNames.map((username) => ({
                name: username,
                value: username
              }))}
            />
            <AntdFormRangePicker
              showTime={true}
              name="start"
              placeholder={[uploadTrans.placeholders.from, uploadTrans.placeholders.to]}
              label={uploadTrans.labels.start}
              timeFormat="HH:mm"
              format="DD/MM/yyyy HH:mm"
            />
            <AntdFormRangePicker
              showTime={true}
              name="stop"
              placeholder={[uploadTrans.placeholders.from, uploadTrans.placeholders.to]}
              label={uploadTrans.labels.stop}
              timeFormat="HH:mm"
              format="DD/MM/yyyy HH:mm"
            />
            <AntdFormSelectField
              name={'status'}
              label={uploadTrans.labels.status}
              placeholder={uploadTrans.placeholders.status}
              options={statuses.map((status: string) => ({
                name: status,
                value: status
              }))}
            />
          </AntdSearch>
        )}
        <div className="row d-flex justify-content-center m-2">
          <section className="col-12 section">
            <div className="col-12 my-2 text-right">
              <Button
                onClick={() => setShowFilters(!showFilters)}
                className="mx-1 mr-2 d-inline-block"
              >
                <i className="fa fa-filter" aria-hidden="true" />
              </Button>
            </div>
            <CustomGridAntd
              loading={tableLoading}
              dataSource={dataSource}
              columns={columns}
              total={totalRows}
              currentPage={currentPage}
              pageChange={(page: number, pageSize?: number) => {
                setCurrentPage(page);
                const size = pageSize ? pageSize : 10
                fetchFileUploadConsoles({ offset: (page - 1) * size, limit: pageSize });
              }}
            />
          </section>
        </div>
      </>
    </Layout>
  )
}

export default Console;
