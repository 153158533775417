


import React, { useEffect, useState } from 'react';
/* AntD */
import { Breadcrumb } from 'antd';
import { HomeOutlined } from "@ant-design/icons";

interface TiTroviInProps {
    path: string;
    onRouteClick: (path: string) => void
}

const TiTroviIn: React.FC<TiTroviInProps> = ({ path, onRouteClick }) => {

    const [pathArr, setPathArr] = useState([]);

    useEffect(() => {
        setPathArr(path?.split("/"));
    }, [path])


    return <Breadcrumb className='breadcrumbs w-100'>
        <Breadcrumb.Item onClick={() => onRouteClick("/")}>
            <HomeOutlined />
        </Breadcrumb.Item>
        {pathArr.map((pathElem, index) => {
            return <Breadcrumb.Item key={index} onClick={() => pathArr.length - 1 !== index && onRouteClick(pathArr.slice(0, index + 1).join("/"))}>
                {pathElem}
            </Breadcrumb.Item>
        })}
    </Breadcrumb>
}

export default TiTroviIn;
