import React, { useState, useEffect, FC, Dispatch } from 'react';
import { AntdFormAutoCompleteField, AntdFormSelectField } from "../../components";
import { Button, Form, Popconfirm, Tree } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DataNode } from "antd/es/tree";
import { getRequiredValidation } from "../../helpers";
import { DocumentTypeResponse, getDocumentTypes } from "../../services/documentTypes";
import { getFolderSuggestions } from "../../services/documentTypes/documentTypes.service";
import { commonTranslations, documentTypesTranslations } from '../../helpers/translations';
const { DirectoryTree } = Tree;

const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

interface PathPatternPropsInterface {
  defaultPath: string[] | undefined;
  currentPath?: string[];
  setArrayPathPattern: Dispatch<{ type: string; item: string | string[]; }>
}

const folderVariableOptions = [{
  name: '<year>',
  value: '<year>'
},
{
  name: '<month>',
  value: '<month>'
},
{
  name: '<businessUnit>',
  value: '<businessUnit>',
},
{
  name: '<societaInfragruppo>',
  value: '<societaInfragruppo>',
},
{
  name: '<odl>',
  value: '<odl>',
},
{
  name: '<croca>',
  value: '<croca>',
},
{
  name: '<subject>',
  value: '<subject>',
},
{
  name: '<customer>',
  value: '<customer>',
},
{
  name: '<partner>',
  value: '<partner>',
},
{
  name: '<supplier>',
  value: '<supplier>',
},
{
  name: '<oda>',
  value: '<oda>',
}
]

export const PathPattern: FC<PathPatternPropsInterface> = ({
  defaultPath,
  setArrayPathPattern,
}) => {
  const [existingPathForm] = Form.useForm();
  const [folderForm] = Form.useForm();
  const [variableFolderForm] = Form.useForm();

  const [treeData, setTreeData] = useState<DataNode[]>([]);

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [popOverOpen, setPopoverOpen] = useState(false);
  const [existingPathsOptions, setExistingPathsOptions] = useState([]);
  const [lsOptions, setLsOptions] = useState([]);
  const [showExistingPath, setShowExistingPath] = useState(true);
  const documentTypesTrans = documentTypesTranslations;
  const addToLastLeaf = (tree: DataNode, node: string) => {
    if (tree.children.length === 0) {
      const uniqueId = uid();
      tree.children = [{
        title: node,
        key: uniqueId,
        children: []
      }];
      return uniqueId;
    }
    return addToLastLeaf(tree.children[0], node);
  };

  const transformNodeToString = (tree: DataNode, path: string): string => {
    if (!tree) return '';
    if (tree.children.length === 0) return `${path}/${tree.title}`;
    return transformNodeToString(tree.children[0], `${path}/${tree.title}`);
  }

  const transformStringToDataNode = (pathArray: string[]): DataNode[] => {
    let newTreeData: DataNode[] = [];
    pathArray.forEach((path, index) => {
      if (index === 0) {
        const uniqueId = uid();
        newTreeData = [{
          title: path,
          key: uniqueId,
          children: []
        }];
        setExpandedKeys([uniqueId]);
      } else {
        const key = addToLastLeaf(newTreeData[0], path);
        setArrayPathPattern({ type: 'ADD', item: path })
        setExpandedKeys([...expandedKeys, key]);
      }
    });
    return newTreeData
  }

  const initializeStates = () => {
    const firstId = uid();
    let defaultDataTree: DataNode[] = [{
      title: '<cig>',
      key: firstId,
      children: []
    }];
    if (defaultPath) {
      defaultDataTree = transformStringToDataNode(defaultPath)
    } else {
      setArrayPathPattern({ type: 'INITIALIZE', item: '<cig>' });
      setExpandedKeys([firstId]);
    }
    setTreeData(defaultDataTree);
  }

  useEffect(() => {
    setLsOptions([]);
    searchFolders();
  }, [treeData])

  const getExistingPaths = () => {
    getDocumentTypes({ limit: 9999 })
      .then((res: DocumentTypeResponse) => {
        const pdgClientPaths = res.documentType
          .filter((docType) => docType.sourceSystem === 'PDG_CLIENT')
          .map((pdgDocType) => `/${pdgDocType.pathPattern.join('/')}`);
        setExistingPathsOptions([...new Set(pdgClientPaths)].sort());
      })
  }

  const searchFolders = async () => {
    try {
      const currentPathString = transformNodeToString(treeData[0], '');
      if (currentPathString === '') return [];
      const res = await getFolderSuggestions(currentPathString.substring(1, currentPathString.length));
      setLsOptions(res.ls.map((fName) => ({ data: fName, value: fName })));
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    initializeStates();
    getExistingPaths();
  }, [])

  const isLastNode = (tree: DataNode, node: string): boolean => {
    if (tree.key === node && tree.children.length === 0) return true;
    if (tree.key === node) return false;
    return isLastNode(tree.children[0], node);
  }

  const handleAddExistingPath = () => {
    const existingPath = existingPathForm.getFieldValue('presetFolder');
    const nodes = existingPath.split('/');
    // REMOVE empty string & <cig> folder
    nodes.shift();
    const cigFolder = nodes.shift();
    setArrayPathPattern({ type: 'ADD', item: nodes });
    nodes.unshift(cigFolder)
    const uniqueId = uid();
    const newDataNode: DataNode[] = [{
      title: nodes[0],
      key: uniqueId,
      children: []
    }]
    setExpandedKeys([uniqueId])
    nodes.forEach((node, index) => {
      if (index === 0) return;
      const key = addToLastLeaf(newDataNode[0], node);
      setExpandedKeys([...expandedKeys, key]);
    })

    setTreeData(newDataNode);
    existingPathForm.resetFields(['presetFolder'])
  };

  const handleAddFolder = () => {
    const folderName = folderForm.getFieldValue('folder');
    const key = addToLastLeaf(treeData[0], folderName);
    setExpandedKeys([...expandedKeys, key]);
    setTreeData([{ ...treeData[0] }]);
    setArrayPathPattern({ type: 'ADD', item: folderName });
    folderForm.resetFields(['folder']);
    setLsOptions([]);
  };

  const handleAddFolderVariable = () => {
    const folderName = variableFolderForm.getFieldValue('folderVariable');
    const key = addToLastLeaf(treeData[0], folderName);
    setExpandedKeys([...expandedKeys, key]);
    setTreeData([{ ...treeData[0] }]);
    setArrayPathPattern({ type: 'ADD', item: folderName });
    variableFolderForm.resetFields(['folderVariable']);
  };

  const removeLastItem = (tree: DataNode) => {
    if (tree.children.length > 0 && tree.children[0].children.length === 0) {
      tree.children = [];
      setArrayPathPattern({ type: 'REMOVE', item: '' });
      return;
    }
    removeLastItem(tree.children[0]);
  }

  return (
    <div className={'d-flex flex-column my-5 border p-2'}>
      <h3 className={'mx-auto'}>{documentTypesTrans.sections.setPathPattern}</h3>
      <div className={'d-flex justify-content-between'}>
        <Form
          form={existingPathForm}
          onFinish={handleAddExistingPath}
          style={{ flex: 1, display: showExistingPath ? 'block' : 'none' }}>
          <div className="d-flex position-relative col-6">
            <AntdFormSelectField
              name="presetFolder"
              placeholder={documentTypesTrans.placeholders.existingPaths}
              label={documentTypesTrans.labels.existingPaths}
              cols={'col-11'}
              rules={getRequiredValidation("documentTypes", "name")}
              required={true}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  existingPathForm && existingPathForm.submit();
                }
              }}
              options={existingPathsOptions.map((path: string) => ({ name: path, value: path }))}
            />
            <Button
              icon={<PlusOutlined />}
              shape={'circle'}
              type={'default'}
              className={'position-absolute'}
              style={{ top: 44, right: 20 }}
              onClick={() => existingPathForm && existingPathForm.submit()}
            />
          </div>
        </Form>
        <Form
          form={folderForm}
          onFinish={handleAddFolder}
          style={{ flex: 1, display: !showExistingPath ? 'block' : 'none' }}
        >
          <div className="d-flex position-relative">
            <AntdFormAutoCompleteField
              name="folder"
              placeholder={documentTypesTrans.placeholders.folder}
              label={documentTypesTrans.labels.folder}
              formItemsProps={{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
                required: true,
                rules: getRequiredValidation("documentTypes", "folder")
              }}
              customClass={'col-11'}
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  folderForm && folderForm.submit();
                }
              }}
              noDebounce={true}
              options={lsOptions}
            />
            <Button
              icon={<PlusOutlined />}
              type={'default'}
              shape={'circle'}
              className={'position-absolute'}
              style={{ top: 40, right: 20 }}
              onClick={() => folderForm && folderForm.submit()}
            />
          </div>
        </Form>
        <Form
          form={variableFolderForm}
          onFinish={handleAddFolderVariable}
          style={{ flex: 1, display: !showExistingPath ? 'block' : 'none' }}
        >
          <div className="d-flex position-relative">
            <AntdFormSelectField
              name="folderVariable"
              placeholder={documentTypesTrans.placeholders.variable}
              label={documentTypesTrans.labels.variable}
              cols={'col-11'}
              options={folderVariableOptions}
              rules={getRequiredValidation("documentTypes", "name")}
              required={true}
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  variableFolderForm && variableFolderForm.submit();
                }
              }}
            />
            <Button
              icon={<PlusOutlined />}
              shape={'circle'}
              type={'default'}
              className={'position-absolute'}
              style={{ top: 44, right: 20 }}
              onClick={() => variableFolderForm && variableFolderForm.submit()}
            />
          </div>
        </Form>
      </div>
      <div className={'my-3 col-4 mx-auto'}>
        <h6>{documentTypesTrans.sections.folderStructure}</h6>
        <DirectoryTree
          className='directoryTreeComponent'
          treeData={treeData}
          defaultExpandAll
          multiple
          checkable={false}
          selectable={false}
          defaultExpandParent={true}
          autoExpandParent={true}
          defaultExpandedKeys={expandedKeys}
          expandedKeys={expandedKeys}
          onRightClick={({ node }) => {
            if (isLastNode(treeData[0], node.key.toString()) && node.title !== '<cig>') setPopoverOpen(true);
          }}
        />
        <Popconfirm
          trigger="click"
          visible={popOverOpen}
          onConfirm={() => {
            removeLastItem(treeData[0]);
            setTreeData([{ ...treeData[0] }])
          }}
          okText={commonTranslations.buttons.yes}
          cancelText={commonTranslations.buttons.no}
          onVisibleChange={(visible) => setPopoverOpen(visible)}
          title={documentTypesTrans.sections.deleteFolder}
        />
      </div>
      <Button
        type={'primary'}
        onClick={() => setShowExistingPath(!showExistingPath)}
        style={{ width: 'max-content', margin: '0 auto' }}
      >
        {showExistingPath ? documentTypesTrans.sections.enterCustomPath : documentTypesTrans.sections.selectExistingPath}
      </Button>
    </div>

  )
}
