import React from "react";
import PageHeading from "./PageHeading";

interface LayoutProps {
  disableInsert?: boolean;
  children: JSX.Element;
  title: string;
  setIsEditing?: () => void;
  leaveEdit?: () => void;
  isEditing?: boolean;
  onSave?: () => void;
  isSaving?: boolean;
}

export const Layout = (props: LayoutProps) => {
  return (
    <>
      <PageHeading
        disableInsert={props.disableInsert || false}
        pageTitle={props.title}
        setIsEditing={props.setIsEditing}
        leaveEdit={props.leaveEdit}
        isEditing={props.isEditing}
        saveChanges={props.onSave}
        isSaving={props.isSaving}
      />
      {props.children}
    </>
  );
};

export default Layout;
