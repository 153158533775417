import React, { ReactElement } from 'react';
/* i18next */
import { useTranslation } from 'react-i18next';
/* COMPONENTS */
import { Button } from 'antd';
/* UTILS */
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export interface DownloadProps {
  filename: string;
  columns: { label: any; key: any; value: any }[];
  dataSource: unknown;
  sheetName?: string;
}

const Download: React.FC<DownloadProps> = ({ filename, columns, dataSource, sheetName }) => {
  const { t } = useTranslation();

  const Buttons: any = t('Buttons', { returnObjects: true });

  return (
    <ExcelFile
      filename={filename}
      element={
        <Button className="ml-3 mb-2">
          {Buttons.exportToXls} <i className="ml-1 fa fa-download" />
        </Button>
      }
    >
      <ExcelSheet data={dataSource} name={sheetName || 'Sheet 1'}>
        {columns.map((col, index) => (
          <ExcelColumn label={col.label} key={index} value={col.key} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default Download;
