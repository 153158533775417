import React, { useState } from "react";
/* Components */
import { AntdSearch } from "../..";
import { useSafeEffect } from "../../../helpers/hooks";
import { AntdFormSelectField, OptionProps } from "../../common/dataEntry";
/* Services and Interfaces */
import { ConsultSearchInterface } from ".";
import { MasterDataResponse, getDropdownByKey, getApplicationCode } from "../../../services/masterData";
/* Translations */
import { commonTranslations } from "../../../helpers";
/* Utility */
import { mapToSelectData } from "../../../helpers";
import { notify, operationError } from "../../../services/notification-wrapper";
/* Provider */
import { useConsult } from "../../../views/consult/consultProvider";

const SearchKnowledgeManagement: React.FC<ConsultSearchInterface> = ({
  onSearch,
  isSearching
}) => {

  const { cig, businessUnits, searchParams, selectedCig, setMasterdataCache } = useConsult();

  const [loading, setLoading] = useState(false);
  const { labels, placeholders } = commonTranslations;
  const [applicationCodes, setApplicationCodes] = useState([]);
  const [valuesToSet, setValuesToSet] = useState({ cig: selectedCig })


  useSafeEffect(
    [selectedCig],
    () => {
      setValuesToSet((state) => ({...state, cig: selectedCig}))
      if (!selectedCig && businessUnits.length && cig.length) return Promise.resolve(null);
      setLoading(true);
      const businessUnitsPromise = getDropdownByKey("innerBusinessUnit", selectedCig);
      const cigPromise = getDropdownByKey("cig", selectedCig);
      return Promise.all([businessUnitsPromise, cigPromise]);
    },
    (data: [MasterDataResponse, MasterDataResponse]) => {
      if (data && data.length > 1) {
        const [_businessUnits, _cigs] = data;
        setMasterdataCache({
          businessUnits: mapToSelectData(_businessUnits?.values),
          cig: mapToSelectData(_cigs?.values)
        })
      }
    },
    (err: any) => notify(operationError()),
    () => setLoading(false)
  );

  const fetchApplicationCodes = async (changedValue, allValues) => {

    if (!allValues?.cig || !allValues.businessUnit) {
      setApplicationCodes([]);
      setValuesToSet((state) => ({ ...state, applicationCode: undefined }));
      return;
    }

    const masterDataResponse = await getApplicationCode({ cig: allValues.cig, businessUnit: allValues.businessUnit, columnKey: "" });
    setApplicationCodes(masterDataResponse.values.map<OptionProps>(value => ({ name: value, value })))
  }

  return <AntdSearch
    name="searchKnowledgeManagement"
    onSearch={(formValues) => {
      if (!formValues) {
        setApplicationCodes([]);
        setValuesToSet((state) => ({ ...state, applicationCode: undefined }));
      }
      onSearch(formValues, "searchKnowledgeManagement")
    }}
    loading={isSearching}
    initialValues={searchParams}
    valuesToSet={valuesToSet}
    resetTrigger={selectedCig}
    atLeastOneFieldRequired
    onValuesChange={fetchApplicationCodes}
  >
    <AntdFormSelectField
      name={"cig"}
      label={labels.cig}
      placeholder={placeholders.cig}
      options={cig}
      cols="col-4"
      loading={loading}
      defaultValue={selectedCig}
      disabled={isSearching}
    />
    <AntdFormSelectField
      name={"businessUnit"}
      label={labels.businessUnit}
      placeholder={placeholders.businessUnit}
      options={businessUnits}
      cols="col-4"
      loading={loading}
      disabled={isSearching || !businessUnits.length}
    />
    <AntdFormSelectField
      name={"applicationCode"}
      label={labels.applicationCode}
      placeholder={placeholders.applicationCode}
      options={applicationCodes}
      cols="col-4"
      loading={loading}
      disabled={isSearching || !applicationCodes.length}
    />
  </AntdSearch>
}

export default SearchKnowledgeManagement;