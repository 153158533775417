import React, { useState } from "react";
/* Components */
import { AntdSearch } from "../..";
import { useSafeEffect } from "../../../helpers/hooks";
import { AntdFormSelectField } from "../../common/dataEntry";
/* Services and Interfaces */
import { ConsultSearchInterface } from ".";
import { MasterDataResponse, getDropdownByKey } from "../../../services/masterData";
/* Translations */
import { commonTranslations } from "../../../helpers";
/* Utility */
import { mapToSelectData } from "../../../helpers";
import { notify, operationError } from "../../../services/notification-wrapper";
/* Provider */
import { useConsult } from "../../../views/consult/consultProvider";

const SearchKPI: React.FC<ConsultSearchInterface> = ({
    onSearch,
    isSearching
}) => {

    const [loading, setLoading] = useState(false);
    const { labels, placeholders } = commonTranslations;

    const { cig, years, searchParams, selectedCig, setMasterdataCache } = useConsult();



    useSafeEffect(
        [selectedCig],
        () => {
          if (!selectedCig && years.length && cig.length) return Promise.resolve(null);
          setLoading(true);
          const yearsPromise = getDropdownByKey("year", selectedCig);
          const cigPromise = getDropdownByKey("cig", selectedCig);
          return Promise.all([yearsPromise, cigPromise]);
        },
        (data: [MasterDataResponse, MasterDataResponse]) => {
          if (data && data.length > 1) {
            const [_years, _cigs] = data;
            setMasterdataCache({
              years: mapToSelectData(_years?.values),
              cig: mapToSelectData(_cigs?.values)
            })
          }
        },
        (err: any) => notify(operationError()),
        () => setLoading(false)
      );

    return <AntdSearch
        name="searchKPI"
        onSearch={(formValues) => onSearch(formValues, "searchKPI")}
        loading={isSearching}
        initialValues={searchParams}
        valuesToSet={{ cig: selectedCig }}
        resetTrigger={selectedCig}
        atLeastOneFieldRequired
    >
        <AntdFormSelectField
            name={"cig"}
            label={labels.cig}
            placeholder={placeholders.cig}
            options={cig}
            cols="col-6"
            loading={loading}
            defaultValue={selectedCig}
            disabled={isSearching}
        />
        <AntdFormSelectField
            name={"year"}
            label={labels.year}
            placeholder={placeholders.year}
            options={years}
            cols="col-6"
            loading={loading}
            disabled={isSearching}
        />
        {/*<AntdFormSelectField
            name={"month"}
            label={labels.month}
            placeholder={placeholders.month}
            options={async () => mapToSelectData((await getDropdownByKey("month")).values)}
            loading={loading}
            disabled={isSearching}
        /> */}
    </AntdSearch>
}

export default SearchKPI;