import React from "react";
import { Form, Radio, FormItemProps } from "antd";

export interface RadioProps {
  name: string;
  value: string;
}

export interface AntdFormRadioFieldProps extends FormItemProps {
  name: string;
  label: string;
  radios: RadioProps[];
}

const colProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const AntdFormRadio: React.FC<AntdFormRadioFieldProps> = ({
  name,
  label,
  radios,
  required,
  ...otherProps
}) => {
  return (
  <Form.Item name={name} label={label} {...otherProps} {...colProps}>
      <Radio.Group>
        {radios.map((radio, index: number) => (
          <Radio key={index} value={radio.value}>
            {radio.name}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default AntdFormRadio;
