import React from "react";
import { Table, TableProps } from "antd";
import { useTranslation } from 'react-i18next';
import { commonTranslations } from "../../../helpers";
import { FilterValue, TablePaginationConfig, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";


export interface CustomGridAntdPropsInterface extends TableProps<any> {
  loading?: boolean;
  total?: number;
  currentPage?: number;
  pageChange?: (page: number, pageSize?: number) => void;
  limitOptions?: string[];
  onTableChange?: (pagination: TablePaginationConfig, filters: Record<string, FilterValue>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) => void;
}

export interface CustomColumnType {
  key: string;
  align?: any;
  dataIndex: string;
  name?: any;
  render?:
  | ((name: string, record: any) => JSX.Element)
  | ((id: number) => JSX.Element);
  sorter?: boolean | ((a: any) => any);
  title: string | ((sort?: any) => any);
}

const CustomGridAntd: React.FC<CustomGridAntdPropsInterface> = ({
  loading,
  dataSource,
  currentPage,
  total,
  pageChange,
  limitOptions,
  columns,
  onTableChange,
  ...otherProps
}) => {


  let paginationProps = {
    total,
    current: currentPage,
    hideOnSinglePage: false,
    showSizeChanger: true,
    showLessItems: true,
    pageSizeOptions: limitOptions || ['10', '20', '40', '60', '100'],
    showTotal(totalRows: any, range: any) {
      return `${commonTranslations.tables.total}: ${totalRows}`;
    },
    locale: { items_per_page: `/ ${commonTranslations.tables.page}` },
    onChange(page: number, pageSize?: number) { }
  }

  if (pageChange) {
    paginationProps = {
      // @ts-ignore
      ...paginationProps, onChange(page: number, pageSize: number) {
        pageChange(page, pageSize);
      }
    }
  }


  return (
    <div className="col-12">
      <Table
        loading={loading}
        dataSource={dataSource}
        pagination={paginationProps}
        scroll={{ x: "max-content" }}
        columns={columns}
        onChange={onTableChange}
        {...otherProps}
      />
    </div>
  );
};

export default CustomGridAntd;
