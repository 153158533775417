import React, { useState } from 'react';
import { Link } from 'react-router-dom';
/* AntD */
import { SearchOutlined, UploadOutlined, FileSearchOutlined } from "@ant-design/icons"
/* Components */
import { Layout, useAuth } from "../components";
import { AntdCard } from '../components/common/dataDisplay';
import { homeTranslations as home, menuTranslations as menu } from '../helpers/translations';
/* Services */
import { getStatistics, StatisticsInterface } from '../services/statistics';
/* Images */
import almaviva from "../assets/images/logo_almaviva.png";
import leonardo from "../assets/images/logo_leonardo.png";
import tim from "../assets/images/logo_TIM.png";
import layerIcon from "../assets/images/layer.png";
/* Utils */
import { useSafeEffect } from '../helpers/hooks';
import { notify, operationError } from '../services/notification-wrapper';
import { formatFileSize } from '../helpers';
import { Spin } from 'antd';

const Home = () => {

  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState<{ fileCount: number, totalSize: string }>(null);
  const {user} = useAuth();

  useSafeEffect(
    [],
    () => {
      setLoading(true);
      return getStatistics();
    },
    (statsResponse: StatisticsInterface) => {
      setStatistics({
        fileCount: statsResponse.NumberOfObjects,
        totalSize: formatFileSize(statsResponse.BucketSizeBytesValue)
      })
    },
    (err: any) => notify(operationError()),
    () => setLoading(false)
  );

  const internalLinks = [
    {
      title: menu.consult.title,
      href: "/consult/documents/search",
      icon: <SearchOutlined />
    },
    {
      title: menu.consult.subPages.fileSearch.title,
      href: "/consult/fileSearch",
      icon: <FileSearchOutlined />
    },
    {
      title: menu.upload.title,
      href: "/upload",
      icon: <UploadOutlined />,
      hidden: !user || user?.role?.accessLevel === "ro"
    },
    /* {
      title: menu.configuration.title,
      href: "/configuration",
      icon: <SettingOutlined />
    } */
  ]
  const externalLinks = [
    {
      title: "Banca dati ALT",
      href: "https://gestioneodlalt.gruppoalmaviva.it/",
      icon: <img src={layerIcon} className="img-fluid"></img>,
      disabled: false
    }
    /* {
      title: "Scheda Readiness ALT 1",
      href: "https://almavivaitaliaspa.sharepoint.com/:f:/r/sites/TransitionFerrovie/Documenti%20condivisi/Scheda%20Readiness%20ALT%201?csf=1&web=1&e=aNfTve&xsdata=MDV8MDF8fDA3YzkzZDNjMmE1MDQ5YjNiNDBiMDhkYWM4YmRmYTNlfDAyODIyNmUwOTllYTRmYWI5ZDFiZGFhNDQwYzllMjg2fDB8MHw2MzgwNDMwMTU0OTkxMzM5NjN8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxNVFkyT0Rjd05EYzBPRFl3TnpzeE5qWTROekEwTnpRNE5qQTNPekU1T2pRNE1tVTNNalF5TFdNNU56a3ROR1E0TnkxaE9UazRMV1U0TXpFellUSTFOalExTUY4Mk9UQTVaamRpTXkweFpqWXhMVFJpTWpVdE9UZzVPQzAxTldaaFlXRTVaV0prWkdKQWRXNXhMbWRpYkM1emNHRmpaWE09fDFlYjVmYjE2MjZmOTQ2NTdiNDBiMDhkYWM4YmRmYTNlfDVmNWRiOTViM2MzNzQ4MGM4MGY0NzE0NTk4OGE1ZThj&sdata=NVhBTzBKU3lpekVuZ3YwcXY2MTN1b1gwSFRBc0xKL1RYa3lrTWYvMndqdz0%3D&ovuser=028226e0-99ea-4fab-9d1b-daa440c9e286%2CE.Mattei%40almaviva.it",
      icon: <img src={layerIcon} className="img-fluid"></img>,
      disabled: false
    },
    {
      title: "Scheda Readiness ALT 2",
      href: "https://almavivaitaliaspa.sharepoint.com/:f:/r/sites/TransitionFerrovie/Documenti%20condivisi/Scheda%20Readiness%20ALT%202?csf=1&web=1&e=z7ySia&xsdata=MDV8MDF8fDA3YzkzZDNjMmE1MDQ5YjNiNDBiMDhkYWM4YmRmYTNlfDAyODIyNmUwOTllYTRmYWI5ZDFiZGFhNDQwYzllMjg2fDB8MHw2MzgwNDMwMTU0OTkxMzM5NjN8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxNVFkyT0Rjd05EYzBPRFl3TnpzeE5qWTROekEwTnpRNE5qQTNPekU1T2pRNE1tVTNNalF5TFdNNU56a3ROR1E0TnkxaE9UazRMV1U0TXpFellUSTFOalExTUY4Mk9UQTVaamRpTXkweFpqWXhMVFJpTWpVdE9UZzVPQzAxTldaaFlXRTVaV0prWkdKQWRXNXhMbWRpYkM1emNHRmpaWE09fDFlYjVmYjE2MjZmOTQ2NTdiNDBiMDhkYWM4YmRmYTNlfDVmNWRiOTViM2MzNzQ4MGM4MGY0NzE0NTk4OGE1ZThj&sdata=YlRwYllGQ2pqb21zSys3aVdkMHEyUTdQWE9yRGMwTXhVV0g3TXJGVWZmYz0%3D&ovuser=028226e0-99ea-4fab-9d1b-daa440c9e286%2CE.Mattei%40almaviva.it",
      icon: <img src={layerIcon} className="img-fluid"></img>,
      disabled: false
    }, */
    /* {
      title: "SLM",
      href: "https://www.google.com",
      icon: <img src={layerIcon} className="img-fluid"></img>
    } */
  ]

  return (
    <Layout title={home.title} disableInsert={true}>
      <div className='row p-3 m-3 menuCardContainer'>

        <div className="col-sm-12 col-md-7 col-lg-12 col-xl-9">
          {/* Card applicazioni */}
          <div className="row mt-3 mx-2">
            <AntdCard title={home.apps} style={{ height: "100%" }}>
              <p className='titleSectionApplicazioni'>{home.internalApps}</p>
              <hr className='w-100' />
              <div className="row appsRow">
                {internalLinks
                  .filter(link => !link.hidden)
                  .map(link =>
                    <span key={link.title} className="linkContainer col-12 p-3">
                      <Link to={link.href} title={link.title}>
                        <span>{link.icon}</span>
                        <span className='pl-3'>{link.title}</span>
                      </Link>
                    </span>
                  )}
              </div>

              <p className='titleSectionApplicazioni'>{home.externalApps}</p>
              <hr className='w-100' />

              <div className="row appsRow">
                {externalLinks
                  .map(link =>
                    <span key={link.title} className="linkContainer col-12 p-3">
                      <a href={link.href} target="_blank" title={link.title} rel="noreferrer" className={link.disabled ? "disabledLinks" : ""}>
                        <span>{link.icon}</span>
                        <span className='pl-3'>{link.title}</span>
                      </a>
                    </span>
                  )}
              </div>
            </AntdCard>
          </div>
        </div>

        <div className="col-sm-12 col-md-5 col-lg-12 col-xl-3">
          {/* Card RTI */}
          <div className="row mt-3 mx-2">
            <AntdCard title={home.rti}>
              <div id="loghiRti" className="d-flex flex-column align-items-center">
                <img src={almaviva} alt="Logo Almaviva" />
                <img src={leonardo} alt="Logo Leonardo" />
                <img src={tim} alt="Logo TIM" />
              </div>
            </AntdCard>
          </div>

          {/* Card statistics */}
          <div className="row mt-3 mx-2">
            <AntdCard title={home.stats}>
              <div className='d-flex flex-column text-center' style={{ fontSize: "1.5em" }}>
                {!loading && statistics &&
                  <>
                    <span>{home.statistics.fileCount} <b>{statistics?.fileCount || 0} {home.statistics.file}</b></span>
                    <span>{home.statistics.total} <b>{statistics?.totalSize || "0 Bytes"}</b></span>
                  </>
                }
                {!loading && !statistics &&
                  <span>{home.statistics.notAvailable}</span>
                }
                {loading &&
                  <div>
                    <Spin size='large' />
                  </div>
                }
              </div>
            </AntdCard>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Home
