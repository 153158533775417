import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
/* AntD */
import { Button, Pagination, Radio, Select, Spin } from 'antd';
import { ArrowLeftOutlined, UnorderedListOutlined, AppstoreOutlined } from "@ant-design/icons"
/* Components */
import FileSection from './fileSection';
import { TiTroviIn } from '../../../../components/layout';
/* Services and interfaces */
import { FileSystemTree, navigateTo } from '../../../../services/navigate';
/* Translations */
import { commonTranslations as common } from '../../../../helpers/translations';
/* Utils */
import { notify, operationError } from '../../../../services/notification-wrapper';
/* Providers */
import { useConsult } from '../../consultProvider';

const ConsultResults = () => {

    const navigate = useNavigate();
    /* States */
    const [params, setParams] = useState({
        order_by: undefined,
        sort_dir: "asc",
        offset: 0
    })
    const [gridType, setGridType] = useState("table");
    const [generalLoading, setGeneralLoading] = useState(true);
    const [localLoading, setLocalLoading] = useState(false);
    const [fileSystemTree, setFileSystemTree] = useState<{ directories: FileSystemTree[], files: FileSystemTree[] }>({
        directories: [],
        files: []
    });
    const[totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    /* Provider data */
    const { prevPath, path, enableFileSelection, setEnableFileSelection, setPath } = useConsult();

    useEffect(() => {
        handleNavigation(path);
    }, [params])

    useEffect(() => {
      setPage(1);
      setParams({...params, offset: 0})
    }, [path])
    


    const handleNavigation = async (newPath: string) => {
        try {
            /* Prevent navigation with empty path */
            if (!newPath) { setFileSystemTree(null); return; }
            setLocalLoading(true);
            const navigationData = await navigateTo({ requestParams: { path: newPath }, ...params });
            /* Split data in directories & files and populate state var */
            const files = [], directories = [];
            for (const fsitem of navigationData.fileSystemTree) {
                fsitem.mimeType === "inode/directory" ? directories.push(fsitem) : files.push(fsitem);
            }
            setFileSystemTree({ files, directories });
            setTotalRows(navigationData.totalrows)
        } catch (error) {
            setPath(prevPath)
            notify(operationError());
        } finally {
            setGeneralLoading(false);
            setLocalLoading(false);
        }
    }

    return <section className="col-12 col-md-11 p-3 section vh-80 w-100 h-100">
        {generalLoading ?
            <div className="spinnerCont">
                <Spin size='large' />
            </div>
            :
            <>
                {localLoading && <div className="localSpinner">
                    <Spin size='large' />
                </div>}
                <div className="col-12 d-flex justify-content-between">
                    {/* TODO: Improve breadcrumbs? */}
                    <Button className='navigationButtons mr-3' onClick={() => navigate("/consult/documents/search")}><ArrowLeftOutlined /> {common.buttons.backToSearch}</Button>
                    <TiTroviIn path={path} onRouteClick={(newPath) => !localLoading && setPath(newPath)} />
                </div>

                <div className="col-12 d-flex justify-content-between mt-4 px-4 fileSectionActions">
                    <h3>{path?.split("/")[1] ? path?.split("/")[path?.split("/").length - 1] : "Root"}</h3>
                    {/* Replace with some dropdown based on resolution?? It's hard to handle different resolutions */}
                    <div className='d-flex'>
                        {!enableFileSelection && !!fileSystemTree?.files?.length && <Button className='mr-2' onClick={() => setEnableFileSelection(true)}>{common?.buttons?.selectFiles}</Button>}
                        <Select
                            options={[{ label: common.filters.filename, value: "fsitem" }/* , { label: common.filters.creationDate, value: "dataCreazione" }, { label: common.filters.documentType, value: "documentTypes_id" } */]}
                            style={{ width: "10rem" }}
                            allowClear={true}
                            value={params?.order_by}
                            onChange={(value) => setParams({ ...params, order_by: value })}
                            placeholder={`${common.filters.orderBy}...`}
                        />
                        <Select
                            className='ml-3'
                            options={[{ label: common.filters.ascending, value: "asc" }, { label: common.filters.descending, value: "desc" }]}
                            style={{ width: "10rem" }}
                            value={params?.sort_dir}
                            onChange={(value) => setParams({ ...params, sort_dir: value })}
                        />
                        {/* TODO: Improve accesibility, add sr-only labels for the user to undestand better */}
                        <Radio.Group
                            name='gridType'
                            className='d-flex ml-3'
                            options={[{ label: <UnorderedListOutlined title={common.filters.tableView} />, value: "table" }, { label: <AppstoreOutlined title={common.filters.gridView} />, value: "grid" }]}
                            value={gridType}
                            onChange={(e) => setGridType(e.target.value)}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </div>
                </div>

                <FileSection
                    type={gridType}
                    loading={localLoading}
                    files={fileSystemTree}
                    refreshList={() => handleNavigation(path)}
                    toggleLocalLoading={(value) => setLocalLoading(value)}
                />
                <Pagination className='text-right' total={totalRows} pageSize={10} showSizeChanger={false} hideOnSinglePage={true} current={page} onChange={(newPage, pageSize) => {setParams({...params, offset: (newPage-1) * pageSize}); setPage(newPage)}} />
            </>
        }
    </section>
}

export default ConsultResults;
