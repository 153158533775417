import React, { useState } from "react";
/* Components */
import { AntdSearch } from "../..";
import { useSafeEffect } from "../../../helpers/hooks";
import { AntdFormSelectField } from "../../common/dataEntry";
/* Services and Interfaces */
import { ConsultSearchInterface } from ".";
import { MasterDataResponse, getDropdownByKey } from "../../../services/masterData";
/* Translations */
import { commonTranslations } from "../../../helpers";
/* Utility */
import { mapToSelectData } from "../../../helpers";
import { notify, operationError } from "../../../services/notification-wrapper";
/* Provider */
import { useConsult } from "../../../views/consult/consultProvider";

const SearchReportingServizi: React.FC<ConsultSearchInterface> = ({
  onSearch,
  isSearching
}) => {

  const [loading, setLoading] = useState(false);
  const { labels, placeholders } = commonTranslations;

  const { cig, years, businessUnits, searchParams, selectedCig, setMasterdataCache } = useConsult();

  useSafeEffect(
    [selectedCig],
    () => {
      if (!selectedCig && years.length && businessUnits.length && cig.length) return Promise.resolve(null);
      setLoading(true);
      const yearsPromise = getDropdownByKey("year", selectedCig);
      const businessUnitsPromise = getDropdownByKey("innerBusinessUnit", selectedCig);
      const cigPromise = getDropdownByKey("cig", selectedCig);
      return Promise.all([yearsPromise, businessUnitsPromise, cigPromise]);
    },
    (data: [MasterDataResponse, MasterDataResponse, MasterDataResponse]) => {
      if (data && data.length > 1) {
        const [_years, _businessUnits, _cigs] = data;
        setMasterdataCache({
          years: mapToSelectData(_years?.values),
          businessUnits: mapToSelectData(_businessUnits?.values),
          cig: mapToSelectData(_cigs?.values)
        })
      }
    },
    (err: any) => notify(operationError()),
    () => setLoading(false)
  );

  return <AntdSearch
    name="searchReportingServizi"
    onSearch={(formValues) => onSearch(formValues, "searchReportingServizi")}
    loading={isSearching}
    initialValues={searchParams}
    valuesToSet={{ cig: selectedCig }}
    resetTrigger={selectedCig}
    atLeastOneFieldRequired
  >
    <AntdFormSelectField
      name={"cig"}
      label={labels.cig}
      placeholder={placeholders.cig}
      options={cig}
      cols="col-4"
      loading={loading}
      defaultValue={selectedCig}
      disabled={isSearching}
    />
    <AntdFormSelectField
      name={"businessUnit"}
      label={labels.businessUnit}
      placeholder={placeholders.businessUnit}
      options={businessUnits}
      cols="col-4"
      loading={loading}
      disabled={isSearching || !businessUnits?.length}
    />
    <AntdFormSelectField
      name={"year"}
      label={labels.year}
      placeholder={placeholders.year}
      options={years}
      cols="col-4"
      loading={loading}
      disabled={isSearching}
    />
  </AntdSearch>
}

export default SearchReportingServizi;