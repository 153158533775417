import React,
{ useState } from "react";
import { useNavigate } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";
import {
  commonTranslations,
  groupsTranslations
} from "../../helpers/translations";
import {
  Button,
  Popconfirm,
  Spin,
  Tooltip
} from "antd";
import {
  EditOutlined,
  LockOutlined,
  UnlockOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import {
  AntdFormInputField,
  AntdSearch,
  CustomColumnType,
  CustomGridAntd,
  ErroredLayout,
  Layout
} from "../../components";
import {
  notify,
  operationError,
  operationSuccess
} from "../../services/notification-wrapper";
import { GroupsResponse } from "../../services/groups";
import { useSafeEffect } from "../../helpers/hooks";
import { Group } from "../../services/groups";
import { GroupParams } from "../../services/groups/Groups.interface";
import { postGroups, updateGroup } from "../../services/groups/Groups.service";
import { getDataSource } from "../../helpers/utility";

const Groups = withErrorBoundary(
  () => {
    const navigate = useNavigate();

    const [groups, setGroups] = useState<Group[]>([]);

    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [params, setParams] = useState<GroupParams>();
    const [sort, setSort] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalRows, setTotalRows] = useState<number>(0);

    const groupsTrans = groupsTranslations;

    useSafeEffect(
      [],
      () => {
        setTableLoading(true);
        setLoading(true);
        return getGroupListInBegin();
      },
      (data) => {
        if (data) {
          setGroups(data.groups);
          setTotalRows(data.totalrows || 0);
        }
      },
      (e) => notify(operationError()),
      () => {
        setTableLoading(false);
        setLoading(false);
      }
    );

    const getGroupListInBegin = async (): Promise<GroupsResponse> => {
      return await postGroups();
    };

    const fetchGroupsList = async (newParams?: { [key: string]: any }) => {
      setTableLoading(true);

      try {
        const concatenatedParams = { ...params, ...newParams };
        setParams(concatenatedParams);
        const groupResp: GroupsResponse = await postGroups({ ...params, ...newParams });
        setGroups(groupResp.groups);
        setTotalRows(groupResp.totalrows || 0);
      } catch (e) {
        console.log("Error fetching data? ", e);
        notify(operationError());
      } finally {
        setTableLoading(false);
        setLoading(false);
      }
    };

    const goToEdit = (id?: number) => {
      const param = id || "";
      const url = `/configuration/groups/edit/${param}`;

      navigate(url);
    };

    const sortRow = (sortType: any, key: string) => {
      if (
        sortType?.sortColumns?.length &&
        key === sortType?.sortColumn?.key &&
        ((sort?.sortColumn === sortType?.sortColumn &&
          sort?.sortOrder !== sortType?.sortOrder) ||
          sort?.sortColumn !== sortType?.sortColumn)
      ) {
        setSort(sortType);
        fetchGroupsList({
          order_by: sortType.sortOrder ? sortType.sortColumn.dataIndex : "",
          sort_dir: sortType.sortOrder === "descend" ? "desc" : "asc",
        }).then(() => {
          setTableLoading(false);
        });
      }
    };

    const columns: CustomColumnType[] = [
      {
        title(columnsSort) {
          sortRow(columnsSort, "groupname");
          return groupsTrans.tableHeadings.name;
        },
        dataIndex: "groupname",
        key: "groupname",
        name: groupsTrans.tableHeadings.name,
        sorter: (a) => false,
      },
      {
        title(columnsSort) {
          sortRow(columnsSort, "groups_id");
          return groupsTrans.tableHeadings.parentGroup;
        },
        render(name: string, record: any) {
          return <>{record.parentGroup?.groupname}</>;
        },
        dataIndex: "parentGroup",
        key: "parentGroup",
        name: groupsTrans.tableHeadings.parentGroup,
        sorter: (a) => false,
      },
      ...[{
        title() {
          return (
            <>
              <div>
                <span>{groupsTrans.tableHeadings.actions}</span>
              </div>
            </>
          );
        },
        dataIndex: "actions",
        key: "actions",
        name: "",
        align: "center",
        render: (id: number, record?: any) => (
          <div className="d-flex w-100 justify-content-center">
            <Tooltip title={commonTranslations.tooltips.edit}>
              <Button type="link" onClick={() => goToEdit(record.id)}>
                <EditOutlined style={{ fontSize: "125%" }} />
              </Button>
            </Tooltip>
          </div>
        )
      }]
    ];

    const dsMapper = (g: Partial<Group>, index?: number) => ({
      key: "group-" + g.id,
      id: g.id,
      groups_id: g.groups_id,
      groupname: g.groupname,
      parentGroup: g.parentGroup
    });

    const dataSource = getDataSource<Group>(groups, dsMapper);


    return (
      <Layout title={groupsTrans.title} setIsEditing={goToEdit}>
        <>
          {showFilters && (
            <AntdSearch
              name="groupname"
              onSearch={(searchParams: any) => {
                setCurrentPage(1);
                fetchGroupsList({ ...searchParams, offset: 0 });
              }}
            >
              <AntdFormInputField
                inputName="groupname"
                placeholder={groupsTrans.search.placeholders.groupname}
                label={groupsTrans.search.labels.groupname}
              />
            </AntdSearch>
          )}

          <section className="row p-3 mt-4 mx-2 section">
            {loading ? (
              <div className={"d-flex flex-fill justify-content-center p-4"}>
                <Spin size="large" />
              </div>
            ) : (
              <>
                <div className="col-12 my-2 text-right">
                  <Button
                    onClick={() => setShowFilters(!showFilters)}
                    className={"mx-1 mr-2 d-inline-block"}
                  >
                    <i className="fa fa-filter" aria-hidden="true" />
                  </Button>
                  <Button type="primary" onClick={() => goToEdit()}>{commonTranslations.buttons.new}</Button>
                </div>

                <CustomGridAntd
                  loading={tableLoading}
                  dataSource={dataSource}
                  columns={columns}
                  total={totalRows}
                  currentPage={currentPage}
                  pageChange={(page: number, pageSize?: number) => {
                    setCurrentPage(page);
                    const size = pageSize ? pageSize : 10
                    fetchGroupsList({ offset: (page - 1) * size, limit: pageSize });
                  }}
                />
              </>
            )}
          </section>
        </>
      </Layout>
    );
  },
  { FallbackComponent: ErroredLayout }
);

export default Groups;
