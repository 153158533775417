import React, { useEffect, useState } from 'react';
/* COMPONENTS */
import { Form, Button } from 'antd';
/* Translations */
import { commonTranslations as common } from '../../helpers';

export interface AntdSearchProps {
  name: string;
  onSearch: (values: { [key: string]: any }) => Promise<unknown> | void;
  children: React.ReactNode;
  loading?: boolean;
  initialValues?: { [key: string]: any }
  atLeastOneFieldRequired?: boolean;
  valuesToSet?: { [key: string]: any };
  resetTrigger?: any;
  onValuesChange?: (changedValues: any, allValues: any) => void;
}

const AntdSearch = ({ name, onSearch, loading, children, initialValues, atLeastOneFieldRequired, valuesToSet, resetTrigger, onValuesChange }: AntdSearchProps) => {

  const [form] = Form.useForm();
  const [hasValidationError, setHasValidationError] = useState(false);

  useEffect(() => {
    valuesToSet && form.setFieldsValue(valuesToSet)
  }, [valuesToSet])

  useEffect(() => {
    resetTrigger && onReset();
  }, [resetTrigger])

  const onReset = () => {
    form.resetFields();
    onSearch(null)
    setHasValidationError(false);
  };

  const handleSearch = async (formValues: any) => {
    setHasValidationError(false);
    if (atLeastOneFieldRequired && !Object.values(formValues).filter((value) => value)?.length) {
      setHasValidationError(true);
      return;
    }

    try {
      await form.validateFields();
      onSearch(formValues);
    } catch {
      console.error("Errore nella validazione")
    }
  }

  return (
    <Form
      name={name}
      form={form}
      className={'bg-white mx-2'}
      onFinish={handleSearch}
      initialValues={initialValues}
      noValidate={atLeastOneFieldRequired}
      onValuesChange={onValuesChange}
    >
      <section className="d-flex flex-column p-3">
        <div className="d-flex flex-wrap align-items-end">
          {children}
          {hasValidationError &&
            <div className='col-12 mt-3 text-center ant-form-item-explain ant-form-item-explain-error'>
              {common.formValidations.atLeastOneRequired}
            </div>
          }
          <div className='col-12 mt-4 text-center'>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {common.buttons.search}
            </Button>
            <Button
              type="default"
              className="ml-2"
              htmlType="button"
              onClick={onReset}
              disabled={loading}
            >
              {common.buttons.reset}
            </Button>
          </div>
        </div>
      </section>
    </Form>
  );
};

export default AntdSearch;
