import React from 'react'
/* Components */
import IconByMimeType from '../../../../components/Utility/IconByMimeType';
/* Services */
import { DocumentType } from '../../../../services/documentTypes';
/* Utils */
import { consultTranslations } from '../../../../helpers';
import { Checkbox } from 'antd';

interface FileInterface {
    checked?: boolean;
    documentType: DocumentType;
    enableFileSelection?: boolean;
    fsitem: string;
    isDeleted?: boolean;
    isFolder: boolean;
    mimeType: string;
    onClick: () => void;
    onContextMenu: (event) => void;
}

export const File: React.FC<FileInterface> = ({
    checked,
    documentType,
    enableFileSelection,
    fsitem,
    isDeleted,
    isFolder,
    mimeType,
    onClick,
    onContextMenu
}) => {

    const docType = isFolder ? consultTranslations.results.folder : documentType.label;

    return (
        <div className={`context-menu-toggle py-4 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 fileGridItem ${checked ? "checkedFile" : ""}${isDeleted ? " deletedFile" : ""}`} onClick={onClick} onContextMenu={onContextMenu}>
            {mimeType !== "inode/directory" && enableFileSelection && <Checkbox checked={checked} />}
            <IconByMimeType mimeType={mimeType} size={"4em"} />
            <span className='filename' title={fsitem}>{fsitem}</span>
            <small className='filetype' title={docType}>{docType}</small>
        </div>
    )
}
