import React from "react";

const ContextMenu = ({ style, content }) => {

    return <div className="context-menu-container" style={style}>
        {content.map(listItem => (
            <div
                key={listItem.info}
                className="context-menu"
                style={listItem.style}
                onClick={listItem.onClick}
            >
                {listItem.info}
            </div>
        ))}
    </div>;
};

export default ContextMenu;
