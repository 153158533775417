import { Axios_Post } from "../../axios";
import {
  SearchResponse,
  SearchParams
} from ".";

export const API_SEARCH = "/v1/search";



export const searchInFileSystem = async (searchParams?: SearchParams): Promise<SearchResponse> => {
  if(Object.prototype.hasOwnProperty.call(searchParams.requestParams, "cig")) {
    searchParams.requestParams["cig"] = searchParams.requestParams["cig"].split(" - ")[0]
  }
  return Axios_Post<SearchResponse>(API_SEARCH, searchParams);
};
