import moment from "moment";
import { CustomColumnType, OptionProps } from "../components";

export const extract = <T extends { [key: string]: any }>(
  a: T,
  keys: (keyof T)[]
) => {
  const po: any = {};
  keys.forEach((key) => {
    po[key] = a[key];
  });
  return po;
};

export const listToString = (
  data: unknown[],
  emapper: (en: unknown) => string,
  config?: { sep?: string; limit?: number }
): string => {
  const { sep, limit } = { sep: " | ", limit: 3, ...config };
  return data.slice(0, limit).map(emapper).join(sep);
};

export const getColumns = <T>(
  tableHeading: {
    [k in keyof Partial<T>]:
    | string
    | {
      label: string | (() => any);
      render: (text: any, d: any, index?: number | string) => JSX.Element;
      disableSorting?: boolean;
      otherColsProps?: Partial<Omit<CustomColumnType, "title" | "render">>;
    };
  },
  sort: (sortType: any, key: keyof T) => void | Promise<void>
): CustomColumnType[] => {
  const keys = Object.keys(tableHeading);
  return keys.map((colkey: string, i: number, arr: string[]) => {
    const tcolValue: any = (tableHeading as any)[colkey];
    const { label, render, disableSorting, otherColsProps } =
      typeof tcolValue === "object"
        ? tcolValue
        : {
          label: tcolValue,
          render: undefined,
          disableSorting: false,
          otherColsProps: {},
        };

    const sortProps = disableSorting
      ? {
        title: label,
        name: label,
      }
      : {
        title(colSort: any) {
          if (!disableSorting) {
            sort(colSort, colkey as keyof T);
          }
          return label;
        },
        sorter: (a: any) => null,
        name: label,
      };

    return {
      ...sortProps,
      dataIndex: colkey,
      key: colkey,
      render,
      ...otherColsProps,
    };
  });
};

export const getDataSource = <T>(
  data: Partial<T>[],
  mapper: (
    row: Partial<T>,
    index?: number
  ) => { key?: number | string;[otherKey: string]: any }
) =>
  data.map((e: Partial<T>, index: number) => {
    return mapper(e, index);
  });

export const transform2SelectOptions = <T>(
  collections: T[],
  mapper: (obj: T) => OptionProps
): OptionProps[] => {
  return collections.map((obj) => {
    const op = mapper(obj);
    op.datum = obj;
    return { datum: obj, ...op };
  });
};

export const uniqueArray = <T extends { [key: string]: any }>(
  arr: T[],
  keys?: (keyof T)[]
): T[] => {
  if (arr.length <= 0) {
    return [];
  }

  const objToStr =
    keys && keys.length > 0
      ? (obj: T) => {
        return keys.reduce((str: string, cv: keyof T) => {
          str += obj[cv];
          return str;
        }, "");
      }
      : JSON.stringify;

  const unique = [
    ...new Map(arr.map((item, key) => [objToStr(item), item])).values(),
  ];

  return unique;
};

export const numberIfNotNaN = (
  strOrNumValue: string | number,
  defaultNumber?: number
) => {
  const nr = Number(strOrNumValue);
  return isNaN(nr)
    ? defaultNumber
      ? Number(defaultNumber)
      : strOrNumValue
    : nr;
};

export const toBase64 = (file): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const fileToBlob = async (file) => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

export const mapToSelectData = (list: string[]): OptionProps[] => {
  return list.map<OptionProps>(element => ({ name: element, value: element }));
}

export const formatFileSize = (bytes, decimalPoint = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + ' ' + sizes[i];
}

export const formatDate = (date = undefined, format = "DD/MM/yyyy") => {
  return date ? moment(date).format(format) : "";
}