import React, { useEffect, useState } from 'react';
/* Components */
import { AntdFormInputField, AntdFormSelectField, AntdSearch, AntdFormCheckbox, AntdFormRangePicker, useAuth } from "../../components";
/* Services and interfaces */
import { getDropdownByKey } from '../../services/masterData';
import { getDocumentTypes } from '../../services/documentTypes';
/* Translations */
import { mapToSelectData } from '../../helpers/utility';
import { fileSearchTranslations, commonTranslations as common } from '../../helpers/translations';

interface FileSearchResearchProps {
    handleSearch: (values: any) => void;
}

const FileSearchResearch: React.FC<FileSearchResearchProps> = ({ handleSearch }) => {

    const { user } = useAuth()
    const userRole = user?.role;
    const { search } = fileSearchTranslations;
    /* Add back subject field 'subject' */
    const searchFields = ['cig', 'year', "customer", 'businessUnit', 'societaInfragruppo', 'odl', 'partner', 'supplier', 'oda']

    const [options, setOptions] = useState<any>({
        cig: [],
        year: [],
        odl: [],
        supplier: [],
        oda: [],
        businessUnit: [],
        societaInfragruppo: [],
        partner: [],
        documentTypes: []
    })

    useEffect(() => {
        fetchDocumentTypes();
        fetchMasterData();
    }, []);

    const fetchDocumentTypes = () => {
        getDocumentTypes({ limit: 1000 })
            .then(res => {
                setOptions((previousOptions: any) => ({
                    ...previousOptions,
                    documentTypes: res.documentType.map(docType => ({ name: docType.label, value: docType.id }))
                })
                )
            })
    }

    const fetchMasterData = () => {
        Promise.all(searchFields.map(key => getDropdownByKey(key))).then(res => {
            const stateObj = {};
            for (const [index, key] of searchFields.entries()) {
                stateObj[key] = mapToSelectData(res[index].values);
            }
            setOptions((previousOptions: any) => ({ ...previousOptions, ...stateObj }));
        })
    }


    return (
        <AntdSearch name='fileSearchForm' onSearch={handleSearch} atLeastOneFieldRequired>
            <div className="col-12 p-0">
                <span className="h6">{search.fileAttributesSection}</span>
            </div>
            <AntdFormInputField
                name="fsitem"
                label={search.labels.filename}
                placeholder={search.placeholders.filename}
            />
            {/* TODO: Solo per utenze maintenance */}
            {userRole?.accessLevel === "xu" &&
                <AntdFormInputField
                    name="s3filename"
                    label={search.labels.s3filename}
                    placeholder={search.placeholders.s3filename}
                />
            }
            <AntdFormSelectField
                name="mimeType"
                label={search.labels.mimeType}
                placeholder={search.placeholders.mimeType}
                options={[
                    { name: "PDF (.pdf)", value: "application/pdf" },
                    { name: "File Word (.doc)", value: "application/msword" },
                    { name: "File Word Open XML (.docx)", value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
                    { name: "File Excel (.xls)", value: "application/vnd.ms-excel" },
                    { name: "File Excel Open XML (.xlsx)", value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
                    { name: "File PowerPoint (.ppt)", value: "application/vnd.ms-powerpoint" },
                    { name: "File PowerPoint Open XML (.pptx)", value: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
                    { name: "P7M (.p7m)", value: "application/pkcs7-mime" }
                ]}
            />
            <AntdFormSelectField
                name="documentType_id"
                label={search.labels.documentType}
                placeholder={search.placeholders.documentType}
                options={options.documentTypes}
            />
            <AntdFormRangePicker
                name="creationDate"
                label={search.labels.creationDate}
                placeholder={[search.placeholders.creationDateStart, search.placeholders.creationDateEnd]}
            />

            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <AntdFormCheckbox
                    name='is_not_deleted'
                    label={search.labels.considerDeletedFiles}
                    placeholder={search.placeholders.considerDeletedFiles}
                />
            </div>
            <div className="col-12 p-0 mt-4">
                <span className="h6">{search.indexAttributesSection}</span>
            </div>
            {searchFields.map(fieldKey =>
                <AntdFormSelectField
                    key={fieldKey}
                    name={fieldKey}
                    label={common.labels[fieldKey]}
                    placeholder={common.placeholders[fieldKey]}
                    options={options[fieldKey]}
                />
            )}
        </AntdSearch>
    )
}

export default FileSearchResearch;
