import React, { useEffect, useReducer, useState } from 'react';
import { Button, Form, Skeleton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { AntdFormAutoCompleteField, AntdFormInputField, Layout } from '../../components';
import { getRequiredValidation } from '../../helpers';
import { useSafeEffect } from '../../helpers/hooks';
import { commonTranslations, documentTypesTranslations } from '../../helpers/translations';
import { DocumentType, GetDocumentTypeByIdResponseInterface } from '../../services/documentTypes/documentTypes.interface';
import {
  createDocumentType,
  getDocumentTypesById,
  getTags, TagResponse,
  updateDocumentType
} from '../../services/documentTypes/documentTypes.service';
import { notify, operationError, operationSuccess } from '../../services/notification-wrapper';
import { PathPattern } from "./PathPattern";

const PDG_SOURCE_SYSTEM = 'PDG_CLIENT';

const pathPatternReducer = (state: string[], action: { type: string, item: string | string[] }): string[] => {
  if (action.type === 'INITIALIZE') return ['<cig>'];
  if (action.type === 'RESET') return [];
  if (action.type === 'ADD') {
    if (Array.isArray(action.item)) return action.item;
    if (typeof action.item === 'string') return [...state, action.item];
  }
  if (action.type === 'REMOVE') {
    state.pop();
    return [...state]
  }
  return state;
}
const DocumentTypeEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const [form] = Form.useForm();
    const isEdit = id !== undefined;

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [documentType, setDocumentType] = useState<DocumentType>();
    const [arrayPathPattern, setArrayPathPattern] = useReducer(pathPatternReducer,[]);
    const [tagOptions, setTagOptions] = useState([]);
    const documentTypesTrans = documentTypesTranslations;

    const fetchDocumentTypeInfo = async () => {
      try {
        const documentTypeResp: GetDocumentTypeByIdResponseInterface = await getDocumentTypesById(Number(id));
        return documentTypeResp.documentType;
      } catch (e) {
        console.log("Error fetching data? ", e);
        notify(operationError());
      }
    };

    const goToView = () => {
      navigate("/configuration/documentTypes");
    };

    useSafeEffect(
      [],
      () => {
        setLoading(true);
        return !isEdit ? Promise.resolve(null) : fetchDocumentTypeInfo();

      },
      (data?: DocumentType) => {
        if (data) {
          const docTypeInfo = {
            ...data,
            ...documentType
          }
          setDocumentType(docTypeInfo);
          if (docTypeInfo && docTypeInfo.pathPattern && docTypeInfo.pathPattern.length > 0) {
            setArrayPathPattern({ type: 'INITIALIZE', item: docTypeInfo.pathPattern })
          }
          form.setFieldsValue(docTypeInfo)
          setDocumentType(data);
        }
      },
      (e: any) => {
        notify(operationError(e.response.status));
      },
      () => {
        setLoading(false)
      }
    );

    useEffect(() => {
      searchTags();
    }, [])

    const showPathPattern = (): boolean => {
      if (!isEdit) return true;
      return isEdit && documentType && documentType.sourceSystem === PDG_SOURCE_SYSTEM;
    }

    const onSaveDoc = async (
        value
      ) => {
        try {
          setIsSaving(true);

          if (isEdit) {
            await updateDocumentType({
              ...documentType,
              ...value,
              ...(documentType.sourceSystem === PDG_SOURCE_SYSTEM && { pathPattern: arrayPathPattern })
            });
          } else {
            await createDocumentType({
              ...documentType,
              ...value,
              pathPattern: arrayPathPattern,
              sourceSystem: PDG_SOURCE_SYSTEM
            });
          }
          notify(operationSuccess());
          goToView();
        } catch (e) {
          notify(operationError(e.response.status));
        } finally {
          setIsSaving(false);
        }
    };

    const searchTags = async () => {
      try {
        const res: TagResponse = await getTags("");
        const mappedValues = res.values.map((tag) => ({ text: tag, value: tag }));
        setTagOptions(mappedValues)
        // return res.values.map((tag) => ({ data: tag, value: tag }));
      } catch {
        return []
      }
    }

    return (
        <Layout
            title=""
            leaveEdit={goToView}
            isEditing={true}
            isSaving={isSaving}
            disableInsert={true}
        >
          <section className="p-3 mt-3 mx-1 section">
            <Skeleton loading={loading} active>
              <Form
                className="column"
                layout="horizontal"
                name="DocumentTypeEditForm"
                form={form}
                initialValues={documentType || {isActive: true}}
                onFinish={onSaveDoc}
              >
                <div>
                  <h1 className="mt-1">{isEdit && documentType ? documentType.label : documentTypesTrans.new}</h1>
                  <div className="mt-4 row px-2">
                    <AntdFormInputField
                      name="label"
                      placeholder={documentTypesTrans.search.placeholders.name}
                      label={documentTypesTrans.search.labels.name}
                      rules={getRequiredValidation("documentTypes", "name")}
                      required={true}
                    />
                    <AntdFormAutoCompleteField
                      name="tag"
                      formItemsProps={{
                        labelCol: { span: 24 },
                        wrapperCol: { span: 24 },
                        required: true,
                        rules: getRequiredValidation("documentTypes", "tag")
                      }}
                      placeholder={documentTypesTrans.search.placeholders.tag}
                      label={documentTypesTrans.labels.tag}
                      disabled={disable}
                      options={tagOptions}
                    />
                  </div>
                  {showPathPattern() && (
                    <PathPattern
                      defaultPath={documentType?.pathPattern}
                      setArrayPathPattern={setArrayPathPattern}
                    />
                  )}
                </div>

                <div className="mt-5 row px-4 justify-content-end">
                  <Button
                    className="m-1"
                    onClick={() => goToView()}
                    disabled={isSaving}
                  >
                    {commonTranslations?.buttons?.back}
                  </Button>
                  <Button
                    className="m-1"
                    type="primary"
                    htmlType="submit"
                    loading={isSaving}
                  >
                    {commonTranslations.buttons.save}
                  </Button>
                </div>
              </Form>
            </Skeleton>
          </section>
        </Layout>
    );
};

export default DocumentTypeEdit;
