import { Axios_Post } from "../../axios";
import config from "../../config.json";
import { TokenResponseInterface } from "./TokenResponse.interface";
import jwt from "jsonwebtoken";
import {
  removeValueFromSession,
  getValueFromSession,
} from "../storage-wrapper/localstorage-wrapper";
/* UTILS */
const appConfig: any = config;
const environment = process.env.REACT_APP_ENVIRONMENT || "none";
const envVariables = appConfig.Environment[environment];

export const getTokenWithAuthCode = async (authCode: string): Promise<any> => {
  const url = `${envVariables.cognitoHost}/oauth2/token`;
  const data = `grant_type=authorization_code&client_id=${envVariables.clientId}&code=${authCode}&redirect_uri=${envVariables.redirectUri}`;
  try {
    const authResponse: TokenResponseInterface = await Axios_Post(
      url,
      data,
      true
    );
    return handleTokenResponse(authResponse);
  } catch (e) {
    return e;
  }
};

export const refreshToken = async (): Promise<any> => {
  const url = `${envVariables.cognitoHost}/oauth2/token`;
  const refresh_token: string =
    getValueFromSession<string>("refreshToken") || "";
  const data = `grant_type=refresh_token&client_id=${envVariables.clientId}&refresh_token=${refresh_token}`;

  removeValueFromSession("token");
  try {
    const authResponse: any = await Axios_Post(url, data, true);
    return handleTokenResponse({ ...authResponse, refresh_token });
  } catch (e) {
    return e;
  }
};

function handleTokenResponse(data: any) {
  const token = data.id_token;
  const accessToken = data.access_token;
  const refreshTokenValue = data.refresh_token;
  const jwtData = jwt.decode(token);
  return {
    ...jwtData,
    token,
    accessToken,
    refreshToken: refreshTokenValue,
  };
}
