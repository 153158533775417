import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';

import headerEN from './assets/locales/en/header.json';
import headerIT from './assets/locales/it/header.json';

import menuEN from './assets/locales/en/menu.json';
import menuIT from './assets/locales/it/menu.json';

import consultEN from './assets/locales/en/consult.json';
import consultIT from './assets/locales/it/consult.json';

import fileSearchEN from './assets/locales/en/fileSearch.json'
import fileSearchIT from './assets/locales/it/fileSearch.json'

import uploadEN from './assets/locales/en/upload.json';
import uploadIT from './assets/locales/it/upload.json';

import commonEN from './assets/locales/en/common.json';
import commonIT from './assets/locales/it/common.json';

import homeEN from './assets/locales/en/home.json';
import homeIT from './assets/locales/it/home.json';

import usersEN from './assets/locales/en/users.json';
import usersIT from './assets/locales/it/users.json';

import documentTypesEN from './assets/locales/en/documentType.json';
import documentTypesIT from './assets/locales/it/documentType.json';

import groupsEN from './assets/locales/en/groups.json';
import groupsIT from './assets/locales/it/groups.json';

import uploadConsoleEN from './assets/locales/en/console.json';
import uploadConsoleIT from './assets/locales/it/console.json';

// the translations
const resources = {
  en: {
    common: commonEN,
    menu: menuEN,
    header: headerEN,
    home: homeEN,
    consult: consultEN,
    fileSearch: fileSearchEN,
    upload: uploadEN,
    users: usersEN,
    documentTypes: documentTypesEN,
    groups: groupsEN,
    console: uploadConsoleEN,
  },
  it: {
    common: commonIT,
    menu: menuIT,
    header: headerIT,
    home: homeIT,
    consult: consultIT,
    fileSearch: fileSearchIT,
    upload: uploadIT,
    users: usersIT,
    documentTypes: documentTypesIT,
    groups: groupsIT,
    console: uploadConsoleIT
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LngDetector)
  .init({
    resources,
    fallbackLng: 'en',

    whitelist: ['it', 'en'],

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
