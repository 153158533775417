import { Axios_Post } from "../../axios";
import {
  MasterDataResponse
} from ".";

export const API_MASTER_DATA = "/v1/masterData";
export const API_GET_APPLICATION_CODE = "/v1/applicationCodes";

const cigs = {
  "871391347D": "Gara 1",
  "8713945EE2": "Gara 2"
}

export const getDropdownByKey = async (columnKey: string, cig?: string): Promise<MasterDataResponse> => {
  if(cig) {
    cig = cig.split(" - ")[0];
  }
  return new Promise((res, rej) => {
    Axios_Post<MasterDataResponse>(API_MASTER_DATA, {columnKey, cig}).then((response) => {
      const newValues = []
      for(let value of response.values) {
        if(["871391347D", "8713945EE2"].includes(value)) {
          value = `${value} - ${cigs[value]}`;
        }
        newValues.push(value);
      }
      res({...response, values: newValues})
    }).catch(err => rej(err));
  })
};

export const getApplicationCode = (params: {cig: string; businessUnit: string; columnKey: string}) => {
  if(params.cig) {
    params.cig = params.cig.split(" - ")[0];
  }
  delete params.columnKey
  return Axios_Post<MasterDataResponse>(API_GET_APPLICATION_CODE, params)
}
