import {
  Axios_Get,
  Axios_Post,
  Axios_Put
} from "../../axios";
import {
  GetGroupByIdResponseInterface,
  Group,
  GroupCreate,
  GroupParams,
  GroupsResponse,
  GroupUpdate
} from "../groups/Groups.interface";
import { UserParamsInterface } from "../user";

export const API_GROUPS_URL = '/v1/groups';
export const API_GROUP_URL = '/v1/group';
export const API_GROUP_LIST_URL = '/v1/configuration/group';

export const getGroup = async (
  filter?: UserParamsInterface
): Promise<GroupsResponse> => {
  return Axios_Post<GroupsResponse>(API_GROUPS_URL, filter)
}

export const postGroups = async (
  filter?: GroupParams
): Promise<GroupsResponse> => {
  return Axios_Post<GroupsResponse>(API_GROUPS_URL, filter)
}

export const getGroupByID = async (
  id: number
): Promise<GetGroupByIdResponseInterface> => {
  return Axios_Post<GetGroupByIdResponseInterface>(`${API_GROUP_URL}`, {id});
};

export const createGroup = async (
  group: Group
): Promise<GroupCreate> => {
  return Axios_Post<GroupCreate>(`${API_GROUP_LIST_URL}`, group);
};

export const updateGroup = async (
  group: any
): Promise<GroupUpdate> => {
  return Axios_Put<GroupUpdate>(`${API_GROUP_LIST_URL}/${group.id}`, group);
};