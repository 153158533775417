import i18next from 'i18next';

export let commonTranslations = getTranslation("common");
export let headerTranslations = getTranslation("header");
export let menuTranslations = getTranslation("menu");
export let homeTranslations = getTranslation("home");
export let consultTranslations = getTranslation("consult");
export let fileSearchTranslations = getTranslation("fileSearch");
export let uploadTranslations = getTranslation("upload");
export let usersTranslations = getTranslation("users");
export let documentTypesTranslations = getTranslation("documentTypes");
export let groupsTranslations = getTranslation("groups");
export let uploadConsoleTranslations = getTranslation("console");

i18next.on("languageChanged", () => {
    commonTranslations = {...commonTranslations, ...getTranslation("common")}
    headerTranslations = {...headerTranslations, ...getTranslation("header")}
    menuTranslations = {...menuTranslations, ...getTranslation("menu")}
    homeTranslations = {...homeTranslations, ...getTranslation("home")}
    consultTranslations = {...consultTranslations, ...getTranslation("consult")}
    fileSearchTranslations = {...fileSearchTranslations, ...getTranslation("fileSearch")}
    uploadTranslations = {...uploadTranslations, ...getTranslation("upload")}
    usersTranslations = {...usersTranslations, ...getTranslation("users")}
    documentTypesTranslations = {...documentTypesTranslations, ...getTranslation("documentTypes")}
    groupsTranslations = {...groupsTranslations, ...getTranslation("groups")}
    uploadConsoleTranslations = { ...uploadConsoleTranslations, ...getTranslation("console") }
})

function getTranslation(key: string): {[key: string]: any} {
    return i18next.t(`${key}:values`, { returnObjects: true })
}
