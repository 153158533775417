import React, { useState } from 'react';
/* AntD */
import { Card, CardProps } from 'antd';

// interface AntdCardProps extends CardProps  {}

const AntdCard = ({title, style, children}: CardProps) => {
  return (
    <>
      <Card
        style={{...{ width: '100%' }, ...style}}
        title={title}
      >
        {children}
      </Card>
    </>
  );
};

export default AntdCard;