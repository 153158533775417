export const DEFAULT_LIMIT = 10;
export const MAX_LIMIT = 10000;

export const UPLOAD_FIELDS = {
    cig: {
        key: "cig",
        name: "CIG",
        cols: "col-12",
        required: true,
        type: "select"
    },
    year: {
        key: "year",
        name: "Anno",
        cols: "col-12",
        required: true,
        type: "select"
    },
    month: {
        key: "month",
        name: "Mese",
        cols: "col-12",
        required: true,
        type: "select"
    },
    odl: {
        key: "odl",
        name: "ODL",
        cols: "col-12",
        required: true,
        type: "select"
    },
    supplier: {
        key: "supplier",
        name: "Fornitore",
        cols: "col-12",
        required: true,
        type: "select"
    },
    partner: {
        key: "partner",
        name: "Partner",
        cols: "col-12",
        required: true,
        type: "select"
    },
    businessUnit: {
        key: "businessUnit",
        name: "businessUnit",
        cols: "col-12",
        required: true,
        type: "select",
        dependsOn: ["CIG"]
    },
    applicationCode: {
        key: "applicationCode",
        name: "applicationCode",
        cols: "col-12",
        required: true,
        type: "select",
        dependsOn: ["CIG", "businessUnit"]
    },
    oda: {
        key: "oda",
        name: "ODA",
        cols: "col-12",
        required: true,
        type: "select"
    },
    formaContrattualeNominato: {
        key: "formaContrattualeNominato",
        name: "formaContrattualeNominato",
        cols: "col-12",
        required: true,
        type: "optgroup",
        options: [
            {name: "Consulente esterno", value: 1},
            {name: "Dipendente", value: 2}
        ]
    }
}

export const STATIC_OPTIONS = {
  month: [
    {name: "01 - Gennaio", value: "01"},
    {name: "02 - Febbraio", value: "02"},
    {name: "03 - Marzo", value: "03"},
    {name: "04 - Aprile", value: "04"},
    {name: "05 - Maggio", value: "05"},
    {name: "06 - Giugno", value: "06"},
    {name: "07 - Luglio", value: "07"},
    {name: "08 - Agosto", value: "08"},
    {name: "09 - Settembre", value: "09"},
    {name: "10 - Ottobre", value: "10"},
    {name: "11 - Novembre", value: "11"},
    {name: "12 - Dicembre", value: "12"}
  ]
}
