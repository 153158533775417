import React from "react";
import { DatePicker, Form, FormItemProps } from "antd";
import { Moment } from "moment";

export interface AntdFormDatePickerProps extends FormItemProps {
  disabledDate?: (date: Moment) => boolean;
  disabled?: boolean;
  name: any;
  label: string;
  placeholder?: string;
  customClass?: string;
  defaultValue?: Moment;
  format?: string;
  onChange?: (a: any) => void;
  noWrapperStyle?: boolean;
}

const colProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const AntdFormDatePicker: React.FC<AntdFormDatePickerProps> = ({
  disabledDate,
  disabled,
  name,
  label,
  placeholder,
  customClass,
  defaultValue,
  format,
  onChange,
  noWrapperStyle,
  ...otherProps
}) => {
  return (
    <div
      className={
        !noWrapperStyle
          ? `col-12 col-sm-12 col-md-6 col-lg-4 my-1 ${customClass}`
          : ""
      }
    >
      <Form.Item
        initialValue={defaultValue}
        name={name}
        label={label}
        {...otherProps}
        {...colProps}
      >
        <DatePicker
          defaultValue={defaultValue}
          placeholder={placeholder}
          {...otherProps}
          className="w-100"
          format="DD/MM/yyyy"
          disabled={disabled}
          disabledDate={disabledDate}
          onChange={onChange ? (e) => onChange(e) : (e) => null}
        />
      </Form.Item>
    </div>
  );
};

export default AntdFormDatePicker;
