import i18n from "i18next";

const getPleaseInputText = (): string => {
  const translateObj: any = i18n.t("common:values.formValidations", { returnObjects: true });
  return translateObj["pleaseInput"];
};

const getLabelText = (parent: string, field: string): string => {
  const translateObj: any = i18n.t(`${parent}:values.labels`, { returnObjects: true });
  return translateObj[field];
};

export const getRequiredValidation = (
  parent: string,
  field: any,
  customValidator?: (rule: any, value: any) => Promise<void | any> | void
) => {
  if (field && Array.isArray(field)) {
    field = field[field.length - 1];
  }

  const requiredObject: any[] = [
    {
      required: true,
      message: getPleaseInputText() + getLabelText(parent, field),
    },
  ];

  if (customValidator) {
    requiredObject[0].validator = customValidator;
  }

  return requiredObject;
};
